define("coffee-client/pod/components/vp-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IBtR7sk8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-row has-feedback\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[12,\"class\",[28,[\"form-group col-sm-\",[27,\"if\",[[23,[\"labelCol\"]],[23,[\"labelCol\"]],3],null],\" control-label\"]]],[9],[0,\"\\n      \"],[1,[27,\"t\",[[23,[\"label\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"form-group col-sm-\",[27,\"if\",[[23,[\"inputCol\"]],[23,[\"inputCol\"]],9],null]]]],[9],[0,\"\\n    \"],[7,\"label\"],[9],[0,\"\\n      \"],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"valuePath\"]]]]],false],[0,\"\\n      \"],[7,\"i\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/vp-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});