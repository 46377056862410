define("coffee-client/pod/home/intro/route", ["exports", "coffee-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    getOption: Ember.inject.service('get-option'),
    router: Ember.inject.service(),

    setHeadTags(model) {
      let domain = _environment.default.APP.domain;
      let currentUrl = domain + this.router.urlFor('home.intro');
      let index = currentUrl.indexOf("?");

      if (index > 0) {
        currentUrl = currentUrl.substring(0, index);
      }

      let headTags = [{
        type: 'meta',
        attrs: {
          name: 'twitter:card',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: "website"
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:title',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:creator',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:site_name',
          content: 'themondaycoffee.com'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.slogan
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: 'http:' + model.logo
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: model.slogan
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: currentUrl
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'description',
          content: model.description
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'keywords',
          content: model.keywords
        }
      }, {
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: currentUrl
        }
      }];
      this.set('headTags', headTags);
    },

    afterModel(model) {
      this.setHeadTags(model);
    },

    model() {
      let description = this.get('getOption').getOption('about-meta-description');
      let keywords = this.get('getOption').getOption('about-meta-keywords');
      let logo = this.get('getOption').getOption('logo');
      let slogan = 'The Monday Coffee | ' + this.get('getOption').getOption('slogan');
      return Ember.RSVP.hash({
        options: this.store.findAll('option'),
        //////////
        description: description,
        keywords: keywords,
        logo: logo,
        slogan: slogan
      });
    }

  });

  _exports.default = _default;
});