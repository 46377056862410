define("coffee-client/pod/components/ecom-admin/product-property/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //-- required
    selection: [],
    checkbox: true,
    category: undefined,
    productProperties: [],
    className: '',
    firstLoad: true,
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    changeCategory: Ember.observer('category', function () {
      //load all property terms by a category
      let self = this;
      self.get('store').query('product-property', {
        sort: 'name',
        dir: 'asc',
        category: self.get('category.id'),
        visible: 1
      }).then(function (productProperties) {
        return Ember.RSVP.all(productProperties.getEach('visibleProductPropertyTerms')).then(function (visibleProductPropertyTerms) {
          return Ember.RSVP.all(visibleProductPropertyTerms.getEach('productPropertyTerm')).then(function () {
            self.set('productProperties', productProperties);

            if (!self.get('firstLoad')) {
              self.set('selection', []);
            }

            self.set('firstLoad', false);
          });
        });
      });
    })
  });

  _exports.default = _default;
});