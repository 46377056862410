define("coffee-client/pod/components/ecom-front/zoom-image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ga3Akjb6",
    "block": "{\"symbols\":[\"img\"],\"statements\":[[2,\"<img style=\\\"width:400px\\\" src=\\\"http://ultraimg.com/images/bNeWGWB.jpg\\\" {/{action \\\"zoomIn\\\" on=\\\"mouseEnter\\\"}}\"],[0,\"\\n\"],[2,\"{/{action \\\"zoomOut\\\" on=\\\"mouseLeave\\\"}}>\"],[0,\"\\n\"],[2,\"<div class=\\\"review-image {/{classReview}}\\\">\"],[0,\"\\n\"],[2,\"</div>\"],[0,\"\\n\"],[4,\"photo-swipe\",null,[[\"items\"],[[23,[\"items1\"]]]],{\"statements\":[[0,\"  \"],[6,[22,1,[]],[[11,\"class\",\"thumb\"],[12,\"src\",[22,1,[\"src\"]]],[12,\"alt\",[22,1,[\"title\"]]]],[[],[]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/zoom-image/template.hbs"
    }
  });

  _exports.default = _default;
});