define("coffee-client/pod/components/ecom-front/categories-by-position/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/x3Gy3+s",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"listChildren\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"home.list-products\",[27,\"query-params\",null,[[\"category\"],[[22,1,[\"id\"]]]]]],null,{\"statements\":[[0,\"        \"],[1,[22,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/categories-by-position/template.hbs"
    }
  });

  _exports.default = _default;
});