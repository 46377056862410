define("coffee-client/models/store-job", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    description: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    theStore: _emberData.default.belongsTo('store'),
    quantity: _emberData.default.attr('number'),
    job: _emberData.default.belongsTo('job')
  });

  _exports.default = _default;
});