define("coffee-client/pod/components/ecom-front/store-in-home/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    height: 0,
    brands: [],
    products1: null,
    products2: null,
    categories: [],
    sellers: [],
    widthLogostore: 0,
    heightLogostore: 0,
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let arr = [];
      this.get('store.productList').forEach(function (product) {
        if (product.get('published') === 'Published') {
          arr.pushObject(product);
        }
      }); // arr.pushObjects(this.get('store.productList'));

      if (arr.get('length') > 0) {
        if (this.get('media.isDesktop')) {
          this.set('products1', arr.slice(0, 4));
          this.set('products2', arr.slice(4, 8));
        }

        if (this.get('media.isTablet')) {
          this.set('products1', arr.slice(0, 3));
          this.set('products2', arr.slice(3, 6));
        }

        if (this.get('media.isMobile')) {
          this.set('products1', arr.slice(0, 2));
          this.set('products2', arr.slice(2, 4));
        }
      } else {
        this.set('products1', null);
        this.set('products2', null);
      }
    },

    changeHeight() {
      this.set('height', this.$('.store-in-home .col-md-10').height());
      this.$('.store-in-home .left').css({
        'height': parseInt(this.height) + 1
      });
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.get('store.headerForeground')) {
        this.$('.list-categories ul li a').css({
          'color': this.get('store.headerForeground')
        });
        this.$('.list-categories ul li a').css({
          'border-left-color': this.get('store.headerForeground')
        });
      }

      let widthLogoStore = this.$('.store-logo').width();

      if (this.get('media.isMobile')) {
        this.$('.store-logo').css({
          'height': 45
        });
      } else {
        this.$('.store-logo').css({
          'height': widthLogoStore
        });
      }

      let heightMenuLeft = 0;

      if (this.get('media.isDesktop')) {
        heightMenuLeft = 456;
        this.$('.list-brands').css({
          'height': heightMenuLeft - parseInt(widthLogoStore)
        });
      }

      if (this.get('media.isTablet')) {
        heightMenuLeft = 299;
        this.$('.list-brands').css({
          'height': heightMenuLeft - parseInt(widthLogoStore) + 1
        });
      } //     if (this.get('store.logoSectionBackground')) {
      //       this.$('.list-brands').css({'border-color': this.get('store.logoSectionBackground')});
      //     }

    },

    willDestroyElement() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});