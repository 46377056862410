define("coffee-client/helpers/get-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getPrice = getPrice;

  function getPrice(params, namedArgs) {
    let product = namedArgs.product;
    let variant = namedArgs.variant;
    let discountVoucher = params;

    if (discountVoucher > 0) {
      if (variant === 1) {
        return product.get('price1');
      }

      if (variant === 2) {
        return product.get('price2');
      }

      if (variant === 3) {
        return product.get('price3');
      }

      if (variant === 4) {
        return product.get('price4');
      }
    } else {
      if (variant === 1) {
        if (product.get('salePrice1')) {
          return product.get('salePrice1');
        } else {
          return product.get('price1');
        }
      }

      if (variant === 2) {
        if (product.get('salePrice2')) {
          return product.get('salePrice2');
        } else {
          return product.get('price2');
        }
      }

      if (variant === 3) {
        if (product.get('salePrice3')) {
          return product.get('salePrice3');
        } else {
          return product.get('price3');
        }
      }

      if (variant === 4) {
        if (product.get('salePrice4')) {
          return product.get('salePrice4');
        } else {
          return product.get('price4');
        }
      }
    }
  }

  var _default = Ember.Helper.helper(getPrice);

  _exports.default = _default;
});