define("coffee-client/transforms/visible", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      if (serialized === 1) {
        return true;
      }

      if (serialized === 0) {
        return false;
      }

      return false;
    },
    serialize: function (deserialized) {
      if (deserialized === false) {
        return 0;
      }

      if (deserialized === true) {
        return 1;
      }

      return 0;
    }
  });

  _exports.default = _default;
});