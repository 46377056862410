define("coffee-client/pod/components/ecom-front/specific-brands/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JQA3OrKy",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"specific-brands\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row specific-brands-box-control\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"specificBrands\"]]],null,{\"statements\":[[4,\"if\",[[27,\"or\",[[27,\"eq\",[[22,1,[\"published\"]],2],null],[27,\"eq\",[[22,1,[\"published\"]],1],null]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"class\",[28,[\"col-md-2 \",[27,\"if\",[[27,\"eq\",[[22,1,[\"published\"]],2],null],\"pending-brand\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[27,\"eq\",[[22,1,[\"published\"]],2],null]],null,{\"statements\":[[4,\"link-to\",[\"admin.home-brands.edit\",[22,1,[\"id\"]]],[[\"target\",\"class\"],[\"_blank\",\"title\"]],{\"statements\":[[0,\"              \"],[1,[27,\"t\",[\"Pending\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"link-to\",[\"home.list-products\",[27,\"query-params\",null,[[\"brands\"],[[22,1,[\"brand\",\"id\"]]]]]],null,{\"statements\":[[0,\"            \"],[1,[27,\"img-lazy\",null,[[\"src\"],[[22,1,[\"brand\",\"image\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/specific-brands/template.hbs"
    }
  });

  _exports.default = _default;
});