define("coffee-client/pod/order-customer/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    identity: Ember.inject.service(),
    session: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        options: this.store.query('option', {
          limit: 1000
        })
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('orderDetailList', this.session.get('data.currentOrder'));
    }

  });

  _exports.default = _default;
});