define("coffee-client/pod/components/e-route-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    backTo: null,
    opened: true,
    actions: {
      close() {
        this.set('opened', false);

        if (this.backTo) {
          this.router.transitionTo(this.backTo);
        }
      }

    }
  });

  _exports.default = _default;
});