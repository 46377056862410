define("coffee-client/pod/components/ecom-front/button-confirm/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bEX49XbY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"a\"],[11,\"class\",\"e-btn-default-green-sm btn\"],[12,\"style\",[28,[\"width:\",[21,\"width\"],\";height:\",[21,\"height\"]]]],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"status\"]]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"t\",[[23,[\"confirmText\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[3,\"action\",[[22,0,[]],\"changeStatus\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/button-confirm/template.hbs"
    }
  });

  _exports.default = _default;
});