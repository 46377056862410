define("coffee-client/pod/components/ecom-admin/user/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //-- required
    selected: null,
    options: [],
    optionComponent: 'select-list-view',
    singleValueComponent: 'selected-view-id-name',
    //--
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let params = {};
      params['limit'] = this.limit;
      let self = this;
      this.set('options', self.get('store').query('user', params));
    },

    actions: {
      selectItem(value) {
        this.set('value', value);
      },

      queryChanged(term) {
        let keyName = this.keyName;
        let params = {};
        term = term.trim();
        params[keyName] = term;
        params['limit'] = this.limit;
        this.set('lastTerm', term);
        let self = this;
        setTimeout(function () {
          if (term === self.get('lastTerm')) {
            self.get('store').query('user', params).then(result => {
              self.set('options', result.toArray());
            });
          }
        }, self.get('timeout'));
      },

      clear() {
        this.set('selected', null);
        this.set('value', null);
      }

    },
    selectedChanged: Ember.observer('selected', function () {
      let find = null;
      let self = this;
      this.options.forEach(function (item) {
        if (item.id === self.get('selected.id')) {
          find = item;
        }
      });

      if (find) {
        this.set('value', find);
      }
    }),
    valueChanged: Ember.observer('value', function () {
      this.set('selected', this.store.peekRecord('user', this.get('value.id')));
    })
  });

  _exports.default = _default;
});