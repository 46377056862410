define("coffee-client/pod/components/e-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jbKReb9p",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[23,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[1,[21,\"e-spinner\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"form\"],[11,\"class\",\"form-horizontal e-form\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"showSubmit\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-bg\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[[23,[\"actionLabel\"]]],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"action\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-form/template.hbs"
    }
  });

  _exports.default = _default;
});