define("coffee-client/pod/components/ecom-front/product/add/component", ["exports", "coffee-client/mixins/form/create", "coffee-client/helpers/url-name", "coffee-client/config/environment", "moment"], function (_exports, _create, _urlName, _environment, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_create.default, {
    modelName: 'product',
    imageNos: [0, 1, 2, 3, 4, 5, 6, 7],
    selectedTerms: [],
    seller: null,
    colors: [],
    variantOptionList: [],
    productVariantList: [],
    openVariantOptionForm: false,
    sizeNumberOptions: [],
    sizeSymbolOptions: [],
    giftList: [],
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('model.seller', this.seller);
      this.set('sizeNumberOptions', _environment.default.APP.product.sizeNumberOptions);
      this.set('sizeSymbolOptions', _environment.default.APP.product.sizeSymbolOptions);
      this.set('variantOptionList', []);
      this.set('productVariantList', []);
      this.set('giftList', []);
    },

    postValidation(model) {
      let self = this;
      let checkVariantValid = true;
      let checkGiftValid = true;
      let checkDateGift = true;
      model.get('gifts').forEach(function (gift) {
        checkGiftValid = gift.get('validations.isValid'); //Check thời gian kết thúc > thới gian bắt đầu

        let startTime = (0, _moment.default)(gift.get('startTime'));
        let endTime = (0, _moment.default)(gift.get('endTime'));

        if (startTime.diff(endTime) > 0) {
          checkDateGift = false;
        }
      }); //Kiểm tra valid cho productVariants

      model.get('productVariants').forEach(function (variant) {
        variant.get('productVariantValues').forEach(function (value) {
          checkVariantValid = value.get('validations.isValid');
        });
      });

      if (checkVariantValid && checkGiftValid && checkDateGift) {
        this.set('productVariantList', []);
        this.set('variantOptionList', []);
        this.set('giftList', []);
        return true;
      } else {
        if (!checkVariantValid) {
          this.messenger.danger('Vui lòng điền đầy đủ thông tin của thuộc tính');
          return false;
        } else {
          if (!checkGiftValid) {
            this.messenger.danger('Vui lòng điền đầy đủ thông tin của quà tặng');
            return false;
          } else {
            if (!checkDateGift) {
              this.messenger.danger('Ngày bắt đầu quà tặng không được lớn hơn ngày kết thúc');
              return false;
            } else {
              this.messenger.danger('Vui lòng điền đầy đủ thông tin');
              return false;
            }
          }
        }
      }
    },

    preValidation() {
      this.set('model.published', 'None');
      this.set('model.seller', this.seller);
      this.set('model.productVariants', this.productVariantList);
      this.set('model.productVariantOptions', this.variantOptionList);
      this.giftList.forEach(function (gift) {
        gift.set('endTime', gift.get('endTimeString'));
        gift.set('startTime', gift.get('startTimeString'));
      });
      this.set('model.gifts', this.giftList);
      return true;
    },

    submitSuccess(model) {
      this.set('selectedTerms', []);
      this.set('productVariantList', []);
      this.set('variantOptionList', []);
      this.set('giftList', []);

      this._super(...arguments);

      let self = this;
      let isNewList = [];
      let isNewGiftList = [];
      let isNewOptionList = [];
      let isNewTierList = [];
      model.reload().then(function () {
        //Xoa obj isNew productPriceTiers
        model.get('productPriceTiers').forEach(function (tier) {
          if (tier.get('isNew')) {
            isNewTierList.pushObject(tier);
          }
        });
        isNewTierList.forEach(function (isNewObj) {
          let find = model.get('productPriceTiers').findBy('isNew', true);

          if (find) {
            model.get('productPriceTiers').removeObject(find);
            find.unloadRecord();
          }
        });
        isNewTierList = []; //Xoa obj isNew gifts

        model.get('gifts').forEach(function (gift) {
          if (gift.get('isNew')) {
            isNewGiftList.pushObject(gift);
          }
        });
        isNewGiftList.forEach(function (isNewObj) {
          let find = model.get('gifts').findBy('isNew', true);

          if (find) {
            model.get('gifts').removeObject(find);
            find.unloadRecord();
          }
        });
        isNewGiftList = []; //Xoa obj isNew productVariants

        model.get('productVariants').forEach(function (variant) {
          if (variant.get('isNew')) {
            isNewList.pushObject(variant);
          }
        });
        isNewList.forEach(function (isNewObj) {
          let find = model.get('productVariants').findBy('isNew', true);

          if (find) {
            model.get('productVariants').removeObject(find);
          }
        });
        isNewList = []; //Xoa obj isNew productVariantOptions

        model.get('productVariantOptions').forEach(function (variant) {
          if (variant.get('isNew')) {
            isNewOptionList.pushObject(variant);
          }
        });
        isNewOptionList.forEach(function (isNewObj) {
          let find = model.get('productVariantOptions').findBy('isNew', true);

          if (find) {
            model.get('productVariantOptions').removeObject(find);
          }
        });
        isNewOptionList = [];
        self.get('router').transitionTo('home.product-detail', (0, _urlName.urlName)([model.get('name')]), model.get('id'));
      });
    },

    addProductPropertyTermDetail: Ember.observer('selectedTerms.@each', function () {
      let self = this;
      self.set('model.productPropertyTermDetails', []);
      console.debug('selectedTerms', self.get('selectedTerms.content'));
      self.get('selectedTerms').forEach(function (term) {
        let productPropertyTermDetail = self.get('store').createRecord('product-property-term-detail', {
          productPropertyTerm: term
        });
        console.debug('Terms', term.get('content'));
        self.get('model.productPropertyTermDetails').pushObject(productPropertyTermDetail);
      });
      console.debug('productPropertyTermDetails', self.get('model.productPropertyTermDetails.content'));
    }),

    upgradeVariant(option) {
      let self = this;
      let variantValueList = [];
      this.productVariantList.forEach(function (productVariant) {
        let variantValue = self.get('store').createRecord('product-variant-value', {
          value: "",
          productVariantOption: option
        });
        productVariant.get('productVariantValues').pushObject(variantValue);
      });
    },

    actions: {
      addGift() {
        let toDate = (0, _moment.default)().format('YYYY-MM-DD');
        let gift = this.store.createRecord('product-gift', {
          endTimeTerm: toDate,
          startTimeTerm: toDate
        });
        this.giftList.pushObject(gift);
      },

      deleteGift(gift) {
        let self = this;
        this.giftList.removeObject(gift);
      },

      //Thêm 1 dòng variant
      addVariant() {
        let self = this;
        let variantValueList = [];

        if (this.get('variantOptionList.length') !== 0) {
          this.variantOptionList.forEach(function (option) {
            let variantValue = self.get('store').createRecord('product-variant-value', {
              value: "",
              productVariantOption: option
            });
            variantValueList.pushObject(variantValue);
          });
          let productVariant = this.store.createRecord('product-variant', {
            productVariantValues: variantValueList
          });
          this.productVariantList.pushObject(productVariant);
        } else {
          this.messenger.danger('Vui lòng thêm option trước khi thêm biến thể!');
        }
      },

      deleteVariant(variant) {
        let self = this;
        this.productVariantList.removeObject(variant);
      },

      addVariantOption() {
        this.set("openVariantOptionForm", true);
      },

      //Thêm option cho productVariant
      createVariantOption(variantOption) {
        let self = this;
        let checkOption = true;
        this.variantOptionList.forEach(function (option) {
          if (option.get('name').underscore().camelize() === variantOption.get('name').underscore().camelize()) {
            checkOption = false;
          }
        });

        if (!checkOption) {
          self.get('messenger').danger('Option đã tồn tại');
        } else {
          variantOption.set('product', this.model);
          this.variantOptionList.pushObject(variantOption);
          this.set('openVariantOptionForm', false);
          this.upgradeVariant(variantOption);
        }
      },

      //Xóa option cho productVarian
      deleteVariantOption(variantOption) {
        let self = this;
        this.variantOptionList.removeObject(variantOption);
        this.productVariantList.forEach(function (variant) {
          let find = variant.get('productVariantValues').findBy('productVariantOption.name', variantOption.get('name'));

          if (find) {
            variant.get('productVariantValues').removeObject(find);
          }
        });

        if (this.get('variantOptionList.length') === 0) {
          this.set('productVariantList', []);
        }
      } // choseColor(color)
      // {
      //   this.set('selectedColor', color);
      //   this.set('model.color', this.get('selectedColor.hexCode'));
      // },


    }
  });

  _exports.default = _default;
});