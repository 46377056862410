define("coffee-client/pod/login/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "coffee-client/mixins/route/reset-scroll"], function (_exports, _unauthenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _resetScroll.default, {
    model() {
      return this.store.findAll('option');
    }

  });

  _exports.default = _default;
});