define("coffee-client/pod/components/ecom-front/gallery-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    photoGroup: null,
    firstImage: null,
    list: null,

    init() {
      this._super(...arguments);

      this.store.query('gallery-item', {
        photoGroup: this.photoGroup
      }).then(data => {
        if (data.get('firstObject')) {
          this.set('firstImage', data.get('firstObject'));
          this.set('list', data);
          this.list.removeObject(this.list.get('firstObject'));
        }
      });
    }

  });

  _exports.default = _default;
});