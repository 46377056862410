define("coffee-client/pod/components/ecom-admin/product/select/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hOzP83q8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"label\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\"],[11,\"class\",\"col-sm-3 control-label\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[[23,[\"label\"]]],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[28,[[27,\"if\",[[23,[\"label\"]],\"col-sm-9 btn-selected height\",\"btn-selected height\"],null]]]],[9],[0,\"\\n    \"],[1,[27,\"ember-select-guru\",null,[[\"value\",\"limit\",\"keyName\",\"optionComponent\",\"singleValueComponent\",\"options\",\"multiple\",\"placeholder\",\"searchPlaceholder\",\"onSelect\",\"onSearchInputChange\"],[[23,[\"selected\"]],[23,[\"limit\"]],[23,[\"keyName\"]],[23,[\"optionComponent\"]],[23,[\"singleValueComponent\"]],[23,[\"options\"]],[23,[\"multiple\"]],[27,\"t\",[\"Click to select...\"],null],[27,\"t\",[\"Type to search...\"],null],[27,\"action\",[[22,0,[]],\"selectItem\"],null],[27,\"action\",[[22,0,[]],\"queryChanged\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"canEmpty\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"selected\"]]],null,{\"statements\":[[4,\"unless\",[[23,[\"multiple\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"btn btn-xs\"],[9],[0,\"X\"],[3,\"action\",[[22,0,[]],\"clear\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-admin/product/select/template.hbs"
    }
  });

  _exports.default = _default;
});