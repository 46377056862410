define("coffee-client/pod/home/index/route", ["exports", "coffee-client/mixins/route/reset-scroll", "coffee-client/config/environment"], function (_exports, _resetScroll, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_resetScroll.default, {
    getOption: Ember.inject.service('get-option'),
    router: Ember.inject.service(),

    setHeadTags(model) {
      let domain = _environment.default.APP.domain;
      let currentUrl = domain + this.router.urlFor('home');
      let index = currentUrl.indexOf("?");

      if (index > 0) {
        currentUrl = currentUrl.substring(0, index);
      }

      let headTags = [{
        type: 'meta',
        attrs: {
          name: 'twitter:card',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:title',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:creator',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: "website"
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:site_name',
          content: 'themondaycoffee.com'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.slogan
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: 'http:' + model.logo
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: model.description
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: currentUrl
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'description',
          content: model.description
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'keywords',
          content: model.keywords
        }
      }, {
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: currentUrl
        }
      }];
      this.set('headTags', headTags);
    },

    afterModel(model) {
      this.setHeadTags(model);
    },

    model() {
      let description = this.get('getOption').getOption('home-meta-description');
      let keywords = this.get('getOption').getOption('home-meta-keywords');
      let logo = this.get('getOption').getOption('logo');
      let slogan = 'The Monday Coffee | ' + this.get('getOption').getOption('slogan'); //

      let limit = 4;
      let news = this.store.query('news', {
        page: 1,
        limit: limit,
        published: 1,
        sort: 'createdTime',
        dir: 'desc'
      });
      let storeGroup = this.store.query('store', {
        page: 1,
        published: 1
      });
      let storeList = this.store.query('store', {
        page: 1,
        published: 1,
        limit: 8
      }); // let drinkMenu = this.get('store').query('category', {page: 1, published: 1, limit: 3,});
      // let foodMenu = this.get('store').query('category', {page: 1, published: 1, limit: 3,});
      // let allCategories = this.store.findAll('category');

      let allCategories = this.store.peekAll('category');
      return Ember.RSVP.hash({
        news: news,
        storeGroup: storeGroup,
        storeList: storeList,
        // drinkMenu: drinkMenu,
        // foodMenu: foodMenu,
        allCategories: allCategories,
        //////////
        description: description,
        keywords: keywords,
        logo: logo,
        slogan: slogan
      });
    },

    setupController(controller, model) {
      let news = model.news;
      let storeList = model.storeList;
      let allCat = model.allCategories;
      news = news.toArray(); //storeGroup = storeGroup.toArray();

      controller.set('news', news);
      controller.set('storeGroups', _environment.default.APP.coffee.storeGroups);
      controller.set('storeList', storeList);
      let listCat1 = [];
      let listCat2 = [];
      let root = model.allCategories.filterBy('parent', null);
      let simpleCatList = [];
      allCat.forEach(cat => {
        if (cat.get('parent.id')) {
          simpleCatList.addObject(cat);
        }
      });

      if (root.objectAt(0)) {
        listCat1 = model.allCategories.filterBy('parent', root.objectAt(0));
      }

      if (root.objectAt(1)) {
        listCat2 = model.allCategories.filterBy('parent', root.objectAt(1));
      }

      controller.set('listCat1', listCat1.slice(0, 3));
      controller.set('listCat2', listCat2.slice(0, 3));
      controller.set('customerRegisterModal', true);
      controller.set('sendSuccess', false);
      controller.set('customerRegister', this.store.createRecord('customer-register'));
      controller.set('allCat', allCat);
      controller.set('simpleCatList', simpleCatList);
      controller.set('openButton', true);
    }

  });

  _exports.default = _default;
});