define("coffee-client/pod/components/ecom-light-table-cell/promotion-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    promotion: null,
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let promotion = this.store.findRecord('promotion', this.value);
      this.set('promotion', promotion);
    }

  });

  _exports.default = _default;
});