define("coffee-client/pod/components/e-input/file-uploader/component", ["exports", "ecom/mixins/validated-input"], function (_exports, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    messenger: Ember.inject.service(),
    width: '150',
    url: '/upload-file',
    uploading: false,
    disabled: false,
    edit: false,
    actions: {
      uploadImageDone(data) {
        this.set('value', data.file);
        console.log('value', this.get('value'));
        this.set('uploading', false);
        this.set('edit', false);
      },

      uploadImageError() {
        this.set('value', '');
        this.messenger.danger('File is not valid');
        this.set('uploading', false);
      },

      openEdit() {
        this.set('edit', true);
      },

      removeImage() {
        this.set('edit', true);
        this.set('value', null);
      }

    }
  });

  _exports.default = _default;
});