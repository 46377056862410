define("coffee-client/pod/components/ecom-front/ads-home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nVceY2xz",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"media\",\"isDesktop\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row ads-home\"],[9],[0,\"\\n    \"],[1,[27,\"ecom-front/display-home-ads\",null,[[\"className\",\"position\",\"homeAds\",\"noDisplayPendingItem\"],[\"col-sm-6 no-padding-left\",8,[23,[\"ads\"]],true]]],false],[0,\"\\n    \"],[1,[27,\"ecom-front/display-home-ads\",null,[[\"className\",\"position\",\"homeAds\",\"noDisplayPendingItem\"],[\"col-sm-6 no-padding-right\",9,[23,[\"ads\"]],true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"row ads-home\"],[9],[0,\"\\n    \"],[1,[27,\"ecom-front/display-home-ads\",null,[[\"className\",\"position\",\"homeAds\",\"noDisplayPendingItem\"],[\"col-xs-12 no-padding\",8,[23,[\"ads\"]],true]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/ads-home/template.hbs"
    }
  });

  _exports.default = _default;
});