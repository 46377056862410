define("coffee-client/pod/components/e-form/static/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KgESn8lD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group row\"],[9],[0,\"\\n  \"],[7,\"label\"],[12,\"class\",[28,[\"col-md-\",[27,\"if\",[[23,[\"labelCol\"]],[23,[\"labelCol\"]],3],null],\" control-label\"]]],[9],[0,\"\\n    \"],[1,[27,\"t\",[[23,[\"label\"]]],null],false],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[7,\"i\"],[11,\"class\",\"required\"],[9],[0,\"*\"],[10]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"col-md-\",[27,\"if\",[[23,[\"inputCol\"]],[23,[\"inputCol\"]],9],null]]]],[9],[0,\"\\n    \"],[7,\"p\"],[11,\"class\",\"form-control-static\"],[9],[1,[21,\"content\"],false],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-form/static/template.hbs"
    }
  });

  _exports.default = _default;
});