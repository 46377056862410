define("coffee-client/pod/components/e-user/identity-name/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'span',

    init() {
      this._super(...arguments);

      this.set('content', this.get('session.data.authenticated.currentUser.displayName'));
    }

  });

  _exports.default = _default;
});