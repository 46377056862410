define("coffee-client/pod/components/ecom-front/show-more-content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    height: 400,
    tagName: 'div',
    isViewedMore: false,
    label: 'Show more',
    contentHeight: 0,

    didInsertElement() {
      this._super(...arguments);

      this.set('contentHeight', this.$('.show-more-content').css('height'));

      if (parseInt(this.contentHeight) < parseInt(this.height)) {
        this.set('isViewedMore', true);
        this.set('height', 'auto');
      }

      if (parseInt(this.contentHeight) > 500) {
        this.$('.show-more-content').css({
          'background': 'linear-gradient(#fff, #f9f9f9, #fff)'
        });
      }
    },

    init() {
      this._super(...arguments);
    },

    actions: {
      showMore() {
        this.set('height', 'auto');
        this.set('isViewedMore', true);
      }

    }
  });

  _exports.default = _default;
});