define("coffee-client/pod/components/e-file-uploader/component", ["exports", "ember-uploader", "coffee-client/config/environment"], function (_exports, _emberUploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberUploader.default.FileField.extend({
    session: Ember.inject.service(),
    messenger: Ember.inject.service(),
    headers: {},

    init() {
      this._super(...arguments);

      let headers = {};
      const data = this.get('session.data');
      let accessToken = data.authenticated.access_token;

      if (accessToken) {
        headers['Authorization'] = `Bearer ${accessToken}`;
      }

      this.set('headers', headers);
    },

    successAction: 'successAction',
    errorAction: 'errorAction',
    startUploading: false,

    filesDidChange(files) {
      let self = this;
      self.set('uploading', true);

      const uploader = _emberUploader.default.Uploader.create({
        url: _environment.default.api.host + this.url,
        ajaxSettings: {
          headers: self.get('headers')
        }
      });

      if (!Ember.isEmpty(files)) {
        uploader.upload(files[0], {}).then(data => {
          self.sendAction('successAction', data);
        }, errors => {
          if (!Ember.isEmpty(errors.responseText)) {
            console.error(JSON.parse(errors.responseText));
            self.sendAction('errorAction', errors.responseText);
          }
        });
      }
    }

  });

  _exports.default = _default;
});