define("coffee-client/pod/components/e-input/input-quantity/component", ["exports", "coffee-client/mixins/validated-input"], function (_exports, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    min: 1,
    max: 999,
    step: 1,
    model: null,
    //require
    valuePath: null,
    //require
    isPrice: false,
    className: null,
    btnDisabled: false,
    // valueChange: Ember.observer('value', function () {
    //   if (this.get('model')) {
    //     let valuePath = this.get('valuePath');
    //     this.set(`model.${valuePath}`, this.get('value'));
    //   }
    // }),
    changeValue: Ember.observer('value', function () {
      let temp = parseInt(this.value);

      if (temp < this.min || !temp) {
        this.set('value', this.min);
      }

      if (temp > this.max) {
        this.set('value', this.max);
      }

      console.log('model', this.model); //  set vo session tai day sau khi thay doi so luong
    }),

    init() {
      this._super(...arguments); // if (this.get('model')) {
      // let valuePath = this.get('valuePath');
      // this.set('value', this.get(`model.${valuePath}`));
      // }

    },

    actions: {
      focusInput() {
        this.$('.scanInput').select();
      },

      plusQuantity(value) {
        let step = parseInt(this.step);
        let quantity = parseInt(value) + step;
        this.set('value', quantity);
      },

      minusQuantity(value) {
        let step = parseInt(this.step);
        let quantity = parseInt(value) - step;
        this.set('value', quantity);
      }

    }
  });

  _exports.default = _default;
});