define("coffee-client/locales/us/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "add_title": "Add {{item}}",
    "edit_title": "Edit {{item}}",
    "confirm_delete_title": "Delete {{item}}",
    "confirm_delete_message": "Do you want to delete this {{item}}. Only non-used {{item}} can be deleted",
    //--- ember-i18n-cp-validations --------
    errors: {
      description: "This field",
      inclusion: "{{description}} is not included in the list",
      exclusion: "{{description}} is reserved",
      invalid: "{{description}} is invalid",
      confirmation: "{{description}} doesn't match {{on}}",
      accepted: "{{description}} must be accepted",
      empty: "{{description}} can't be empty",
      blank: "{{description}} can't be blank",
      present: "{{description}} must be blank",
      collection: "{{description}} must be a collection",
      singular: "{{description}} can't be a collection",
      tooLong: "{{description}} is too long (maximum is {{max}} characters)",
      tooShort: "{{description}} is too short (minimum is {{min}} characters)",
      before: "{{description}} must be before {{before}}",
      after: "{{description}} must be after {{after}}",
      wrongDateFormat: "{{description}} must be in the format of {{format}}",
      wrongLength: "{{description}} is the wrong length (should be {{is}} characters)",
      notANumber: "{{description}} must be a number",
      notAnInteger: "{{description}} must be an integer",
      greaterThan: "{{description}} must be greater than {{gt}}",
      greaterThanOrEqualTo: "{{description}} must be greater than or equal to {{gte}}",
      equalTo: "{{description}} must be equal to {{is}}",
      lessThan: "{{description}} must be less than {{lt}}",
      lessThanOrEqualTo: "{{description}} must be less than or equal to {{lte}}",
      otherThan: "{{description}} must be other than {{value}}",
      odd: "{{description}} must be odd",
      even: "{{description}} must be even",
      positive: "{{description}} must be positive",
      date: "{{description}} must be a valid date",
      email: "{{description}} must be a valid email address",
      phone: "{{description}} must be a valid phone number",
      url: "{{description}} must be a valid url"
    } //--------------------------------------

  };
  _exports.default = _default;
});