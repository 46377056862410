define("coffee-client/pod/components/e-user/change-password-form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    oldPassword: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 20
      })]
    },
    newPassword: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 20
      })]
    },
    confirmNewPassword: {
      validators: [(0, _emberCpValidations.validator)('confirmation', {
        on: 'newPassword',
        messageKey: 'Password do not match'
      })]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    opened: false,
    ajax: Ember.inject.service(),
    messenger: Ember.inject.service(),
    didValidate: false,
    processing: false,
    url: '/secret',
    actions: {
      changePassword() {
        let self = this;
        let oldPassword = this.oldPassword;
        let newPassword = this.newPassword;
        this.validate().then(_ref => {
          let {
            validations
          } = _ref;

          if (validations.get('isValid')) {
            if (newPassword === oldPassword) {
              self.get('messenger').danger('New password must be different from old password');
              self.set('processing', false);
            } else {
              self.get('ajax').request(self.get('url'), {
                method: 'POST',
                data: JSON.stringify({
                  "data": {
                    "attributes": {
                      "old-password": oldPassword,
                      "new-password": newPassword
                    }
                  }
                })
              }).then(function () {
                self.set('processing', false);
                self.get('messenger').success('Password has been updated successfully');
              }).catch(function (error) {
                self.set('processing', false);
                self.get('messenger').danger(error);
              });
            }
          } else {
            self.get('messenger').danger('Invalid inputs. Please check again');
            self.set('didValidate', true);
            self.set('processing', false);
          }
        });
      }

    }
  });

  _exports.default = _default;
});