define("coffee-client/pod/components/vp-radio-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CgDWsX/J",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[9],[0,\"\\n  \"],[1,[27,\"radio-button\",null,[[\"disabled\",\"value\",\"groupValue\",\"changed\"],[[23,[\"disabled\"]],[23,[\"value\"]],[23,[\"groupValue\"]],[23,[\"onchange\"]]]]],false],[0,\"\\n  \"],[7,\"i\"],[9],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"label1\"],[9],[1,[21,\"label\"],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/vp-radio-button/template.hbs"
    }
  });

  _exports.default = _default;
});