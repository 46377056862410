define("coffee-client/services/devices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isMobile: Ember.computed(function () {
      let isMobile = false;

      if (/Mobi/.test(navigator.userAgent)) {
        isMobile = true;
      }

      return isMobile;
    })
  });

  _exports.default = _default;
});