define("coffee-client/pod/components/e-user/sign-up-form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    displayName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 20
      })]
    },
    password: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 8,
        max: 20
      }), (0, _emberCpValidations.validator)('format', {
        regex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,20}$/,
        messageKey: 'passwordRule'
      })]
    },
    confirmPassword: {
      validators: [(0, _emberCpValidations.validator)('confirmation', {
        on: 'password',
        messageKey: 'Password do not match'
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email'
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    ajax: Ember.inject.service(),
    messenger: Ember.inject.service(),
    i18n: Ember.inject.service(),
    didValidate: false,
    completed: false,
    processing: false,
    url: '/register',
    actions: {
      signUp() {
        let self = this;
        this.validate().then(_ref => {
          let {
            validations
          } = _ref;
          self.set('didValidate', true);

          if (validations.get('isValid')) {
            self.set('processing', true);
            self.get('ajax').request(self.get('url'), {
              method: 'POST',
              data: JSON.stringify({
                "data": {
                  "attributes": {
                    "email": self.get('email'),
                    "displayName": self.get('displayName'),
                    "password": self.get('password'),
                    "phone": self.get('phone')
                  }
                }
              })
            }).then(function (data) {
              self.set('processing', false);
              self.set('completed', true);
            }).catch(function (error) {
              self.get('messenger').danger(self.get('i18n').t(error));
              self.set('processing', false);
            });
          } else {
            self.get('messenger').danger('Invalid inputs. Please check again');
          }
        });
      }

    }
  });

  _exports.default = _default;
});