define("coffee-client/pod/components/e-modal/from-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: null,
    opened: false,
    tagName: '',
    actions: {
      open() {
        this.set('opened', true);
        alert(this.opened);
      }

    }
  });

  _exports.default = _default;
});