define("coffee-client/pod/components/ecom-front/display-home-ads/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    homeAds: null,
    position: null,
    item: null,
    pendingItem: null,
    publishedItem: null,

    init() {
      this._super(...arguments);

      let find = this.homeAds.findBy('position', this.position);

      if (find) {
        //Set pendingItem & publishedItem to change image
        if (find.get('published') === 2) {
          this.set('pendingItem', find);
          this.set('item', this.pendingItem);
        }

        if (find.get('published') === 1) {
          this.set('publishedItem', find);
        } //Uu tien load pending neu co


        if (this.pendingItem) {
          this.set('item', this.pendingItem);
        } else {
          this.set('item', this.publishedItem);
        }
      }
    }

  });

  _exports.default = _default;
});