define("coffee-client/models/contact", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowNonTld: true
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    content: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 10
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    email: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    done: _emberData.default.attr('number'),
    phone: _emberData.default.attr('number'),
    note: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    contentSubject: _emberData.default.attr('string')
  });

  _exports.default = _default;
});