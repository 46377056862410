define("coffee-client/models/shift", ["exports", "ember-data", "ember-data/model", "moment", "ember-cp-validations"], function (_exports, _emberData, _model, _moment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    },
    startTime: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    endTime: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: _emberData.default.attr('string'),
    startTime: _emberData.default.attr('string'),
    endTime: _emberData.default.attr('string'),
    startDate: Ember.computed('startTime', function () {
      let time = (0, _moment.default)(this.startTime);
      console.log('pppppp', '1111:11:11' + ' ' + time.format('HH:mm:ss'));
      return '1111-11-11' + ' ' + time.format('HH:mm:ss');
    }),
    endDate: Ember.computed('endTime', function () {
      let time = (0, _moment.default)(this.endTime);
      return '1111-11-11' + ' ' + time.format('HH:mm:ss');
    }),
    startHour: Ember.computed('startTime', function () {
      let time = (0, _moment.default)(this.startTime);
      return time.format('HH:mm');
    }),
    endHour: Ember.computed('endTime', function () {
      let time = (0, _moment.default)(this.endTime);
      return time.format('HH:mm');
    })
  });

  _exports.default = _default;
});