define("coffee-client/pod/components/ecom-front/time-sheet-by-salary/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    salary: null,
    store: Ember.inject.service(),
    timeSheets: Ember.computed(function () {
      return [];
    }),

    init() {
      this._super(...arguments);

      this.set('timeSheets', this.store.query('time-sheet', {
        salary: this.get('salary.id')
      }));
    }

  });

  _exports.default = _default;
});