define("coffee-client/pod/components/e-input/input/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DSE3uh3d",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"input\",[[27,\"-input-type\",[[23,[\"type\"]]],null]],[[\"type\",\"value\",\"placeholder\",\"class\",\"name\",\"disabled\"],[[23,[\"type\"]],[23,[\"value\"]],[27,\"if\",[[23,[\"placeholder\"]],[27,\"t\",[[23,[\"placeholder\"]]],null],\"\"],null],\"form-control\",[23,[\"valuePath\"]],[23,[\"disabled\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"isValid\"]]],null,{\"statements\":[[0,\"  \"],[2,\"<span class='valid-input fa fa-check'></span>\"],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-error\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showErrorMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n      \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"message\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showWarningMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"warning\"],[9],[0,\"\\n      \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"warningMessage\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-input/input/template.hbs"
    }
  });

  _exports.default = _default;
});