define("coffee-client/pod/components/ecom-front/button-back-to-top/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classBackToTop: '',

    scrollWindown() {
      if (this.$(document).scrollTop() >= 450) {
        this.set('classBackToTop', 'active');
      } else {
        this.set('classBackToTop', '');
      }
    },

    didInsertElement() {
      this._super(...arguments);

      let self = this; // Register your events here
      // this.$ if the element is located inside the component

      this.$(document).on('scroll', function () {
        self.scrollWindown();
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.$(document).off('scroll');
    },

    actions: {
      resetScroll() {
        Ember.$('html,body').animate({
          scrollTop: 0
        }, '1500', 'swing');
      }

    }
  });

  _exports.default = _default;
});