define("coffee-client/pod/components/ecom-front/select-size-by-number/component", ["exports", "coffee-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    colors: [],
    value: "Trắng",
    selected: null,

    init() {
      this._super(...arguments);

      this.set('colors', _environment.default.APP.product.colorOptions);
      this.set('selected', this.colors[0]);
    },

    actions: {
      onchange(item) {
        this.set('selected', item);
        this.set('value', item.value);
      }

    }
  });

  _exports.default = _default;
});