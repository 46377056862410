define("coffee-client/mixins/route/permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    identity: Ember.inject.service(),
    permissions: [],

    beforeModel() {
      let flag = false;
      let identity = this.identity;
      this.permissions.forEach(function (permission) {
        if (identity.userAccess(permission)) {
          flag = true;
        }
      });

      if (!flag) {
        this.triggerAuthentication();
      }
    }

  });

  _exports.default = _default;
});