define("coffee-client/mixins/form/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    successMessage: 'Changes saved successfully',
    successAction: null,
    modelVariableName: 'model',
    didValidate: false,
    loading: false,
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),

    getModel() {
      return this.get(this.modelVariableName);
    },

    preValidation() {},

    postValidation() {
      return true;
    },

    submitSuccess(model) {
      if (this.successMessage) {
        this.messenger.success(this.successMessage);
      }
    },

    submitError(reason) {
      this.messenger.danger(reason);
    },

    actions: {
      submit() {
        let self = this;
        let model = this.getModel();
        self.set('loading', true);
        this.preValidation();
        model.validate().then(_ref => {
          let {
            model,
            validations
          } = _ref;

          if (validations.get('isValid')) {
            let postValidation = self.postValidation(model);

            if (postValidation === false) {
              self.set('loading', false);
              return postValidation;
            }

            model.save().then(function () {
              self.set('didValidate', false);
              self.submitSuccess(model);
              self.set('loading', false); //send action

              if (self.get('successAction') != null) {
                self.send('successAction', model);
              }

              return true;
            }).catch(function (reason) {
              self.submitError(reason);
              self.set('loading', false);
              return false;
            });
          } else {
            self.submitError('Invalid inputs. Please check again');
            self.set('didValidate', true);
            self.set('loading', false);
            return false;
          }
        });
      }

    }
  });

  _exports.default = _default;
});