define("coffee-client/mixins/route/scroll-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    i: 0,
    // activate: function () {
    //   this._super();
    //   this.resetScroll();
    // },
    setupController: function () {
      this._super(...arguments);

      this.scrollTop();
    },

    scrollTop() {
      console.log('i: ', 'scroll');
      let self = this;
      this.set('i', 0);
      setInterval(function () {
        if (self.get('i') < 740) {
          window.scrollTo(0, self.get('i'));
          self.set('i', self.get('i') + 10);
        }
      }, 10);
    }

  });

  _exports.default = _default;
});