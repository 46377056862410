define("coffee-client/pod/components/e-route-modal/delete-model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/10PaIpC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"e-route-modal\",null,[[\"backTo\",\"title\"],[[23,[\"backTo\"]],[23,[\"title\"]]]],{\"statements\":[[0,\"  \"],[7,\"form\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"processing\"]]],null,{\"statements\":[[0,\"      \"],[1,[21,\"e-spinner\"],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"text-danger\"],[9],[1,[21,\"messsage\"],false],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"errors\",\"length\"]]],null,{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"btn btn-bg\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"Confirm\"],null],false],[0,\"\\n          \"],[3,\"action\",[[22,0,[]],\"ok\",[23,[\"model\"]]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"button\"],[11,\"class\",\"btn btn-outline\"],[9],[0,\"\\n          \"],[1,[27,\"t\",[\"Cancel\"],null],false],[0,\"\\n        \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-route-modal/delete-model/template.hbs"
    }
  });

  _exports.default = _default;
});