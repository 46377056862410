define("coffee-client/pod/components/e-user/login-form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    identification: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 80
      })]
    },
    password: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    session: Ember.inject.service('session'),
    routing: Ember.inject.service('-routing'),
    identity: Ember.inject.service(),
    i18n: Ember.inject.service(),
    didValidate: false,
    hasErrors: false,
    processing: false,
    useVietnamese: true,
    useEnglish: false,
    forgotPasswordRoute: null,
    signUpRoute: null,
    afterAuthenticationRoute: null,
    hasSocialNetworkErrors: false,
    footer: false,
    useVietnameseChanged: Ember.observer('useVietnamese', function () {
      if (this.useVietnamese === true) {
        this.set('useEnglish', false);
      } else {
        this.set('useEnglish', true);
      }
    }),
    useEnglishChanged: Ember.observer('useEnglish', function () {
      if (this.useEnglish === true) {
        this.set('useVietnamese', false);
      } else {
        this.set('useVietnamese', true);
      }
    }),
    actions: {
      authenticateWithFacebook() {
        let self = this;
        self.get('session').authenticate('authenticator:torii', 'facebook').then(function () {}, function () {
          self.set('hasSocialNetworkErrors', true);
        });
      },

      authenticateWithGoogle() {
        let self = this;
        self.get('session').authenticate('authenticator:torii', 'google').then(function () {}, function () {
          self.set('hasSocialNetworkErrors', true);
        });
      },

      authenticate() {
        let self = this;
        self.validate().then(_ref => {
          let {
            validations
          } = _ref;
          self.set('didValidate', true);

          if (validations.get('isValid')) {
            let {
              identification,
              password
            } = self;
            self.set('processing', true);
            self.get('session').authenticate('authenticator:oauth2', identification, password).then(function (value) {
              if (self.get('useVietnamese') === true) {
                self.get('session').set('data.locale', 'vi');
              } else {
                self.get('session').set('data.locale', 'us');
              }
            }, function () {
              self.set('processing', false);
              self.set('password', '');
              self.set('errors', 'Your username and password are not correct');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});