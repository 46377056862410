define("coffee-client/pod/components/ecom-front/categories-by-position/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    categories: null,
    listChildren: null,

    init() {
      this._super(...arguments);

      let categories = [];
      this.categories.forEach(function (category) {
        categories.pushObject(category);
      });
      this.set('categories', null);
      this.set('categories', categories.sortBy('position'));
      let arr = this.categories.toArray();
      this.set('listChildren', arr.slice(0, 6));
    }

  });

  _exports.default = _default;
});