define("coffee-client/models/voucher", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    content: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    discountPercent: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    content: _emberData.default.attr('string'),
    expired: _emberData.default.attr('string'),
    discountPercent: _emberData.default.attr('number')
  });

  _exports.default = _default;
});