define("coffee-client/locales/vi/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    //job
    "__Title_application": "Mẫu điền thông tin ứng tuyển vị trí",
    "A file has been attached": "1 Tệp được dính kèm",
    "Apply this position": "Ứng tuyển vị trí này",
    "Current address": "Địa chỉ hiện tại",
    "Positions present": "Các vị trí hiện tại",
    "Upload other file": "Tải lên tệp khác",
    "Your CV file": "CV (Tập tin PDF, DOC, DOCX, và dưới 2MB)",
    "Apply success": "Ứng tuyển thành công",
    "Birthday": "Sinh nhật",
    "Gender": "Giới tính",
    "Male": "Nam",
    "Female": "Nữ",
    "Other": "Khác",
    "Copy": "Copy",
    "Copy success": "Sao chép thành công",
    //combo
    //"Date time": "Ngày tháng",
    "Combo": "Combo",
    "combo": "combo",
    "component": "thành phần",
    "Component": "Thành phần",
    "Warning quantity": "Số lượng cảnh báo",
    // "Ingredient quantity": "Số lượng thành phần",
    "Combo ingredient": "Thành phần combo",
    "Product combos": "Combo sản phẩm",
    "Add combo ingredient": "Thêm thành phần combo",
    //Salaries
    "Salary per hour": "Lương theo giờ",
    "From date": "Từ ngày",
    "To date": "Đến ngày",
    "Gross amount": "Lương Gross",
    "Net amount": "Lương Net",
    "Tax amount": "Thuế",
    "Pay": "Lương ngày",
    "salaries": "bảng lương",
    "Salaries": "Bảng lương",
    "Start time": "Thời gian bắt đầu",
    "End time": "Thời gian kết thúc",
    "Working time": "Giờ làm việc",
    "Late time": "Thời gian trễ",
    "Deducted time": "Thời gian bị trừ",
    //Employee shifts
    "Employee shifts": "Bảng lương theo ca",
    "Shift": "Ca",
    "Shifts": "Ca làm việc",
    //Activity
    "Delete time sheet": "Xóa bảng chấm công",
    "Do you want delete this time sheet?": "Bạn có muốn xóa xóa bảng chấm công này?",
    "_activity-time-note": "Chỉ điền giờ và phút - ví dụ : 8:30",
    "Do you want delete this activity?": "Bạn có muốn xóa lich làm việc này không?",
    "Delete activity": "Xóa lịch làm việc",
    "Add activity": "Thêm lịch làm việc",
    "Edit activity": "Sửa lịch làm việc",
    "Employee": "Nhân viên",
    "Out time": "Thời gian ra",
    "In time": "Thời gian vào",
    "Activities": "Lịch làm việc",
    "Activity": "Lịch làm việc",
    "activity": "lịch làm việc",
    "Time sheets": "Bảng chấm công",
    "Time sheet": "Bảng chấm công",
    "time sheet": "bảng chấm công",
    "Check in": "Check in",
    "Over time": "Tăng ca",
    "over time": "Tăng ca",
    "Time is not within the allowed range": "Thời gian không nằm trong khoảng cho phép",
    //chart
    "Hour": "Giờ",
    "Filter": "Bộ lọc",
    "Advanced filter": "Lọc nâng cao",
    "Filter by month": "Lọc theo tháng",
    "Filter by day": "Lọc theo ngày",
    "Statistics": "Thống kê",
    "Ingredients statistics": "Thống kê nguyên liệu",
    "Profit statistics": "Thống kê lợi nhuận",
    "Profit": "Lợi nhuận",
    //message
    "Some products are not available for purchasing": "Một số sản phẩm không khả dụng để đặt hàng",
    "User are not available": "Người dùng này không khả dụng",
    "Seller does not exist'": "Người bán hàng không tồn tại",
    "Order details are invalid'": "Chi tiết đơn hàng không chính xác",
    "Some products is out of stock": "Hết hàng",
    "Pin code is incorrect": "Sai mã Pin",
    "Selected status is not valid": "Trạng thái được chọn không chính xác",
    "Apply amount cannot less than total price": "Tiền nhận không được nhỏ hơn số tổng số tiền",
    "Delete store": "Xóa kho",
    "Save": "Lưu",
    "Cancel": "Hủy bỏ",
    "Do you want to delete this store? Only empty store are allowed to delete": "Bạn có thực sự muốn xóa kho này không? Chỉ những kho trống mới được phép xóa",
    "Changes saved  successfully": "Dữ liệu đã được lưu thành công",
    "Delete successfully": "Xóa thành công",
    "Add successfully": "Thêm mới thành công",
    "Invalid pin code": "Mã pin không đúng",
    "Promotion price is not higher than the selling price": "Giá khuyến mãi không được lớn hơn giá bán",
    "Selling price is not available": "Giá bán chưa có",
    "You can access and use features from the left navigation bar": "Bạn có thể truy cập và sử dụng cả các tính năng từ thanh điều hướng bên trái",
    //blog
    "Search result": "Kết quả tìm kiếm",
    "Search": "Tìm",
    //order
    "Choose a store to continue": "Vui lòng chọn một cửa hàng để tiếp tục việc đặt hàng",
    "Shipping information": "Thông tin người nhận",
    "District": "Quận",
    "Changing": "Tiền thối",
    "Discount voucher": "Voucher",
    "Sub total": "Tạm tính",
    "Discount": "Giảm giá",
    "Completer": "Người hoàn tất",
    "Can not save": "Không thể lưu",
    "Completed time": "Thời gian hoàn thành",
    "Complete order": "Hoàn tất đơn hàng",
    "Search...": "Tìm kiếm...",
    "New orders": "Đơn hàng mới",
    "Canceled user": "Người hủy",
    "Canceled time": "Ngày hủy",
    "Back to home": "Quay lại trang chủ",
    "Canceled order info": "Thông tin hủy đơn hàng",
    "Total": "Tổng cộng",
    "From": "Từ",
    "To": "Đến",
    "Size": "Kích thước",
    "Final price": "Thành tiền",
    "Order details": "Chi tiết đơn hàng",
    "Quantity": "SL",
    "Ingredient quantity": "SL ng. liệu",
    "Create order": "Tạo đơn hàng",
    "Delete": "Xóa",
    "Edit": "Sửa",
    "Orders": "Đơn hàng",
    "My orders": "Đơn hàng của tôi",
    "Order no": "Mã đơn hàng",
    "Order status": "Trạng thái đơn hàng",
    "New": "Mới",
    "Packaging": "Đóng gói",
    "Shipping": "Đang giao hàng",
    "Completed": "Hoàn tất",
    "Customer name": "Tên khách hàng",
    "Apply amount": "Số tiền nhận",
    "City/province": "Tỉnh/thành phố",
    "Full name": "Họ và tên",
    "Phone": "Số điện thoại",
    "Address": "Địa chỉ",
    "Make order steps": "Các bước đặt hàng",
    "Choose menu": "Chọn món từ thực đơn",
    "Choose payment method": "Chọn hình thức thanh toán",
    "Complete and wait": "Hoàn tất và chờ nhận hàng",
    "Continue": "Tiếp tục",
    "Apply amount can not empty": "Số tiền nhận không được để trống",
    "Payment": "Thanh toán",
    "View": "Xem",
    "View all": "Xem tất cả",
    "Clear search": "Xóa tìm kiếm",
    "Orders is created": "Đơn hàng đã tạo",
    "Print order": "In hóa đơn",
    "Shipping name": "Tên người nhận",
    "Shipping phone": "Số điện thoại người nhận",
    "Shipping address": "Địa chỉ người nhận",
    "Customer info": "Thông tin khách hàng",
    "Payment method": "Phương thức thanh toán",
    "Change status successfully": "Đổi trạng thái thành công",
    "Apply amount must be bigger final price": "Tiền nhận phải lớn hơn tổng tiền",
    "COD": "Thanh toán khi nhận hàng",
    "Payment by ATM": "Thẻ nội địa (ATM)",
    "Preparation": "Chuẩn bị",
    "Delivery": "Vận chuyển",
    "On delivery": "Đang vận chuyển",
    "Canceled": "Hủy",
    "Payment pending": "Đang thanh toán",
    "Payment failed": "Thanh toán lỗi",
    "Done": "Hoàn tất",
    "Created start": "Thời gian tạo (từ)",
    "Created end": "Thời gian tạo (đến)",
    "Created time": "Thời gian tạo",
    "Total price order": "Tổng tiền các hóa đơn:",
    "Total price": "Tổng tiền",
    "_cancel-order-note": "Bạn có muốn hủy hóa đơn này",
    "Ok": "Đồng ý",
    "Order is canceled": "Hóa đơn đã được hủy",
    "Cancel order": "Hủy đơn hàng",
    "Revocation": "Hủy",
    "No order": "Không có đơn hàng",
    "Total amount": "Tổng cộng",
    "Shipping method": "Hình thức vận chuyển",
    "Total bills": "Tổng tiền các hóa đơn",
    "Billing name": "Tên người nhận",
    "Billing address": "Địa chỉ thanh toán",
    "Billing phone": "Số điện thoại",
    "Billing city": "Billing city",
    "Daily order report": "Báo cáo hóa đơn hàng ngày",
    "Report daily bills": "Báo cáo hóa đơn hàng ngày",
    "No new order": "Không có hóa đơn mới",
    "Order of the day": "Đơn hàng trong ngày",
    "Order canceled": "Đơn hàng đã hủy",
    "Today's total": "Tổng doanh thu",
    "Your most recent order": "Hóa đơn mới nhất",
    "Timekeeping": "Bảng chấm công",
    "last five days": "năm ngày gần nhất",
    "Voucher": "Voucher",
    // Login
    "Login": "Đăng nhập",
    "Your email": "Email của bạn",
    "New member ?": "Là thành viên mới ?",
    "Create your account": "Tạo tài khoản của bạn",
    "Your username and password are not correct": "Tên tài khoản và mật khẩu không chính xác",
    "Or login with": "Hoặc đăng nhập với",
    "social_network_errors": "Chúng tôi chỉ hỗ trợ đăng nhập cho tài khoản Facebook và Google sử dụng email để đăng nhập. Đối với quý khách sử dụng số điện thoại để đăng nhập, vui lòng cập nhật email cho tài khoản Facebook hoặc Google để sử dụng tính năng này. Xin cảm ơn.",
    "Invalid inputs. Please check again": "Dữ liệu không hợp lệ. Vui lòng kiểm tra lại",
    //admin
    "User": "Người dùng",
    "Pin code": "Mã pin",
    "user": "người dùng",
    "admin": "Quản trị hệ thống",
    "Admin": "Quản trị hệ thống",
    "Users": "Người dùng",
    "Seller": "Người bán",
    "normal": "normal",
    "Your account was upgraded to seller.Please login again": "Tài khoản của bạn đã nâng cấp thành công vui lòng đăng nhập lại",
    "super user": "Nhân viên quản lý",
    "Active": "Đã kích hoạt",
    "Upgrade": "Trạng thái nâng cấp",
    "Normal pending": "Tài khoản chờ duyệt",
    "Seller pending": "Người bán chờ duyệt",
    "Requested": "Đã yêu cầu",
    "Upgraded": "Đã nâng cấp",
    "User upgrade": "Yêu cầu nâng cấp",
    "Email or name": "Email hoặc tên",
    "Roles": "Phân quyền",
    "role": "phân quyền",
    "Permission": "Quyền",
    "Allow login": "Cho phép đăng nhập",
    "Permissions": "Quyền",
    "Selected": "Được chọn",
    "sale": "Bán hàng",
    "Available": "Khả dụng",
    "Disable": "Vô hiệu hóa",
    "ID": "Mã",
    "Add user": "Thêm người dùng",
    "Password": "Mật khẩu",
    "Edit user": "Chỉnh sửa người dùng",
    "Delete user": "Xóa người dùng",
    "Do you want to delete this user?": "Bạn muốn xóa người dùng này?",
    "User role": "Quyền",
    "User ID": "Mã người dùng",
    "Manager ID": "Mã người quản lý",
    "User lists": "Danh sách người dùng",
    "Manage member": "Quản lý thành viên",
    "Price": "Giá",
    "Sub price": "Đơn giá",
    "Price...": "Giá...",
    "Total cost": "Tổng giá",
    "Price 1": "Đơn giá 1",
    "Price 2": "Đơn giá 2",
    "Price 3": "Đơn giá 3",
    "Price 4": "Đơn giá 4",
    "Status": "Trạng thái",
    "Enabled": "Được cho phép",
    "Disabled": "Vô hiệu hóa",
    "All": "Tất cả",
    "Description": "Mô tả",
    "Detail": "Chi tiết",
    "Order": "Đơn hàng",
    "BACK OFFICE": "BACK OFFICE",
    "Sold": "Đã bán",
    "Brand": "Nhãn hiệu",
    "In stock": "Trong kho",
    "Group": "Nhóm",
    "group": "nhóm",
    "Color": "Màu",
    "color": "màu",
    "Created": "Tạo",
    "created": "tạo",
    "Last edit": "Lần sửa cuối",
    "Last editor": "Người sửa cuối",
    "Rating": "Xếp hạng",
    "rating": "xếp hạng",
    "Number of rates": "Số lượng giá",
    "Number of sales": "Trong kho",
    "Shipping info": "Thông tin vận chuyển",
    "Shipping city": "Shipping city",
    "Other info": "Thông tin khác",
    "Specific": "Đăc biệt",
    "Publish": "Xuất bản",
    "Published": "Đã xuất bản",
    "None published": "Chưa xuất bản",
    "Published message": "Thông báo xuất bản",
    "Product statistic info": "Thông tin thống kê sản phẩm",
    "Not classified": "Không phân loại",
    "Boost on top at": "Tăng trên đầu trang tại",
    "Used": "Đã sử dụng",
    "used": "đã sử dụng",
    "undefined": "chưa xác định",
    "Clear": "Clear",
    "Small": "Nhỏ",
    "Medium": "Vừa",
    "Large": "Lớn",
    "Preview": "Preview",
    "Management": "Quản lý",
    "Notifications": "Thông báo",
    "Comment": "Bình luận",
    "News": "Tin tức",
    "Related news": "Tin tức liên quan",
    "news": "tin tức",
    "Modified": "Chỉnh sửa",
    "News group": "Nhóm tin",
    "News groups": "Nhóm tin",
    "News management": "Quản lý tin",
    "News group name": "Tên nhóm tin",
    "News title": "Tiêu đề tin tức",
    "Title": "Tiêu đề",
    "Url name": "Tên url",
    "Content": "Nội dung",
    "Dashboard": "Dashboard",
    "Discover": "Khám phá",
    "": "",
    //-------------------------------
    //Home
    "Register now": "Đăng ký ngay",
    "Close": "Đóng",
    "Recruitment": "Tuyển dụng",
    "Confirmed": "Đã xác nhận",
    "Email is used": "Email đã được sử dụng",
    "Phone is used": "Số điện thoại đã được sử dụng",
    "Send email failed": "Gửi mail thất bại",
    "Home": "Trang chủ",
    "Introduction": "Giới thiệu",
    "Menu": "Thực đơn",
    "Gallery": "Gallery",
    "Blog": "Blog",
    "Contact": "Liên hệ",
    "No contact": "Không có liên hệ",
    "Order online": "Đặt hàng online",
    // Introduction
    "About us": "Về chúng tôi",
    "Mission": "Sứ mệnh",
    "Career opportunities": "Cơ hội nghề nghiệp",
    //--- Logs -------------------------------
    "Log content": "Nội dung log",
    "Delete cash flow {{{code}}}": "Xóa phiếu thu chi {{{code}}}",
    "Create cash flow {{{code}}}": "Tạo phiếu thu chi {{{code}}}",
    "Update cash flow {{{code}}}": "Cập nhật phiếu thu chi {{{code}}}",
    "Delete category {{{code}}}": "Xóa ngành hàng {{{code}}}",
    "Create category {{{code}}}": "Tạo ngành hàng {{{code}}}",
    "Update category {{{code}}}": "Cập nhật ngành hàng {{{code}}}",
    "Update liabilities of customer {{{code}}}": "Cập nhật công nợ khách hàng {{{code}}}",
    "Delete customer {{{code}}}": "Xóa khách hàng {{{code}}}",
    "Create customer {{{code}}}": "Tạo khách hàng {{{code}}}",
    "Update customer {{{code}}}": "Cập khách hàng {{{code}}}",
    "Create return {{{code}}}": "Tạo đơn trả hàng {{{code}}}",
    "Delete return {{{code}}}": "Xóa đơn trả hàng {{{code}}}",
    "Create invoice {{{code}}}": "Tạo hóa đơn {{{code}}}",
    "Delete invoice {{{code}}}": "Xóa hóa đơn {{{code}}}",
    "Update delivery quantity of invoice {{{code}}}": "Cập số lượng giao của hóa đơn {{{code}}}",
    "Delete product {{{code}}}": "Xóa sản phẩm {{{code}}}",
    "Update product {{{code}}}": "Cập nhật sản phẩm {{{code}}}",
    "Create product {{{code}}}": "Tạo sản phẩm {{{code}}}",
    "Delete purchase order {{{code}}}": "Xóa đơn nhập hàng {{{code}}}",
    "Create purchase order {{{code}}}": "Tạo đơn nhập hàng {{{code}}}",
    "Delete purchase return {{{code}}}": "Xóa đơn trả hàng nhập {{{code}}}",
    "Create purchase return {{{code}}}": "Tạo đơn trả hàng nhập {{{code}}}",
    "Create stock take {{{code}}}": "Tạo đơn kiểm hàng {{{code}}}",
    "Delete store {{{code}}}": "Xóa kho {{{code}}}",
    "Update store {{{code}}}": "Cập kho {{{code}}}",
    "Create store {{{code}}}": "Tạo kho {{{code}}}",
    "Update liabilities of supplier {{{code}}}": "Cập nhật công nợ nhà cung cấp {{{code}}}",
    "Delete supplier {{{code}}}": "Xóa nhà cung cấp {{{code}}}",
    "Create supplier {{{code}}}": "Tạo nhà cung cấp {{{code}}}",
    "Update supplier {{{code}}}": "Cập nhà cung cấp {{{code}}}",
    "Update user {{{displayName}}} ({{{email}}})": "Cập nhật người dùng {{{displayName}}} ({{{email}}})",
    //--------------------------------------
    //--- Login, logout, change password ---
    "Password changed successfully": "Mật khẩu đã được thay đổi thành công",
    "Logout": "Đăng xuất",
    //--------------------------------------
    //error
    errors: {
      //ma ... is not valid suplier code
      description: "Trường này",
      inclusion: "{{description}} is not included in the list",
      exclusion: "{{description}} is reserved",
      invalid: "{{description}} không hợp lệ",
      confirmation: "{{description}} không khớp với {{on}}",
      accepted: "{{description}} phải được chấp nhận",
      empty: "{{description}} không thể chỉ chứa khoảng trống",
      blank: "{{description}} không thể trống",
      present: "{{description}} không thể thiếu",
      collection: "{{description}} must be a collection",
      singular: "{{description}} can't be a collection",
      tooLong: "{{description}} quá dài (tối đa {{max}} ký tự)",
      tooShort: "{{description}} quá ngắn (tối thiểu {{min}} ký tự)",
      before: "{{description}} must be before {{before}}",
      after: "{{description}} must be after {{after}}",
      wrongDateFormat: "{{description}} must be in the format of {{format}}",
      wrongLength: "{{description}} is the wrong length (should be {{is}} characters)",
      notANumber: "{{description}} phải là 1 số",
      notAnInteger: "{{description}} phải là 1 số nguyên",
      greaterThan: "{{description}} phải > {{gt}}",
      greaterThanOrEqualTo: "{{description}} phải ≥ {{gte}}",
      equalTo: "{{description}} phải = {{is}}",
      lessThan: "{{description}} phải < {{lt}}",
      lessThanOrEqualTo: "{{description}} phải ≥ {{lte}}",
      otherThan: "{{description}} phải khác {{value}}",
      odd: "{{description}} phải là số lẻ",
      even: "{{description}} phải là số chẵn",
      positive: "{{description}} phải là số dương",
      date: "{{description}} phải là ngày thánPassword must include at least one upper case letter, one lower case letter, and a numberg",
      email: "{{description}} phải là email",
      phone: "{{description}} phải là sồ điện thoại",
      url: "{{description}} must be a valid url"
    },
    TimeMalformed: "Thời gian không đúng định dạng giờ:phút (24 giờ)",
    messPositiveIntegers: "Trường này phải là số nguyên dương",
    messCustomerRegisterFalse: "Phải nhập Ngày-Tháng-Năm. Ví dụ: 01-01-2000",
    passwordRule: "Mật khẩu phải bao gồm tối thiểu 1 ký tự viết hoa, 1 ký tự thường, và 1 chữ số",
    "Unique key is not valid": "Từ khóa bị trùng",
    //Global sign
    "Print": "In",
    "Log": "Nhật ký người dùng",
    "Update": "Cập nhật",
    "Product": "Sản phẩm",
    "Resell": "Bán lại",
    "Product management": "Quản lý sản phẩm",
    "Product code": "Mã s.phẩm",
    "Fullname": "Tên đầy đủ",
    "Bills": "Hóa đơn",
    "Create": "Tạo mới",
    "create": "tạo mới",
    "Creator": "Người tạo",
    "creator": "người tạo",
    "Create from": "Tạo từ",
    "Create to": "Tạo ra",
    "Create date": "Ngày tạo",
    "create product": "tạo sản phẩm",
    "Create product": "Tạo sản phẩm",
    "Edit product": "Chỉnh sửa sản phẩm",
    "edit product": "chỉnh sửa sản phẩm",
    "Info": "Thông tin",
    "Invoice cancelled": "Hóa đơn bị hủy",
    // "Other": "Khác",
    "pending": "chờ xử lý",
    "Revenue": "Doanh thu",
    "Save changes": "Lưu thay đổi",
    "Code": "Mã số",
    "Notes": "Ghi chú",
    "null": "",
    "No": "Chưa",
    "Yes": "Có",
    "Hi": "Hi",
    "Logo": "Logo",
    "Banner": "Banner",
    "Collapse": "Thu gọn",
    "Unit": "Đơn vị",
    "Main": "Chính",
    "Ingredients": "Thành phần",
    "Back": "Trở lại",
    "Updating...": "Đang cập nhật...",
    //
    "No products": "Chưa có sản phẩm",
    "Pending": "Đang xử lý",
    "Date time": "Ngày giờ",
    "Date of birth": "Ngày sinh",
    "Change password": "Đổi mật khẩu",
    "Note/Reasons": "Lưu ý / Lý do",
    "of the today's": "của ngày hôm nay",
    //form errors message
    "Duration": "Khoảng thời gian",
    //  Login
    "Sign In": "Đăng nhập",
    "English": "English",
    "Vietnamese": "Tiếng Việt",
    //////////////
    "Confirm": "Đồng ý",
    ////////////
    "add_title": "Thêm {{item}}",
    "edit_title": "Cập nhật {{item}}",
    "confirm_delete_title": "Xoá {{item}}",
    "confirm_delete_message": "Bạn có muốn xoá {{item}} này hay không. Chỉ có thể xoá những {{item}} chưa được sử dụng trong hệ thống",
    "confirm_delete_message_custom": "Bạn có chắc chắn muốn xóa liên hệ này không {{item}} này hay không?",
    "confirm_delete_message_2": "Bạn có muốn xoá {{item}} này hay không.",
    /////////////////////////
    "At least one role need to be selected": "Vui lòng chọn ít nhất 1 phân quyền",
    "Changes saved successfully": "Dữ liệu đã được lưu thành công",
    // seller sign-up
    "Create seller account": "Tạo tài khoản bán hàng",
    "Please fill bellow information to create your seller account": "Vui lòng điền vào các thông tin cần thiết bên dưới",
    "Already have an account ? Login and upgrade your account": "Bạn đã tạo tài khoản ? Bạn có thể nâng cấp tài khoản để thành người bán hàng",
    "Please wait for our contact": "Chúng tôi sẽ liên hệ cho bạn sớm nhất",
    ///////////////
    "No product is found for this seller": "Người bán này không đăng bán sản phẩm nào",
    "Return to homepage": "Trở về trang chủ",
    "There are": "Có",
    "Results": "Kết quả",
    "Note": "Ghi chú",
    "Your note": "Ghi chú của bạn",
    "Guaranteed by QuaDa.vn": "Gian hàng chất lượng",
    "Guaranteed seller": "Nguời bán chất lượng",
    /// selling page
    "Manage your products": "Quản lý tất cả sản phẩm của bạn",
    "Manage your orders": "Quản lý tất cả đơn hàng của bạn",
    "Privacy Policy": "Chính sách bán hàng",
    "Product sales guide": "Hướng dẫn đăng sản phẩm",
    "Process of product processing": "Quy trình xử lý sản phẩm",
    //errors
    "Something went wrong": "Có lỗi xảy ra trong khi xử lý yêu cầu của bạn.",
    "Role name is used": "Tên quyền đã tồn tại",
    "File is not valid": "Tập tin này không hợp lệ",
    "Image is not valid": "Hình ảnh này không hợp lệ",
    "This key already exists": "Từ khóa này đã tồn tại",
    // API error code:
    "ResourceBeingUsed": "Không thể xóa. Dữ liệu này đã được sử dụng",
    ////
    // option
    "Option": "Tùy chọn",
    "option": "Tùy chọn",
    "Options": "Tùy chọn",
    "Options list": "Danh sách tùy chọn",
    "Options table": "Bảng tùy chọn",
    "No options": "Chưa có tùy chọn",
    "Name": "Tên",
    "Key": "Từ khóa",
    "Key input": "Nhập từ khóa",
    "Value": "Giá trị",
    "Create successfully!": "Tạo mới thành công!",
    //product
    "Products": "Sản phẩm",
    "product": "sản phẩm",
    "Product lists": "Danh sách sản phẩm",
    "Product name": "Tên s.phẩm",
    "Product general info": "Thông tin chung của sản phẩm",
    "Product width": "Chiều rộng sản phẩm",
    "Product height": "Chiều cao sản phẩm",
    "Product length": "Độ dài sản phẩm",
    "Product weight": "Trọng lượng sản phẩm",
    "Create new product": "Tạo mới sản phẩm",
    "Price of products": "Giá sản phẩm",
    "This code already exists": "Mã này đã tồn tại",
    //Sale
    "Salesman": "Nhân viên bán hàng",
    "Sale price": "Giá bán khuyến mãi",
    "Sale price 1": "Giá khuyến mãi 1",
    "Sale price 2": "Giá khuyến mãi 2",
    "Sale price 3": "Giá khuyến mãi 3",
    "Sale price 4": "Giá khuyến mãi 4",
    "Cashier": "Nhân viên thu ngân",
    // Input
    "Input": "Nhập",
    "Input type": "Kiểu nhập",
    "Input text": "Nhập ký tự",
    "Input number...": "Nhập số...",
    "Input number": "Nhập số",
    "Input code...": "Nhập mã...",
    "Input name...": "Nhập tên...",
    "Input group...": "Nhập nhóm...",
    "Input product group...": "Nhập nhóm sản phẩm...",
    "Input type number...": "Nhập kiếu số",
    "Enter unit (eg kg, gr, l, ml...)": "Nhập đơn vị (ví dụ: kg, gr, l, ml...)",
    "Input keyword": "Nhập từ khóa",
    // Select
    "Click to select...": "Bấm để chọn...",
    // Type
    "Type to search...": "Nhập để tìm kiếm...",
    // Image
    "Image": "Ảnh",
    "image": "ảnh",
    "Image min size": "Kích thước nhỏ nhất",
    "Image Max size": "Kích thước lớn nhất",
    "Image format": "Định dạng ảnh",
    "Image of the product": "Ảnh của sản phẩm",
    "Image upload rules": "Quy tắc tải ảnh lên",
    "Upload other image": "Tải lên hình ảnh khác",
    //change password
    "Change": "Thay đổi",
    "Old password": "Mật khẩu cũ",
    "New password": "Mật khẩu mới",
    "Retype new password": "Gõ lại mật khẩu mới",
    "Confirm new password": "Nhập lại mật khẩu mới ",
    "Password has been updated successfully": "Mật khẩu đã được cập nhật thành công",
    "Please enter bellow information to get new password": "Vui lòng điền thông tin bên dưới để đổi mật khẩu mới",
    "Password do not match": "Mật khẩu nhập lại không khớp",
    "New password must be different from old password": "Mật khẩu mới phải khác mật khẩu cũ",
    // Contact
    "contact": "Liên hệ",
    "New contacts": "Liên hệ mới",
    "Type": "Loại",
    "news group": "nhóm tin tức",
    "Position": "Vị trí",
    "Contact content": "Nội dung liên hệ",
    "Contact subject": "Bạn cần liên hệ",
    "Email": "Email",
    "Contact successfully": "Liên hệ của bạn đã được gửi thành công",
    "Not yet processed": "Chưa xử lý",
    "Have processed": "Đã xử lý",
    //
    "Send": "Gửi",
    // Banking and payment method
    "Bank": "Ngân hàng",
    "Bank name": "Tên ngân hàng",
    "Bank account": "T.khoản ngân hàng",
    "Bank account name": "Tên t.khoản ngân hàng",
    "Bank info": "Thông tin ngân hàng",
    "Bank account no": "Số t.khoản ngân hàng",
    "Bank office": "Chi nhánh ngân hàng",
    // Company
    "Company": "Công ty",
    "Tax number": "Mã số thuế",
    "Company full name": "Tên đầy đủ của công ty",
    //ads
    "Ads": "Quảng cáo",
    "Image 2": "Hình 2",
    "Link": "Link",
    "Copy link": "Copy link",
    "No ads": "Chưa có quảng cáo",
    //News
    "Create news": "Tạo tin",
    "Group management": "Quản lý nhóm",
    "Create newsgroup": "Tạo nhóm tin",
    // store
    "Store": "Cửa hàng",
    "Stores": "Cửa hàng",
    "Go to store": "Đến cửa hàng",
    //Stock take
    "Stock take": "Phiếu kiểm hàng",
    "Stock takes": "Phiếu kiểm hàng",
    "Balanced": "Đã cân bằng",
    "Balance": "Cân bằng",
    "State": "Trạng thái",
    "Difference": "Chênh lệch",
    "Add item": "Thêm item",
    "Actual quantity": "SL thực tế",
    "Inventory quantity": "SL tồn kho",
    "Difference quantity": "SL lệch",
    "Difference reasons": "Lý do chênh lệch",
    "Please select a product": "Vui lòng chọn 1 sản phẩm",
    "Balancer": "Người cân bằng",
    "Balanced time": "Thời gian cân bằng",
    "Balanced stock take can be changed": "Phiếu kiểm hàng đã cân bằng sẽ không thể chỉnh sửa được nữa",
    // Inventory
    "Inventory": "Tồn kho",
    // Cash flow
    "Cash flows": "Phiếu thu chi",
    "Cash flow": "Phiếu thu chi",
    "Disabler": "Người huỷ",
    "Flow": "Thu/chi",
    "Manually": "Tự điều chỉnh",
    "Order cancel": "Huỷ đơn hàng",
    "Cash in": "Thu",
    "Cash out": "Chi",
    "Disable cash flow": "Vô hiệu hoá phiếu thu chi",
    // Purchase order
    "Purchase order": "Phiếu nhập hàng",
    "Purchase orders": "Phiếu nhập hàng",
    "Import time": "Ngày nhập hàng",
    "Qty": "SL",
    "Last modified time": "T.gian sửa cuối",
    "Last modified": "Lần sửa cuối",
    "Last modified user": "Người sửa cuối",
    "Create new orders": "Tạo đơn hàng",
    "Order management": "Quản lý đơn hàng",
    // power select
    "No result found": "Không tìm thấy kết quả phù hợp",
    "Searching...": "Đang tìm kiếm...",
    // Others
    "Notice": "Chú ý",
    "Are you sure you want to do that": "Bạn có chắc chắn để thực hiện chưa",
    "Choose a date": "Chọn 1 ngày",
    // receipt
    "Receipt": "Thành phần",
    "receipt": "thành phần",
    "No receipts": "Chưa có thành phần",
    "Receipt Item": "Thành phần",
    "Base price": "Giá cơ bản",
    "Receipt product": "Thành phần sản phẩm",
    "Operation": "Thao tác",
    "No unit": "Chưa có đ.vị",
    "Create receipt": "Tạo thành phần",
    //Ingredients management
    "Ingredients management": "Quản lý nguyên liệu",
    "Create ingredients ": "Tạo nguyên liệu",
    "Ingredients name": "Tên nguyên liệu",
    "No ingredients": "chưa có nguyên liệu",
    //rules
    "Notes when create information": "Lưu ý khi tạo thông tin",
    "General rules": "Quy tắc chung",
    "Fields marked with an asterisk (*) are required to be entered correctly and fully.": "Các trường được đánh dấu sao (*) được yêu cầu nhập chính xác và đầy đủ.",
    "Usually when your input is wrong, there will be a reminder.": "Thông thường khi bạn nhập sai, sẽ có thông báo nhắc nhở, hoặc gợi ý.",
    "Please review the requirements, or regulations with the input.": "Vui lòng xem kỹ các yêu cầu, hoặc các quy định."
  };
  _exports.default = _default;
});