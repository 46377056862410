define("coffee-client/models/product-display", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    store: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 2,
        max: 255
      })]
    },
    description: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 400
      })]
    },
    detail: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100000
      })]
    },
    price: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    image: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'url'
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    products: _emberData.default.belongsTo('product', {
      async: true
    }),
    store: _emberData.default.belongsTo('store', {
      async: true
    }),
    expiredTime: _emberData.default.attr('string'),
    displayTime: _emberData.default.attr('string')
  });

  _exports.default = _default;
});