define("coffee-client/models/activity", ["exports", "ember-data", "ember-data/model", "ember-cp-validations", "moment", "ember-inflector"], function (_exports, _emberData, _model, _emberCpValidations, _moment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inflector = _emberInflector.default.inflector;
  inflector.irregular('activity', 'activities');
  let Validations = (0, _emberCpValidations.buildValidations)({
    employee: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    shift: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    inTime: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    outTime: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    employee: _emberData.default.belongsTo('user', {
      async: true
    }),
    shift: _emberData.default.belongsTo('shift', {
      async: true
    }),
    inTime: _emberData.default.attr('string'),
    outTime: _emberData.default.attr('string'),
    day: Ember.computed('inTime', function () {
      let day = (0, _moment.default)(this.inTime);
      return day.format('YYYY-MM-DD');
    }),
    inTimeHour: Ember.computed('inTime', function () {
      let time = (0, _moment.default)(this.inTime);
      return time.format('HH:mm');
    }),
    outTimeHour: Ember.computed('outTime', function () {
      let time = (0, _moment.default)(this.outTime);
      return time.format('HH:mm');
    })
  });

  _exports.default = _default;
});