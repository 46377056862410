define("coffee-client/pod/components/ecom-front/specific-brands/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    identity: Ember.inject.service(),
    store: Ember.inject.service(),
    specificBrands: Ember.computed(function () {
      if (this.identity.userAccess('view-all-home-brands')) {
        return this.store.query('home-brand', {});
      } else {
        if (this.identity.userAccess('view-home-brands')) {
          return this.store.query('home-brand', {
            published: 1
          });
        }
      }
    }),

    init() {
      this._super(...arguments);
    }

  });

  _exports.default = _default;
});