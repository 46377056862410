define("coffee-client/services/browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    isSafari: Ember.computed(function () {
      let isSafari = /constructor/i.test(window.HTMLElement) || function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
      }(!window['safari'] || safari.pushNotification);

      return isSafari;
    }),

    init() {
      // Safari 3.0+ "[object HTMLElementConstructor]"
      // Opera 8.0+ (UA detection to detect Blink/v8-powered Opera)
      let isOpera = !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0; // Firefox 1.0+

      let isFirefox = typeof InstallTrigger !== 'undefined'; // Internet Explorer 6-11

      let isIE =
      /*@cc_on!@*/
      false || !!document.documentMode; // Edge 20+

      let isEdge = !isIE && !!window.StyleMedia; // Chrome 1+

      let isChrome = !!window.chrome && !!window.chrome.webstore; // Blink engine detection

      let isBlink = (isChrome || isOpera) && !!window.CSS;
    }

  });

  _exports.default = _default;
});