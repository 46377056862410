define("coffee-client/pod/components/ecom-front/purchase-orders/component", ["exports", "coffee-client/mixins/infinity-table"], function (_exports, _infinityTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_infinityTable.default, {
    modelName: 'order',
    seller: null,
    code: null,
    columns: [{
      label: 'Code',
      valuePath: 'code',
      sortable: false
    }, {
      label: 'Created time',
      valuePath: 'createdTime',
      sortable: false
    }, {
      label: 'Status',
      valuePath: 'statusString',
      cellComponent: 'ecom-light-table-cell/order-status',
      sortable: false
    }, {
      label: '',
      cellComponent: 'ecom-light-table-cell/view-received-order-detail',
      valuePath: 'id',
      sortable: false
    }],

    init() {
      this._super(...arguments);

      this.set('seller', this.user);
    },

    params: ['seller', 'code']
  });

  _exports.default = _default;
});