define("coffee-client/pod/components/ecom-light-table-cell/view-received-order-detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TJbYv5EO",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"home.user.received-orders.received-order-detail\",[23,[\"value\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"View detail\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/view-received-order-detail/template.hbs"
    }
  });

  _exports.default = _default;
});