define("coffee-client/pod/components/e-modal/from-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    opened: false,
    actions: {
      open() {
        this.set('opened', true);
      }

    }
  });

  _exports.default = _default;
});