define("coffee-client/pod/components/ecom-front/seen-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J/tZYgZe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"products\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"seen-products e-slide-prod\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"seen-products-content\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"e-wrapper-cont\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"e-slide-prod\"],[9],[0,\"\\n          \"],[7,\"h3\"],[11,\"class\",\"slider-prod-tit\"],[9],[0,\"\\n            \"],[1,[27,\"t\",[\"Seen products\"],null],false],[0,\"\\n          \"],[10],[0,\"\\n          \"],[1,[27,\"ecom-front/top-products\",null,[[\"products\",\"smallSize\",\"openCartAction\"],[[23,[\"products\"]],true,[27,\"action\",[[22,0,[]],\"openCart\"],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/seen-products/template.hbs"
    }
  });

  _exports.default = _default;
});