define("coffee-client/pod/components/ecom-front/gallery-item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2quIrgGS",
    "block": "{\"symbols\":[\"galleryItem\"],\"statements\":[[4,\"if\",[[23,[\"firstImage\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"light-box\",null,[[\"href\",\"data-lightbox\",\"data-title\",\"wrapAround\",\"disableScrolling\",\"class\",\"data-alt\"],[[23,[\"firstImage\",\"url\"]],[23,[\"photoGroup\"]],[23,[\"title\"]],true,false,\"card-img-top circle\",[23,[\"alt\"]]]]],false],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"style\",\"display: none\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"list\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"light-box\",null,[[\"href\",\"data-lightbox\",\"data-title\",\"wrapAround\",\"disableScrolling\",\"class\",\"data-alt\"],[[22,1,[\"url\"]],[23,[\"photoGroup\"]],[23,[\"title\"]],true,false,\"card-img-top circle\",[23,[\"alt\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/gallery-item/template.hbs"
    }
  });

  _exports.default = _default;
});