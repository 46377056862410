define("coffee-client/mixins/infinity-table", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    page: 1,
    limit: 30,
    //@TODO: cannot load onScrolledToBottom if less than 27
    dir: 'desc',
    sort: 'id',
    totalItems: null,
    totalPrice: null,
    isLoading: false,
    table: null,
    modelName: null,
    isLast: false,
    columns: [],
    params: [],
    firstFetch: false,
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.setup();
    },

    setup() {
      this.set('table', new _emberLightTable.default(this.columns)); //    this.set('table', new Table(this.get('columns')));
      //    this.fetchRecords(true);
      //add observer for additional params
      // @todo: text field update every stroke
      //    let self = this;
      //    if (this.get('params').length > 0) {
      //      this.get('params').forEach(function (item) {
      //        self.addObserver(item, function () {
      //          self.reloadItems();
      //        });
      //      });
      //    }
    },

    didRender() {
      if (!this.firstFetch) {
        this.set('firstFetch', true);
        this.fetchRecords(true);
      }
    },

    fetchRecords(increasePage) {
      //    if (!this.get('isLast') && !this.get('isLoading')) {
      //    if (!this.get('isLast')) {
      let self = this;
      self.set('isLoading', true);
      self.get('store').query(self.get('modelName'), self.getFilters()).then(records => {
        if (!self.get('isDestroyed')) {
          self.get('table').addRows(records);
          self.set('isLast', records.get('meta.isLast'));
          self.set('totalItems', records.get('meta.total_items'));
          self.set('totalPrice', records.get('meta.totalPrice'));

          if (increasePage === true) {
            self.incrementProperty('page');
          }

          self.set('isLoading', false);
        }
      }); //    }
    },

    actions: {
      onScrolledToBottom() {
        if (!this.isLast && !this.isLoading) {
          this.fetchRecords(true);
        }
      },

      onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath'),
            page: 1
          });
          this.resetTable();
          this.fetchRecords();
        }
      },

      refresh() {
        let self = this;
        this.params.forEach(function (item) {
          self.set(item, null);
        });
        this.reloadItems();
      },

      reloadItems() {
        this.reloadItems();
      }

    },

    reloadItems() {
      this.resetTable();
      this.fetchRecords();
    },

    resetTable() {
      this.set('isLast', false);
      this.set('page', 1);
      this.table.setRows([]);
    },

    getParams() {
      return this.params;
    },

    getFilters() {
      return this.getProperties(['page', 'limit', 'sort', 'dir'].concat(this.getParams()));
    }

  });

  _exports.default = _default;
});