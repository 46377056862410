define("coffee-client/pod/components/ecom-front/select-district/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    districtOptions: ['Quận 1', 'Quận 2', 'Quận 3', 'Quận 4', 'Quận 5', 'Quận 6', 'Quận 7', 'Quận 8', 'Quận 9', 'Quận 10', 'Quận 11', 'Quận 12', 'Bình thạnh', 'Bình tân', 'Phú Nhuận', 'Gò Vấp', 'Tân Bình', 'Tân phú', 'Thủ đức'],
    value: "Quận 10"
  });

  _exports.default = _default;
});