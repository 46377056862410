define("coffee-client/models/app", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    fullName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 50
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowNonTld: true
      })]
    },
    dob: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    gender: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    currentAddress: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    cvFile: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    theStore: _emberData.default.belongsTo('store'),
    job: _emberData.default.belongsTo('job'),
    fullName: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    dob: _emberData.default.attr('string'),
    gender: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    currentAddress: _emberData.default.attr('string'),
    state: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    cvFile: _emberData.default.attr('string', {
      defaultValue: 'no-file'
    })
  });

  _exports.default = _default;
});