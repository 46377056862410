define("coffee-client/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "coffee-client/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:oauth2',
    host: _environment.default.api.host,
    primaryKey: 'id',

    shouldReloadRecord() {
      return false;
    },

    //  shouldReloadAll() {
    //    return false;
    //  },
    //
    //  shouldBackgroundReloadAll() {
    //    return false;
    //  },
    shouldBackgroundReloadRecord() {
      return false;
    }

  });

  _exports.default = _default;
});