define("coffee-client/pod/home/recruitment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iSuaPcyQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"recruitment-title \",[27,\"if\",[[27,\"or\",[[23,[\"media\",\"isMobile\"]],[23,[\"media\",\"isTablet\"]]],null],\"pl-2\"],null]]]],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"style\",\"font-size: 1em\"],[11,\"class\",\"text-uppercase mb-4\"],[9],[0,\"\\n    \"],[7,\"p\"],[9],[7,\"i\"],[9],[1,[27,\"get-option\",[\"recruitment-title\"],null],true],[10],[10],[0,\"\\n    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[7,\"b\"],[9],[1,[27,\"get-option\",[\"site-name\"],null],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"btn btn-bg-ft text-uppercase shadow-none\"],[9],[0,\"Ứng tuyển ngay\"],[3,\"action\",[[22,0,[]],\"scroll\"]],[10],[0,\"\\n\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"recruitment\"],[9],[0,\"\\n  \"],[1,[21,\"outlet\"],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/home/recruitment/template.hbs"
    }
  });

  _exports.default = _default;
});