define("coffee-client/models/order", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    shippingName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 255
      })]
    },
    shippingPhone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    shippingAddress: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 250
      })]
    },
    shippingCity: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    billingName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 255
      })]
    },
    billingPhone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    billingAddress: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 250
      })]
    },
    billingCity: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    code: _emberData.default.attr('string'),
    createdTime: _emberData.default.attr('string'),
    shippingName: _emberData.default.attr('string'),
    shippingAddress: _emberData.default.attr('string'),
    shippingCity: _emberData.default.attr('string'),
    shippingPhone: _emberData.default.attr('string'),
    billingName: _emberData.default.attr('string'),
    billingAddress: _emberData.default.attr('string'),
    billingCity: _emberData.default.attr('string'),
    billingPhone: _emberData.default.attr('string'),
    shippingMethod: _emberData.default.attr('string'),
    paymentMethod: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    totalPrice: _emberData.default.attr('number'),
    orderDetails: _emberData.default.hasMany('order-detail', {
      async: true
    }),
    seller: _emberData.default.belongsTo('user'),
    theStore: _emberData.default.belongsTo('store'),
    completer: _emberData.default.belongsTo('user'),
    completedTime: _emberData.default.attr('string'),
    paid: _emberData.default.attr('string'),
    applyAmount: _emberData.default.attr('number'),
    quadaRefId: _emberData.default.attr('string'),
    quadaU: _emberData.default.attr('string'),
    changing: _emberData.default.attr('number'),
    status: _emberData.default.attr('number'),
    discountVoucher: _emberData.default.attr('number'),
    discount: _emberData.default.attr('number'),
    statusString: Ember.computed('status', function () {
      let status = undefined;

      if (this.status === 0) {
        status = 'Disabled';
      }

      if (this.status === 1) {
        status = 'Done';
      }

      if (this.status === 2) {
        status = 'Preparation';
      }

      if (this.status === 3) {
        status = 'On delivery';
      }

      if (this.status === 4) {
        status = 'Canceled';
      }

      if (this.status === 5) {
        status = 'Payment pending';
      }

      if (this.status === 6) {
        status = 'Payment failed';
      }

      return status;
    }),
    name: Ember.computed('code', function () {
      return this.code;
    })
  });

  _exports.default = _default;
});