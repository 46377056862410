define("coffee-client/pod/components/ecom-front/profile/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    identity: Ember.inject.service(),
    login: null,
    edit: false,

    init() {
      this._super(...arguments);

      let identity = this.identity;

      if (this.get('identity.user')) {
        if (identity.userAccess('access-selling')) {
          this.set('isSeller', true);
        }

        this.set('isLogin', true);
      }
    },

    actions: {
      editProfile() {
        this.set("edit", true);
      },

      cancelEditProfile() {
        this.set("edit", false);
        this.login.rollbackAttributes();
      },

      saveSuccess() {
        this.login.reload();
      }

    }
  });

  _exports.default = _default;
});