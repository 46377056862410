define("coffee-client/pod/components/ecom-front/order-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orderDetails: [],
    totalItems: Ember.computed('orderDetails.@each.quantity', function () {
      let totalItems = 0;
      this.orderDetails.forEach(function (orderDetail) {
        totalItems = totalItems + parseInt(orderDetail.get('quantity'));
      });
      return totalItems;
    }),
    finalPrice: Ember.computed('orderDetails.@each.finalPrice', function () {
      let finalPrice = 0;
      this.orderDetails.forEach(function (orderDetail) {
        finalPrice = finalPrice + parseInt(orderDetail.get('finalPrice'));
      });
      return finalPrice;
    }),
    total: Ember.computed('finalPrice', 'shippingPrice', function () {
      if (this.shippingPrice) {
        return this.finalPrice + this.shippingPrice;
      } else {
        return this.finalPrice;
      }
    }),

    init() {
      this._super(...arguments);

      let self = this;
      this.set('orderDetails', []);
      this.get('model.orderDetails').forEach(function (orderDetail) {
        if (!orderDetail.get('isNew')) {
          self.get('orderDetails').pushObject(orderDetail);
        }
      });
    }

  });

  _exports.default = _default;
});