define("coffee-client/pod/components/ecom-front/similar-products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DuqYNE+7",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"products\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"e-slide-prod\"],[9],[0,\"\\n    \"],[7,\"h5\"],[11,\"class\",\"slider-prod-tit\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Products you may like\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[1,[27,\"ecom-front/top-products\",null,[[\"products\",\"openCartAction\"],[[23,[\"similarProducts\"]],[23,[\"openCartAction\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/similar-products/template.hbs"
    }
  });

  _exports.default = _default;
});