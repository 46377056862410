define("coffee-client/pod/components/ecom-light-table-cell/cash-flow-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hqjys7yQ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],1],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Manually\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],2],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Order\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],3],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Purchase order\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],4],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Stock take\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],5],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Order cancel\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/cash-flow-type/template.hbs"
    }
  });

  _exports.default = _default;
});