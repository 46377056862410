define("coffee-client/pod/components/ecom-front/display-product-variant/color/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    color: null,
    hexCode: null,
    mappers: [{
      value: 'Đỏ',
      code: '#FF0000'
    }, {
      value: 'Xanh lá',
      code: '#33CC00'
    }, {
      value: 'Trắng',
      code: '#FFFFFF'
    }, {
      value: 'Vàng',
      code: '#FFFF00'
    }, {
      value: 'Đen',
      code: '#111111'
    }, {
      value: 'Xanh dương',
      code: '#0099FF'
    }, {
      value: 'Tím',
      code: '#993399'
    }, {
      value: 'Vàng đồng',
      code: '#FFEC8B'
    }, {
      value: 'Xám',
      code: '#CFCFCF'
    }, {
      value: 'Bạc',
      code: '#EEEEEE'
    }, {
      value: 'Xanh biển',
      code: '#333399'
    }, {
      value: 'Cam',
      code: '#FF9900'
    }, {
      value: 'Hồng',
      code: '#FF66CC'
    }, {
      value: 'Kaki',
      code: '#8B864E'
    }, {
      value: 'Nâu',
      code: '#8B4513'
    }, {
      value: 'Xanh cổ vịt',
      code: '#306754'
    }, {
      value: 'Kem',
      code: '#FFFFCC'
    }],

    init() {
      this._super(...arguments);

      if (this.color) {
        let temp = this.mappers.findBy('value', this.color);

        if (temp) {
          this.set('hexCode', temp.code);
        }
      }
    }

  });

  _exports.default = _default;
});