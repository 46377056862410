define("coffee-client/pod/components/coffee-contact/component", ["exports", "coffee-client/mixins/form/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_create.default, {
    store: Ember.inject.service(),
    modelName: 'contact',
    contactDone: 'contactDone',

    init() {
      this._super(...arguments);

      this.set('model', this.store.createRecord('contact'));
      this.set('model.contentSubject', 'Chất lượng dịch vụ');
      this.set('contentSubjectOptions', ['Chất lượng dịch vụ', 'Chất lượng sản phẩm', 'Tuyển dụng', 'Khác']);
    },

    preValidation(model) {
      model.set('content', model.get('contentSubject') + ': ' + model.get('content'));
      return true;
    },

    postValidation(model) {
      if (model.get('contact')) {
        model.set('done', 0);
        return true;
      } else {}
    },

    submitSuccess() {
      this.set('successMessage', 'Contact successfully');

      this._super(...arguments);

      this.sendAction('contactDone');
    },

    submitError() {
      this._super(...arguments);

      this.set('model.content', '');
    },

    actions: {
      onchange() {}

    }
  });

  _exports.default = _default;
});