define("coffee-client/pod/home/forgot-password/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xmXrreeX",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"outlet\"],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"e-mar-large\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"style\",\"display: none\"],[9],[7,\"h1\"],[9],[0,\"Forgot pass\"],[10],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"a-section\"],[9],[0,\"\\n\"],[4,\"if\",[[27,\"user-access\",[\"forgot-password\"],null]],null,{\"statements\":[[0,\"      \"],[1,[27,\"e-user/forgot-password-form\",null,[[\"token\"],[[23,[\"model\",\"token\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"e-signin-footer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"e-signin-footer-cont\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/home/forgot-password/template.hbs"
    }
  });

  _exports.default = _default;
});