define("coffee-client/pod/components/ecom-admin/product/publish-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Elf4FIRG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"e-route-modal\",null,[[\"opened\",\"backTo\",\"title\"],[[23,[\"opened\"]],[23,[\"backTo\"]],[27,\"t\",[\"Published product\"],null]]],{\"statements\":[[0,\"  \"],[7,\"form\"],[11,\"class\",\"form-horizontal\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"isCancelForm\"]]],null,{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"text-danger\"],[9],[1,[27,\"t\",[\"_unPublishmesssage\"],null],false],[10],[0,\"\\n\"],[4,\"e-form/row\",null,[[\"label\",\"labelCol\",\"inputCol\"],[\"Published message\",2,10]],{\"statements\":[[0,\"        \"],[1,[27,\"e-input/textarea\",null,[[\"model\",\"valuePath\"],[[22,0,[]],\"publishedMessage\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"p\"],[11,\"class\",\"text-danger\"],[9],[1,[27,\"t\",[\"_publishmesssage\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Ok\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"\\n      \"],[1,[27,\"t\",[\"Cancel\"],null],false],[0,\"\\n    \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-admin/product/publish-modal/template.hbs"
    }
  });

  _exports.default = _default;
});