define("coffee-client/pod/components/ecom-admin/aside/left/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    identity: Ember.inject.service(),
    i18n: Ember.inject.service(),
    toggleBar: true,
    collapsedProduct: true,
    collapsedOrder: true,
    collapsedNews: true,
    collapsedSalary: true,
    collapsedStatistic: true,
    isManager: false,

    //hasRole
    init() {
      this._super(...arguments);

      this.set('isManager', this.identity.hasRole('manager'));
    },

    actions: {
      // onClick(){
      //   // alert(this.set('click', true));
      //   document.body.classList.add('sidebar-compact');
      //   return true
      // }
      toggleSidebar: function () {
        // this.toggleProperty('toggleBar');
        let toggleAside = this.toggleProperty('toggleBar');

        if (toggleAside) {
          document.body.classList.add('sidebar-compact');
        } else {
          document.body.classList.remove('sidebar-compact');
        }
      },

      toggleProduct() {
        this.toggleProperty('collapsedProduct');
      },

      toggleSalary() {
        this.toggleProperty('collapsedSalary');
      },

      toggleOrder() {
        this.toggleProperty('collapsedOrder');
      },

      toggleNews() {
        this.toggleProperty('collapsedNews');
      },

      toggleStatistic() {
        this.toggleProperty('collapsedStatistic');
      }

    }
  });

  _exports.default = _default;
});