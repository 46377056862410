define("coffee-client/pod/components/e-input/tinymce-editor/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kjsfa11J",
    "block": "{\"symbols\":[],\"statements\":[[1,[27,\"tinymce-editor\",null,[[\"options\",\"value\",\"menuBar\"],[[23,[\"options\"]],[23,[\"value\"]],[23,[\"menuBar\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-error\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showErrorMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n      \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"message\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showWarningMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"warning\"],[9],[0,\"\\n      \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"warningMessage\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-input/tinymce-editor/template.hbs"
    }
  });

  _exports.default = _default;
});