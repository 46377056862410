define("coffee-client/pod/home/menu/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    title: 'Menu - The Monday Coffee',

    loadProducts() {// this.set('products', this.get('store').query('product', {category: this.get('category.id')}));
    },

    actions: {
      setCat(cat) {
        this.set('category', cat);
        this.loadProducts();
      }

    }
  });

  _exports.default = _default;
});