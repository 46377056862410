define("coffee-client/pod/home/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    category: null,
    openButton: true,
    canonical: null,
    // currentStoreGroup: null,
    customerRegister: null,
    successMessage: 'Add successfully',
    messenger: Ember.inject.service(),
    storeOptions: {
      parallax: true,
      slidesPerView: 4,
      spaceBetween: 12,
      navigation: true
    },
    storeOptionsMobile: {
      slidesPerView: 2,
      spaceBetween: 12
    },

    submitSuccess() {
      if (this.successMessage) {
        this.messenger.success(this.successMessage);
      }
    },

    submitError(reason) {
      this.messenger.danger(reason);
    },

    loadStore() {
      this.set('storeList', this.store.query('store', {
        storeGroup: this.storeGroup
      }));
    },

    actions: {
      setStoresGroup(item) {
        this.set('storeGroup', item); // this.set('currentStoreGroup', item);

        this.loadStore();
      },

      closeModal() {
        this.set('customerRegisterModal', false);
      },

      sendRegister() {
        let model = this.customerRegister;
        this.set('openButton', false);
        model.validate().then(_ref => {
          let {
            model,
            validations
          } = _ref;

          if (validations.get('isValid')) {
            model.save().then(() => {
              this.set('sendSuccess', true);
              this.set('didValidate', false);
              this.set('openButton', true);
            }).catch(reason => {
              this.submitError(reason);
              console.log('aaa', reason);
              this.set('openButton', true);
              this.set('sendSuccess', false);
            });
          } else {
            this.submitError('Invalid inputs. Please check again');
            this.set('openButton', true);
            this.set('didValidate', true);
          }
        });
      }

    },

    init() {
      this._super(...arguments);

      this.store.query('category', {
        page: 1,
        published: 1,
        limit: 3
      }).then(cats => {
        this.set('cat', cats.get('firstObject')); // console.log('cat',this.get('cat'))
      });
    }

  });

  _exports.default = _default;
});