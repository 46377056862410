define("coffee-client/pod/components/ecom-light-table-cell/view-order-detail/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isOrderDetail: false,
    actions: {
      openOrderDetail() {
        this.set('isOrderDetail', true);
      }

    }
  });

  _exports.default = _default;
});