define("coffee-client/pod/components/ecom-front/similar-products/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    products: null,
    productId: null,
    openCartAction: null,
    similarProducts: Ember.computed(function () {
      let self = this;
      let similarProducts = [];
      this.products.forEach(function (product) {
        if (product.get('id') !== self.get('productId')) {
          similarProducts.pushObject(product);
        }
      });
      return similarProducts;
    })
  });

  _exports.default = _default;
});