define("coffee-client/pod/components/ecom-front/news/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      console.log('height', this.$('.news-image').css('height'));
      this.$('.box-title-news').css({
        'height': this.$('.news-image').css('height')
      });
    }

  });

  _exports.default = _default;
});