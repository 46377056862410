define("coffee-client/pod/components/ecom-light-table-cell/sold/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    product: null,
    backTo: null,
    changeSold: Ember.observer('sold', function () {
      if (this.sold !== this.get('product.sold')) {
        let product = this.product;
        this.save(product);
      }
    }),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let self = this;
      this.store.findRecord('product', this.value).then(function (product) {
        self.set('product', product);
        self.set('sold', product.get('sold'));
      });
    },

    save(product) {
      product.set('sold', this.sold);
      product.set('productVariants', product.get('productVariants'));
      product.save(); // product.reload();
    },

    actions: {
      save() {}

    }
  });

  _exports.default = _default;
});