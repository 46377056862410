define("coffee-client/pod/components/ecom-front/list-products/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    orderOptions: ['Mới nhất', 'Cũ nhất', 'Giá cao', 'Giá thấp'],
    brandList: [],
    heightImg: 0,
    listPage: [],
    openFilterBox: false,
    openCategoriesMobile: false,
    total: 0,
    keywordOnResult: '',
    params: null,
    sort: null,
    dir: null,
    limit: 0,
    page: 0,
    totalPage: 0,
    priceStart: 0,
    priceEnd: 60000000,
    maxPagesInList: 7,
    selectedBrands: [],
    selectedTerms: [],
    priceRange: [0, 0],
    allCategories: null,
    category: '',
    selectedCategory: null,
    seller: '',
    keyword: '',
    transitionDelay: 1000,
    productProperties: [],
    selectedBrand: null,
    openCartAction: null,
    selectedRootCategory: Ember.computed('selectedCategory', function () {
      if (!this.selectedCategory) {
        return null;
      }

      let category = this.selectedCategory.findRootNode();
      category.set('treeOpened', true);
      return category;
    }),

    init() {
      this._super(...arguments);

      if (this.get('media.isMobile')) {
        this.set('maxPagesInList', 4);
      }

      this.set('listPage', []);
      let products = this.products;
      let meta = products.get('meta'); //     let self = this;
      //     Ember.run.debounce(this, function () {
      //       self.set('heightImg', self.$('.e-list-product.mobile li .e-box-list-product img').width());
      //       self.$('.e-list-product.mobile li .e-box-list-product img').css(
      //         {"height": this.get('heightImg')});
      //     }, 100);

      this.set('keywordOnResult', this.keyword);
      this.set('keyword', null);
      this.set('selected', this.order);
      this.set('total', parseInt(meta.total_items));
      this.set('totalPage', parseInt(meta.total_pages));
      this.set('dir', this.dir);
      this.set('sort', this.sort);
      this.set('limit', parseInt(this.limit));
      this.set('page', parseInt(this.page));

      if (parseInt(this.priceStart) > 0 || parseInt(this.priceEnd) > 0) {
        this.set('activeRangeSlider', 'active');
      }

      this.set('priceRange', [parseInt(this.priceStart), parseInt(this.priceEnd)]);

      if (this.get('sellerObject.id')) {
        this.set('seller', parseInt(this.get('sellerObject.id')));
      } else {
        this.set('seller', null);
      }

      if (!this.get('selectedPage.pageNumber')) {
        this.set('selectedPage', {
          pageNumber: this.page,
          totalPage: this.totalPage
        });
      }

      for (let i = 1; i <= this.totalPage; i++) {
        this.listPage.pushObject({
          pageNumber: i,
          totalPage: this.totalPage
        });
      }
    },

    didInsertElement() {
      this._super(...arguments); //     let self = this;
      // Register your events here
      // this.$ if the element is located inside the component
      //     this.$(window).resize(function () {
      //       Ember.run.debounce(self, function () {
      //         self.set('heightImg', self.$('.e-list-product.mobile li .e-box-list-product img').width());
      //         self.$('.e-list-product.mobile li .e-box-list-product img').css(
      //           {"height": this.get('heightImg')});
      //       }, 100);
      //     });

    },

    brands: Ember.computed('selectedBrands.@each', function () {
      let idList = [];
      this.selectedBrands.forEach(function (brand) {
        idList.pushObject(brand.id);
      });
      return idList;
    }),
    priceRangeChanged: Ember.observer('priceStart', 'priceEnd', function () {
      // this.set('priceStart', this.get('priceRange').objectAt(0));
      // this.set('priceEnd', this.get('priceRange').objectAt(1));
      if (parseInt(this.priceStart) > 0 || parseInt(this.priceEnd) > 0) {
        this.set('activeRangeSlider', 'active');
      } else {
        this.set('activeRangeSlider', '');
      }

      this.set('page', 1);

      if (!this.get('media.isMobile')) {
        this.transition(2000);
      }
    }),
    brandsChange: Ember.observer('selectedBrands.@each', function () {
      this.set('page', 1);

      if (!this.get('media.isMobile')) {
        this.transition();
      }
    }),
    termsChanged: Ember.observer('selectedTerms.@each', function () {
      this.set('page', 1);

      if (!this.get('media.isMobile')) {
        this.transition();
      }
    }),
    terms: Ember.computed('selectedTerms.@each', function () {
      let idList = [];
      this.selectedTerms.forEach(function (term) {
        idList.pushObject(term.id);
      });
      console.debug('terms', idList);
      return idList;
    }),
    selectedCategoryChanged: Ember.observer('selectedCategory.id', function () {
      if (this.get('selectedCategory.id')) {
        this.set('category', this.get('selectedCategory.id'));
        this.set('page', 1);
        this.doTransition();
      }
    }),
    keywordChanged: Ember.observer('keyword', function () {
      this.transition();
    }),
    orderChanged: Ember.observer('order', function () {
      if (this.order === 'Mới nhất') {
        this.set('sort', 'createdTime');
        this.set('dir', 'desc');
      }

      if (this.order === 'Cũ nhất') {
        this.set('sort', 'createdTime');
        this.set('dir', 'asc');
      }

      if (this.order === 'Giá cao') {
        this.set('sort', 'price');
        this.set('dir', 'desc');
      }

      if (this.order === 'Giá thấp') {
        this.set('sort', 'price');
        this.set('dir', 'asc');
      }

      this.set('page', 1);
      this.doTransition();
    }),
    openCategories: Ember.observer('openCategoriesMobile', function () {
      if (this.openCategoriesMobile) {
        this.set('classMobileFilterCategories', 'clicked');
      } else {
        this.set('classMobileFilterCategories', null);
      }
    }),
    actions: {
      deleteBrandFilter(brand) {
        this.selectedBrands.removeObject(brand);
      },

      chosePage(pageInfo) {
        this.set('selectedPage', pageInfo);
        this.set('page', this.get('selectedPage.pageNumber'));
        this.doTransition();
      },

      deleteFilterProperties() {
        this.priceRange.clear();
        this.priceRange.pushObjects([0, 60000000]);
        this.set('priceStart', 0);
        this.set('priceEnd', 60000000);

        if (this.selectedBrands) {
          this.selectedBrands.clear();
        }

        if (this.selectedTerms) {
          this.selectedTerms.clear();
        }
      },

      filter() {
        this.doTransition();
      },

      openFilterBox() {
        this.toggleProperty('openFilterBox');
      },

      openCategoriesMobile() {
        this.toggleProperty('openCategoriesMobile', true);
      },

      cancelSeller() {
        this.set('seller', null);
        this.doTransition();
      },

      getPage(pageNumber) {
        this.set('page', pageNumber);
        this.doTransition();
      },

      filterByPrice(priceStart, priceEnd) {
        this.set('priceStart', priceStart);
        this.set('priceEnd', priceEnd);
        this.set('page', 1);
        this.doTransition();
      },

      setAllCategory() {
        this.set('category', null);
        this.doTransition();
      }

    },

    transition(delay) {
      if (delay === undefined) {
        delay = this.transitionDelay;
      }

      Ember.run.debounce(this, this.doTransition, delay);
    },

    doTransition() {
      if (this.get('media.isMobile')) {
        this.set('openFilterBox', false);
      }

      window.scrollTo(0, 0);
      let queries = this.getProperties(['page', 'priceStart', 'priceEnd', 'brands', 'category', 'keyword', 'seller', 'dir', 'sort', 'terms']);
      this.router.transitionTo('home.list-products', {
        queryParams: queries
      });
    }

  });

  _exports.default = _default;
});