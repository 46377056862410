define("coffee-client/pod/components/ecom-front/menu-order/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    catChild: null,
    store: Ember.inject.service(),
    products: [],
    actionName: "addCart",
    tagName: "",
    allProductPrices: [],
    theStore: null,

    init() {
      this._super(...arguments);

      this.store.query('product', {
        category: this.get('catChild.id')
      }).then(products => {
        this.set('products', products);

        if (this.get('theStore.id')) {
          this.products.forEach(product => {
            let temp = this.allProductPrices.filter((item, index, enumerable) => {
              if (item.get('shop.id') === this.get('theStore.id')) {
                return true;
              }

              return false;
            });
            temp = temp.filter((item, index, enumerable) => {
              if (item.get('product.id') === product.get('id')) {
                return true;
              }

              return false;
            });
            temp.forEach(pPrice => {
              if (pPrice.get('variant') === 1) {
                product.set('price1', pPrice.get('price'));
                product.set('salePrice1', pPrice.get('salePrice'));
              }

              if (pPrice.get('variant') === 2) {
                product.set('price2', pPrice.get('price'));
                product.set('salePrice2', pPrice.get('salePrice'));
              }
            });
          });
        }
      });
    },

    actions: {
      addCart(product, variant) {
        this.sendAction('actionName', product, variant);
      }

    }
  });

  _exports.default = _default;
});