define("coffee-client/pod/components/ecom-front/select-warranty-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GCnEYag6",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[7,\"div\"],[11,\"class\",\"e-select-color\"],[9],[0,\"\\n  \"],[2,\"{/{e-input/select model=model didValidate=didValidate valuePath=valuePath options=colors}}\"],[0,\"\\n\"],[4,\"power-select\",null,[[\"options\",\"allowClear\",\"selected\",\"searchEnabled\",\"onchange\"],[[23,[\"warrantyTypes\"]],false,[23,[\"selected\"]],false,[27,\"action\",[[22,0,[]],\"onchange\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"option-color-box\"],[9],[0,\"\\n      \"],[1,[22,1,[\"desc\"]],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/select-warranty-type/template.hbs"
    }
  });

  _exports.default = _default;
});