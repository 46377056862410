define("coffee-client/pod/components/e-spinner/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "I/RoaVd/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"lds-ring-container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"lds-ring-background\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"lds-ring\"],[9],[0,\"\\n      \"],[7,\"div\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[9],[10],[0,\"\\n      \"],[7,\"div\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-spinner/template.hbs"
    }
  });

  _exports.default = _default;
});