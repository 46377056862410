define("coffee-client/pod/components/e-action/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NJX2uNr2",
    "block": "{\"symbols\":[],\"statements\":[[2,\"<button type=\\\"button\\\" class=\\\"btn btn-danger\\\" {{action \\\"delete\\\" value}}>\"],[0,\"\\n  \"],[1,[27,\"t\",[\"Delete\"],null],false],[2,\"\"],[0,\"\\n\"],[2,\"</button>\"],[0,\"\\n\"],[2,\"<button type=\\\"button\\\" class=\\\"btn btn-info\\\" {{action \\\"edit\\\" value}}>\"],[0,\"\\n  \"],[1,[27,\"t\",[\"Edit\"],null],false],[2,\"\"],[0,\"\\n\"],[2,\"</button>\"],[0,\"\\n\"],[2,\"<button type=\\\"button\\\" class=\\\"btn btn-primary\\\" {{action \\\"create\\\" value}}>\"],[0,\"\\n  \"],[1,[27,\"t\",[\"Create\"],null],false],[2,\"\"],[0,\"\\n\"],[2,\"</button>\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-action/template.hbs"
    }
  });

  _exports.default = _default;
});