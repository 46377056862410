define("coffee-client/pod/components/ecom-admin/product-property/view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "macOHtpw",
    "block": "{\"symbols\":[\"productPropertyTerm\"],\"statements\":[[7,\"div\"],[11,\"class\",\"bg-white\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"table-info\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"productPropertyTerms\"]]],null,{\"statements\":[[0,\"      \"],[1,[22,1,[\"name\"]],false],[0,\"\\n      \"],[7,\"br\"],[9],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"pull-right e-mar-top-base\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"editRoute\"]]],null,{\"statements\":[[4,\"if\",[[27,\"user-access\",[\"manage-product-properties\"],null]],null,{\"statements\":[[4,\"link-to\",[[23,[\"editRoute\"]],[23,[\"model\",\"id\"]]],[[\"class\"],[\"btn btn-sm btn-save btn-bg\"]],{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-edit\"],[9],[10],[1,[27,\"t\",[\"Edit\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[4,\"if\",[[23,[\"deleteRoute\"]]],null,{\"statements\":[[4,\"if\",[[27,\"user-access\",[\"manage-product-properties\"],null]],null,{\"statements\":[[4,\"link-to\",[[23,[\"deleteRoute\"]],[23,[\"model\",\"id\"]]],[[\"class\"],[\"btn btn-sm btn-del\"]],{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-trash\"],[9],[10],[1,[27,\"t\",[\"Delete\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-admin/product-property/view/template.hbs"
    }
  });

  _exports.default = _default;
});