define("coffee-client/pod/components/ecom-front/top-products/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    products: null,
    loop: false,
    smallSize: false,
    openCartAction: null,

    init() {
      this._super(...arguments);

      let arr = this.products.toArray();

      if (!this.smallSize) {
        if (this.get('media.isMobile')) {
          this.set('products1', arr.slice(0, 2));
          this.set('products2', arr.slice(2, 4));
          this.set('products3', arr.slice(4, 6));
        } else {
          this.set('products1', arr.slice(0, 5));
          this.set('products2', arr.slice(5, 10));
          this.set('products3', arr.slice(10, 15));
        }
      } else {
        if (this.get('media.isMobile')) {
          this.set('products1', arr.slice(0, 2));
          this.set('products2', arr.slice(2, 4));
          this.set('products3', arr.slice(4, 6));
        } else {
          this.set('products1', arr.slice(0, 6));
          this.set('products2', arr.slice(6, 12));
          this.set('products3', arr.slice(12, 18));
        }
      }
    }

  });

  _exports.default = _default;
});