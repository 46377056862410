define("coffee-client/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    session: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    identity: Ember.inject.service(),
    queryParams: {
      quada_ref_id: {
        refreshModel: true,
        replace: true
      },
      quada_u: {
        refreshModel: true,
        replace: true
      }
    },

    init() {
      this._super(...arguments);
    },

    model(params) {
      if (params.quada_ref_id) {
        this.get('session').set('data.quadaRefId', params.quada_ref_id);
      }

      if (params.quada_u) {
        this.get('session').set('data.quadaU', params.quada_u);
      }

      return this.get('identity').resetPermissions();
    },

    sessionAuthenticated() {
      let self = this;
      return this.identity.resetPermissions().then(function () {
        let afterAuthenticationRoute = 'home';

        if (self.get('identity').userAccess('access-admin')) {
          if (self.get('identity').hasRole('normal')) {
            let currentRoute = self.get('routing').get('currentRouteName');

            if (currentRoute === 'order-customer.index') {
              afterAuthenticationRoute = 'order-customer';
            } else {
              afterAuthenticationRoute = 'admin.make-order';
            }
          } else {
            afterAuthenticationRoute = 'admin';
          }
        } else {
          window.history.back();
        } //transition to some route


        self.transitionTo(afterAuthenticationRoute);
      });
    },

    sessionInvalidated() {
      //clear contact info
      this.session.set('data.contactInfo', {});
      this.identity.resetPermissions(); //@todo: ??

      let currentRoute = this.routing.get('currentRouteName');

      if (currentRoute === 'order-customer.index') {
        this.router.transitionTo('order-customer');
      } else {
        this._super(...arguments);
      }
    }

  });

  _exports.default = _default;
});