define("coffee-client/pod/home/make-order/route", ["exports", "coffee-client/mixins/route/scroll-content"], function (_exports, _scrollContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    identity: Ember.inject.service(),
    session: Ember.inject.service(),

    model() {
      // let catParents = [];
      // let allCat = [];
      return Ember.RSVP.hash({
        // catParents: catParents,
        allCats: this.store.query('category', {
          limit: 1000
        }),
        allStores: this.store.query('store', {
          limit: 1000,
          hasSelling: '1',
          isWarehouse: '0'
        }),
        allProductPrices: this.store.findAll('product-price')
      });
    },

    afterModel(model) {
      model.catParents = [];
      model.allCatChildren = [];
      model.allCats.forEach(cat => {
        if (!cat.get('parent.id')) {
          model.catParents.pushObject(cat);
        } else {
          model.allCatChildren.pushObject(cat);
        }
      }); //console.log('allcatCHildren', model.catParents);
      //console.log('allcatCHildren', model.allCatChildren);
      //console.log('allcat', model.allCats);
    },

    setupController(controller, model) {
      this._super(...arguments);

      let data = [];
      controller.set('quadaRefId', this.get('session.data.quadaRefId'));
      controller.set('quadaU', this.get('session.data.quadaU'));
      let order = this.store.createRecord('order');
      controller.set('allStores', model.allStores);
      controller.set('order', order);
      controller.set('theStore', null);
      controller.set('orderDone', false);
      controller.set('openButton', true);
      controller.set('theStore', null);
      controller.set('catParents', model.catParents);
    },

    actions: {
      saveOrderSuccess() {
        this.transitionTo('home.order-done');
      }

    }
  });

  _exports.default = _default;
});