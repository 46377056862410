define("coffee-client/pod/components/e-input/number/component", ["exports", "coffee-client/mixins/validated-input"], function (_exports, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    init() {
      this._super(...arguments);

      let valuePath = this.valuePath;
      Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
    }

  });

  _exports.default = _default;
});