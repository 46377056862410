define("coffee-client/serializers/order", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      theStore: 'store',
      orderDetails: {
        serialize: 'records'
      }
    }
  });

  _exports.default = _default;
});