define("coffee-client/pod/components/ecom-front/home-categories/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    autoPlayTime: null,
    openCartAction: null,

    init() {
      this._super(...arguments);

      if (this.get('item.category.banner3') || this.get('item.category.banner4')) {
        this.set('autoPlayTime', 10000);
      }
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.get('item.category.mainColor')) {
        this.$('.slide-title-box .title').css({
          'background-color': this.get('item.category.mainColor')
        });
        this.$('.e-slide-prod .swiper-button-next').css({
          'border-color': this.get('item.category.mainColor'),
          'color': this.get('item.category.mainColor')
        });
        this.$('.slide-title-box .see-all').css({
          'color': this.get('item.category.mainColor')
        });
        this.$('.e-slide-prod .swiper-button-prev').css({
          'border-color': this.get('item.category.mainColor'),
          'color': this.get('item.category.mainColor')
        });
      }
    }

  });

  _exports.default = _default;
});