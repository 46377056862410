define("coffee-client/pod/home/menu/content/route", ["exports", "coffee-client/config/environment", "coffee-client/helpers/url-name"], function (_exports, _environment, _urlName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // queryParams: {
    //   brands: {
    //     refreshModel: false,
    //     replace: false
    //   }
    // },
    media: Ember.inject.service(),
    getOption: Ember.inject.service('get-option'),
    router: Ember.inject.service(),

    setHeadTags(model) {
      let domain = _environment.default.APP.domain;
      let currentUrl = domain + this.router.urlFor('home.menu');
      let index = currentUrl.indexOf("?");

      if (index > 0) {
        currentUrl = currentUrl.substring(0, index);
      }

      currentUrl = currentUrl + '/' + (0, _urlName.urlName)([model.category.name]) + '/' + model.category.id;
      let headTags = [{
        type: 'meta',
        attrs: {
          name: 'twitter:card',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:title',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:creator',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:site_name',
          content: 'themondaycoffee.com'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.category.name
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: 'http:' + model.category.image
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: model.category.description
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: currentUrl
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: "website"
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'description',
          content: model.description
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'keywords',
          content: model.keywords
        }
      }, {
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: currentUrl
        }
      }];
      this.set('headTags', headTags);
    },

    model(params) {
      let description = this.get('getOption').getOption('menu-meta-description');
      let keywords = this.get('getOption').getOption('menu-meta-keywords');
      let logo = this.get('getOption').getOption('logo');
      let slogan = 'The Monday Coffee | ' + this.get('getOption').getOption('slogan');
      this.store.findAll('option');
      return Ember.RSVP.hash({
        categories: this.store.query('category', {
          limit: 1000
        }),
        idCat: params.id,
        description: description,
        keywords: keywords,
        logo: logo,
        slogan: slogan
      });
    },

    afterModel(model) {
      let category = model.categories.findBy('id', model.idCat);
      model.category = category;
      this.setHeadTags(model);
      return model.products = this.store.query('product', {
        category: category.get('id')
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let products = model.products.toArray();
      console.log('products', products);
      controller.set('products', products.sortBy('name'));
      controller.set('categories', []);
      controller.set('title', model.category.get('name'));
      model.categories.forEach(cat => {
        if (cat.get('parent.id')) {
          controller.categories.pushObject(cat);
        }
      });
      controller.set('category', model.category);
    }

  });

  _exports.default = _default;
});