define("coffee-client/models/category", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        max: 255
      })]
    },
    description: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 1000,
        min: 0
      })]
    },
    note: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 5000,
        allowBlank: true
      })]
    },
    image: {
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'url',
        allowBlank: true
      })]
    },
    icon: {
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'url',
        allowBlank: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    code: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    children: _emberData.default.hasMany('category', {
      async: false
    }),
    parent: _emberData.default.belongsTo('category', {
      inverse: 'children',
      async: false
    }),
    image: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    icon: _emberData.default.attr('string'),
    banner1: _emberData.default.attr('string'),
    banner1Link: _emberData.default.attr('string'),
    banner2: _emberData.default.attr('string'),
    banner2Link: _emberData.default.attr('string'),
    banner3: _emberData.default.attr('string'),
    banner3Link: _emberData.default.attr('string'),
    banner4: _emberData.default.attr('string'),
    banner4Link: _emberData.default.attr('string'),
    position: _emberData.default.attr('number'),
    mainColor: _emberData.default.attr('string'),
    blockOnHome: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    treeOpened: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    selected: _emberData.default.attr(),

    findRootNode() {
      let categoryObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let category = categoryObject ? categoryObject : this;
      let parent = category.get('parent');

      if (!parent) {
        return category;
      }

      return this.findRootNode(parent);
    }

  });

  _exports.default = _default;
});