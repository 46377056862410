define("coffee-client/pod/components/ecom-front/return-product-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {},
    tagName: '',
    orderDetail: null,
    listNumber: [],
    opened: false,
    successMessage: 'Returned, please wait for contact from us',
    messenger: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.set('returnReason', null);
      this.set('returnedQuantity', 1);

      for (let i = 1; i <= this.get('orderDetail.quantity'); i++) {
        this.listNumber.pushObject(i);
      }
    },

    actions: {
      openModal() {
        this.set('opened', true);
      },

      cancel() {
        this.set('opened', false);
      },

      save() {
        let self = this;
        let orderDetail = this.orderDetail;

        if (this.returnReason) {
          orderDetail.set('returnReason', this.returnReason);
          orderDetail.set('returnedQuantity', this.returnedQuantity);
          orderDetail.save().then(function () {
            self.get('messenger').success(self.get('successMessage'));
            self.set('opened', false);
          });
        } else {
          self.get('messenger').danger('Returned reason can not empty');
        }
      }

    }
  });

  _exports.default = _default;
});