define("coffee-client/pod/components/ecom-front/button-confirm/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    action: 'action',
    delay: 3000,
    status: false,
    width: 100,
    height: 100,
    tagName: "span",
    confirmText: 'Ok ?',

    init() {
      this._super(...arguments);
    },

    didInsertElement: function () {
      this.set('width', this.$('.e-btn-default-green-sm').css('width'));
      this.set('height', this.$('.e-btn-default-green-sm').css('height'));
    },
    actions: {
      changeStatus() {
        if (!this.status) {
          this.set('status', true);
          Ember.run.debounce(this, function () {
            if (!this.isDestroyed) {
              this.set('status', false);
            }
          }, this.delay);
        } else {
          this.sendAction('action', this.userId);
        }
      }

    }
  });

  _exports.default = _default;
});