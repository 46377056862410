define("coffee-client/pod/home/recruitment/application-recruitment/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),
    actions: {
      createApplication() {
        let model = this.application;
        let self = this;
        let day = (0, _moment.default)(this.dob);
        model.set('dob', day.format('YYYY-MM-DD') + ' 00:00:00');

        if (this.dob) {
          model.validate().then(_ref => {
            let {
              model,
              validations
            } = _ref;

            if (validations.get('isValid')) {
              model.save().then(function () {
                self.set('didValidate', false);
                self.set('openModal', false);
                self.get('messenger').success('Apply success');
                return true;
              }).catch(function (reason) {
                // self.submitError(reason);
                return false;
              });
            } else {
              self.get('messenger').danger('Invalid inputs. Please check again');
              self.set('didValidate', true);
              return false;
            }
          });
        } else {
          self.get('messenger').danger('Invalid inputs. Please check again');
          self.set('didValidate', true);
          return false;
        }
      },

      closeModal() {
        this.set('openModal', false);
      },

      openForm(job) {
        this.set('openModal', true);
        this.set('application', this.store.createRecord('app'));
        this.set('application.gender', "1");
        this.set('application.job', job);
        this.set('application.theStore', this.theStore);
      }

    }
  });

  _exports.default = _default;
});