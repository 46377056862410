define("coffee-client/pod/components/ecom-light-table-cell/in-stock-form/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    product: null,
    backTo: null,
    opened: false,
    tagName: '',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let self = this;
      this.store.findRecord('product', this.value).then(function (product) {
        self.set('product', product);
      });
    },

    actions: {
      openModal() {
        this.set('opened', true);
      },

      closeModal() {
        this.set('opened', false);
      }

    }
  });

  _exports.default = _default;
});