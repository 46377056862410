define("coffee-client/models/product-price", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    shop: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    variant: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    salePrice: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    },
    price: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    shop: _emberData.default.belongsTo('store'),
    product: _emberData.default.belongsTo('product'),
    variant: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    price: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    salePrice: _emberData.default.attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});