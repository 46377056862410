define("coffee-client/templates/components/no-options-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XbaIOtzV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"ember-select-guru__option\"],[9],[0,\"No options.\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/templates/components/no-options-component.hbs"
    }
  });

  _exports.default = _default;
});