define("coffee-client/pod/home/menu/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      this.store.findAll('option');
      return Ember.RSVP.hash({
        categories: this.store.query('category', {
          limit: 1000
        })
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('categories', []);
      model.categories.forEach(cat => {
        if (cat.get('parent.id')) {
          controller.categories.pushObject(cat);
        }
      });
      controller.set('category', model.category);
    }

  });

  _exports.default = _default;
});