define("coffee-client/pod/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cwYF3meP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"coffee\"],[12,\"style\",[28,[\"background-image: url(\",[27,\"await\",[[27,\"get-option\",[\"bg-header-1\"],null]],null],\"),url(\",[27,\"await\",[[27,\"get-option\",[\"bg-header-2\"],null]],null],\");\\n       background-position:-300px -200px,550px 120px;\\n       background-repeat: no-repeat,no-repeat;\\n       background-size: 956px 637px,956px 680px;\\n       padding-bottom: 65px;\"]]],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"session\",\"isAuthenticated\"]]],null,{\"statements\":[[0,\"    \"],[7,\"br\"],[9],[10],[0,\"\\n    \"],[1,[27,\"e-user/login-form\",null,[[\"forgotPasswordRoute\",\"signUpRoute\"],[\"home.forgot-password-request\",\"home.signup\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/login/template.hbs"
    }
  });

  _exports.default = _default;
});