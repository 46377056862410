define("coffee-client/models/news-group", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    },
    urlName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    },
    note: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 0,
        max: 5000,
        allowBlank: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    urlName: _emberData.default.attr('string'),
    published: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    type: _emberData.default.attr('string', {
      defaultValue: 'news'
    }),
    position: _emberData.default.attr('number', {
      defaultValue: 9
    })
  });

  _exports.default = _default;
});