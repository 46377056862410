define("coffee-client/mixins/route/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    modelPath: null,

    deactivate() {
      let path = 'currentModel';

      if (this.modelPath) {
        path = 'currentModel.' + this.modelPath;
      }

      this.store.unloadRecord(this.get(path));
    }

  });

  _exports.default = _default;
});