define("coffee-client/pod/components/ecom-front/footer/ls-1/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WqxA5d06",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\"],[11,\"class\",\"footer pt-4\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"footer-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"container-fluid text-center\"],[9],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"site-name color-white\"],[9],[1,[27,\"await\",[[27,\"get-option\",[\"site-name\"],null]],null],true],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"color-white\"],[9],[1,[27,\"await\",[[27,\"get-option\",[\"slogan\"],null]],null],true],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"color-white\"],[9],[1,[27,\"await\",[[27,\"get-option\",[\"contact-address\"],null]],null],true],[10],[0,\"\\n      \"],[7,\"p\"],[11,\"class\",\"color-white\"],[9],[7,\"span\"],[11,\"class\",\"text-uppercase\"],[9],[0,\"Hot line:\"],[10],[0,\" \"],[1,[27,\"await\",[[27,\"get-option\",[\"contact-phone\"],null]],null],true],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"footer-copyright text-center py-3\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"container-fluid\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"color-white\"],[9],[0,\"\\n          © 2018 - Copyright of \"],[1,[27,\"await\",[[27,\"get-option\",[\"site-name\"],null]],null],true],[0,\". Developed by \"],[7,\"a\"],[11,\"href\",\"http://pttnewtech.com/\"],[11,\"Title\",\"PTT\"],[11,\"target\",\"_blank\"],[9],[0,\"PTT\"],[10],[0,\".\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/footer/ls-1/template.hbs"
    }
  });

  _exports.default = _default;
});