define("coffee-client/serializers/role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      permissionList: {
        serialize: 'records'
      }
    },

    serialize(snapshot) {
      let json = this._super(...arguments);

      let permissionList = [];

      if (snapshot.hasMany('permissionList')) {
        snapshot.hasMany('permissionList').forEach(function (item) {
          permissionList.push(item.attr('name'));
        });
      }

      json.data.attributes['permission-list'] = permissionList;
      delete json.data.relationships;
      return json;
    }

  });

  _exports.default = _default;
});