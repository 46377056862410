define("coffee-client/pod/components/ecom-light-table-cell/product-name/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZojcPZUY",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"home.product-detail\",[27,\"url-name\",[[23,[\"value\",\"name\"]]],null],[23,[\"value\",\"id\"]]],null,{\"statements\":[[0,\"  \"],[1,[23,[\"value\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/product-name/template.hbs"
    }
  });

  _exports.default = _default;
});