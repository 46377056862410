define("coffee-client/pod/components/ecom-front/product/images/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    size: 's',
    bigImage: null,
    listImages: [],
    options: {
      fullscreenEl: false,
      zoomEl: false,
      shareEl: false
    },
    bigImageChanged: Ember.observer('bigImage', function () {
      this.listImages.clear();
      this.listImages.pushObject({
        src: this.bigImage,
        w: 1000,
        h: 1000
      });
    }),

    init() {
      this._super(...arguments);

      this.set('bigImage', this.get('product.image1'));

      if (this.get('product.published') === "Published") {
        this.set('size', 's');
      } else {
        this.set('size', '');
      }

      this.listImages.clear();
      this.listImages.pushObject({
        src: this.get('product.image1'),
        w: 1000,
        h: 1000
      });
    },

    actions: {
      changeImage(image) {
        this.set('bigImage', image);
      }

    }
  });

  _exports.default = _default;
});