define("coffee-client/pod/home/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      // this.get('cart').getCartData();
      document.body.classList.add('app');
      document.body.classList.add('app-site');
    },

    model() {
      this.store.findAll('category');
      return this.store.findAll('option');
    },

    afterModel() {
      document.body.classList.add('theme-basic');
      document.body.classList.add('theme-tmc'); //getOption
      // document.body.classList.add('theme-' + this.get('getOption').getOption('ui-site-color')); //getOption
    }

  });

  _exports.default = _default;
});