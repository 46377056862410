define("coffee-client/pod/components/ecom-front/form-add-variant-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NrqjNxQY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"variant-option\"],[9],[0,\"\\n  \"],[7,\"ul\"],[9],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[1,[27,\"vp-radio-button\",null,[[\"label\",\"value\",\"groupValue\"],[[27,\"t\",[\"Color\"],null],\"Màu sắc\",[23,[\"variantOptionName\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[1,[27,\"vp-radio-button\",null,[[\"label\",\"value\",\"groupValue\"],[[27,\"t\",[\"Size (by number)\"],null],\"Kích thước (theo số)\",[23,[\"variantOptionName\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[1,[27,\"vp-radio-button\",null,[[\"label\",\"value\",\"groupValue\"],[[27,\"t\",[\"Size (by symbol)\"],null],\"Kích thước (theo chữ)\",[23,[\"variantOptionName\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"li\"],[9],[0,\"\\n      \"],[1,[27,\"vp-radio-button\",null,[[\"label\",\"value\",\"groupValue\"],[[27,\"t\",[\"Other\"],null],\"\",[23,[\"variantOptionName\"]]]]],false],[0,\"\\n\"],[4,\"unless\",[[23,[\"variantOptionName\"]]],null,{\"statements\":[[0,\"        \"],[1,[27,\"e-input/input\",null,[[\"model\",\"valuePath\",\"didValidate\"],[[22,0,[]],\"variantOptionInput\",[23,[\"didValidate\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-white\"],[9],[1,[27,\"t\",[\"Add variant option\"],null],false],[3,\"action\",[[22,0,[]],\"addVariantOption\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/form-add-variant-option/template.hbs"
    }
  });

  _exports.default = _default;
});