define("coffee-client/mixins/route/reset-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    activate: function () {
      this._super();

      this.resetScroll();
    },
    beforeModel: function () {
      this._super(...arguments);

      this.resetScroll();
    },

    resetScroll() {
      window.scrollTo(0, 0);
    }

  });

  _exports.default = _default;
});