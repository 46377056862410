define("coffee-client/pod/components/ecom-front/seen-products/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    identity: Ember.inject.service(),
    session: Ember.inject.service(),
    seenProductService: Ember.inject.service('seen-products'),
    store: Ember.inject.service(),
    products: [],
    ids: [],
    currentProduct: null,
    triggerOpenCart: 'triggerOpenCart',
    changeSeenProduct: Ember.observer('seenProductService.seenProducts', 'seenProductService.currentProductId', function () {
      let self = this;
      this.set('products', []);
      this.set('ids', []);
      let seenProductsId = this.get('seenProductService.seenProducts');
      let currentProductId = this.get('seenProductService.currentProductId');
      seenProductsId.forEach(function (id) {
        self.get('ids').pushObject(id);
      });
      let idReverse = this.ids.reverse(); //reverse array id

      let idString = idReverse.join(); //change list id from array to string
      //    param limit

      let limit = 0;

      if (this.get('media.isMobile')) {
        limit = 4;
      }

      if (this.get('media.isTablet')) {
        limit = 5;
      }

      if (this.get('media.isDesktop')) {
        limit = 10;
      }

      if (this.ids) {
        //Mang ids la thu tu cua san pham da xem - sap xep san pham trong productList theo thu tu
        this.store.query('product', {
          ids: idString,
          limit: limit
        }).then(function (productList) {
          self.get('ids').forEach(function (id) {
            productList.forEach(function (product) {
              if (product.get('id') === id && product.get('id') !== currentProductId) {
                self.get('products').pushObject(product);
              }
            });
          });
        });
      }
    }),

    init() {
      this._super(...arguments);

      this.changeSeenProduct();
    },

    actions: {
      openCart() {
        this.sendAction('triggerOpenCart');
      }

    }
  });

  _exports.default = _default;
});