define("coffee-client/pod/home/gallery/route", ["exports", "coffee-client/mixins/route/scroll-content"], function (_exports, _scrollContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollContent.default, {
    setupController(controller) {
      this._super(...arguments);

      controller.set('items', [{
        src: 'http://lorempixel.com/1024/768/nature/1',
        w: 1024,
        h: 768
      }, {
        src: 'http://lorempixel.com/768/1024/nature/2',
        w: 768,
        h: 1024
      }, {
        src: 'http://lorempixel.com/768/768/nature/3',
        w: 768,
        h: 768
      }]);
    }

  });

  _exports.default = _default;
});