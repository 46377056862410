define("coffee-client/torii-providers/google", ["exports", "torii/providers/google-oauth2"], function (_exports, _googleOauth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _googleOauth.default.extend({
    fetch(data) {
      return data;
    }

  });

  _exports.default = _default;
});