define("coffee-client/models/employee-shift", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    employee: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    shift: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    salaryPerHour: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    employee: _emberData.default.belongsTo('user', {
      async: true
    }),
    shift: _emberData.default.belongsTo('shift', {
      async: true
    }),
    salaryPerHour: _emberData.default.attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});