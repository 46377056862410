define("coffee-client/pod/components/ecom-light-table-cell/sold/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1W3Ka+i2",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"sold-toggle-btn\"],[9],[0,\"\\n  \"],[1,[27,\"x-toggle\",null,[[\"value\",\"theme\",\"onToggle\"],[[23,[\"sold\"]],\"light\",[27,\"action\",[[22,0,[]],[27,\"mut\",[[23,[\"sold\"]]],null]],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/sold/template.hbs"
    }
  });

  _exports.default = _default;
});