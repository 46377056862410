define("coffee-client/helpers/plus-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.plusNumber = plusNumber;

  function plusNumber(params, namedArgs) {
    let count = null;

    if (Ember.isPresent(namedArgs.number) && Ember.isPresent(namedArgs.step)) {
      count = parseInt(namedArgs.number) + parseInt(namedArgs.step);
    }

    return count;
  }

  var _default = Ember.Helper.helper(plusNumber);

  _exports.default = _default;
});