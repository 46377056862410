define("coffee-client/pod/components/ecom-light-table-cell/in-stock-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1w9fpAGA",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"btn btn-sm btn-save btn-bg\"],[9],[0,\"\\n  \"],[1,[27,\"t\",[\"Change inventory\"],null],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"openModal\"]],[10],[0,\"\\n\\n\"],[4,\"e-route-modal\",null,[[\"opened\",\"backTo\",\"title\"],[[23,[\"opened\"]],[23,[\"backTo\"]],[27,\"t\",[\"Change inventory\"],null]]],{\"statements\":[[0,\"  \"],[1,[27,\"ecom-front/in-stock-modal\",null,[[\"product\"],[[23,[\"product\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/in-stock-form/template.hbs"
    }
  });

  _exports.default = _default;
});