define("coffee-client/helpers/user-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //@todo user access
  var _default = Ember.Helper.extend({
    identity: Ember.inject.service(),

    compute(params) {
      return this.identity.userAccess(params[0]);
    }

  });

  _exports.default = _default;
});