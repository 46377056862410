define("coffee-client/pod/home/order-done/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hbGikVdP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"wrapper\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"make-order-steps mb-4 px-5\"],[9],[0,\"\\n    \"],[7,\"h5\"],[9],[1,[27,\"t\",[\"Order online\"],null],false],[10],[0,\"\\n    \"],[2,\"<ul>\"],[0,\"\\n    \"],[2,\"<li>{{t \\\"Shipping info\\\"}}</li>\"],[0,\"\\n    \"],[2,\"<li>{{t \\\"Choose menu\\\"}}</li>\"],[0,\"\\n    \"],[2,\"<li>{{t \\\"Choose payment method\\\"}}</li>\"],[0,\"\\n    \"],[2,\"<li>{{t \\\"Complete and wait\\\"}}</li>\"],[0,\"\\n    \"],[2,\"</ul>\"],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"row order-done\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-4\"],[9],[0,\"\\n      \"],[7,\"img\"],[11,\"src\",\"Images/order-done.jpg\"],[9],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-md-8\"],[9],[0,\"\\n      \"],[7,\"br\"],[9],[10],[0,\"\\n      \"],[7,\"p\"],[9],[0,\" \"],[1,[27,\"get-option\",[\"finish-order-note\"],null],true],[10],[0,\"\\n\\n      \"],[7,\"div\"],[11,\"class\",\"inline-block\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"home\"],[[\"class\"],[\"btn btn-green\"]],{\"statements\":[[0,\"          \"],[1,[27,\"t\",[\"Back to home\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/home/order-done/template.hbs"
    }
  });

  _exports.default = _default;
});