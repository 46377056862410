define("coffee-client/pod/home/forgot-password/route", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin", "ecom/mixins/route/reset-scroll"], function (_exports, _unauthenticatedRouteMixin, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, _resetScroll.default, {
    model(params) {
      return Ember.RSVP.hash({
        token: params.token
      });
    }

  });

  _exports.default = _default;
});