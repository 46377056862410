define("coffee-client/pod/components/ecom-front/select-size-by-number/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Md0U0HJP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"e-select-city\"],[9],[0,\"\\n  \"],[1,[27,\"e-input/select\",null,[[\"model\",\"didValidate\",\"valuePath\",\"options\"],[[23,[\"model\"]],[23,[\"didValidate\"]],[23,[\"valuePath\"]],[23,[\"sizeOptions\"]]]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/select-size-by-number/template.hbs"
    }
  });

  _exports.default = _default;
});