define("coffee-client/models/salary", ["exports", "ember-data", "ember-data/model", "moment", "ember-cp-validations", "ember-inflector"], function (_exports, _emberData, _model, _moment, _emberCpValidations, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inflector = _emberInflector.default.inflector;
  inflector.irregular('salary', 'salaries');
  let Validations = (0, _emberCpValidations.buildValidations)({
    employee: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    fromDate: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    toDate: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    employee: _emberData.default.belongsTo('user', {
      async: true
    }),
    fromDate: _emberData.default.attr('string'),
    toDate: _emberData.default.attr('string'),
    grossAmount: _emberData.default.attr('number'),
    taxAmount: _emberData.default.attr('number'),
    netAmount: _emberData.default.attr('number'),
    createdTime: _emberData.default.attr('string')
  });

  _exports.default = _default;
});