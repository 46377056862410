define("coffee-client/router", ["exports", "coffee-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.on('routeDidChange', () => {
        const page = this.router.currentURL;
        const title = this.router.currentRouteName || 'unknown';
        this.metrics.trackPage({
          page,
          title
        });
      });
    }

  });
  Router.map(function () {
    this.route('print-stock-take', {
      path: '/stock-takes/print/:stock_take_id'
    });
    this.route('login', {
      path: '/dang-nhap'
    });
    this.route('order-customer', {
      path: 'don-hang'
    }, function () {});
    this.route('home', {
      path: '/'
    }, function () {
      this.route('signup', {
        path: '/dang-ky'
      });
      this.route('forgot-password-request', {
        path: '/yeu-cau-lay-lai-mat-khau'
      });
      this.route('forgot-password', {
        path: '/lay-lai-mat-khau/:token'
      });
      this.route('make-order', {
        path: '/tao-don-hang'
      });
      this.route('order-done', {
        path: '/tao-don-hang-thanh-cong'
      });
      this.route('contact', {
        path: '/lien-he'
      });
      this.route('intro', {
        path: '/gioi-thieu'
      });
      this.route('menu', {
        path: '/menu'
      }, function () {
        this.route('content', {
          path: '/:catName/:id'
        });
      });
      this.route('recruitment', {
        path: '/tuyen-dung'
      }, function () {
        this.route('application-recruitment', {
          path: '/:storeName/:id'
        });
      });
      this.route('news', {
        path: '/tin-tuc'
      }, function () {
        this.route('detail', {
          path: '/:urlName/:id'
        });
      });
    }); // this.route('print-salary', {path: '/print-salary/:id'});
    // this.route('admin', {path: '/'}, function () {
    //   this.route('change-password', {path: '/doi-mat-khau'});
    //   this.route('profit-statistics');
    //   this.route('ingredients-statistics');
    //   this.route('homepage-ads', function () {
    //     this.route('add');
    //     this.route('delete', {path: '/:id/delete'});
    //     this.route('edit', {path: '/:id/edit'});
    //   });
    //
    //   this.route('contact', function () {
    //     this.route('edit', {path: '/:id/edit'});
    //     this.route('delete', {path: '/:id/delete'});
    //   });
    //
    //   this.route('roles', function () {
    //     this.route('delete', {path: '/:id/delete'});
    //   });
    //   this.route('roles.add', {path: '/roles/add'});
    //   this.route('roles.edit', {path: '/roles/:id/edit'});
    //
    //   this.route('users', function () {
    //     this.route('add');
    //     this.route('edit', {path: '/:id/edit'});
    //     this.route('delete', {path: '/:id/delete'});
    //   });
    //
    //   this.route('news-groups', function () {
    //     this.route('add');
    //     this.route('delete', {path: '/:id/delete'});
    //     this.route('edit', {path: '/:id/edit'});
    //   });
    //
    //   this.route('news', function () {
    //     this.route('delete', {path: '/:id/delete'});
    //   });
    //   this.route('news.add', {path: '/news/add'});
    //   this.route('news.edit', {path: '/news/:id/edit'});
    //
    //   this.route('products', function () {
    //     this.route('delete', {path: '/:id/delete'});
    //   });
    //
    //   this.route('products.add', {path: '/products/add'});
    //   this.route('products.edit', {path: '/products/:id/edit'});
    //
    //   this.route('comments', function () {
    //     this.route('edit', {path: '/:id/edit'});
    //     this.route('delete', {path: '/:id/delete'});
    //   });
    //
    //   this.route('my-orders');
    //   this.route('orders', function () {
    //     this.route('complete', {path: '/complete/:id/'});
    //     this.route('new-status', {path: '/:id/new-status'});
    //   });
    //
    //   this.route('make-order', function () {
    //     this.route('print', {path: '/:id/print'});
    //   });
    //
    //   this.route('options', function () {
    //     this.route('add', {path: '/add'});
    //     this.route('edit', {path: '/edit/:id'});
    //     this.route('delete', {path: '/delete/:id'});
    //   });
    //
    //   this.route('stores', function () {
    //     this.route('delete', {path: '/:store_id/delete'});
    //   });
    //   this.route('stores.add', {path: '/stores/add'});
    //   this.route('stores.edit', {path: '/stores/:store_id/edit'});
    //
    //   this.route('stock-takes', function () {
    //     this.route('delete', {path: '/:stock_take_id/delete'});
    //   });
    //   this.route('stock-takes.copy', {path: '/stock-takes/copy/:stock_take_id'});
    //   this.route('stock-takes.add', {path: '/stock-takes/add'});
    //   this.route('stock-takes.edit', {path: '/stock-takes/:stock_take_id'});
    //
    //   this.route('inventory');
    //
    //   this.route('cash-flows', function () {
    //     this.route('disable', {path: '/:cash_flow_id/disable'});
    //   });
    //   this.route('cash-flows.add', {path: '/cash-flows/add'});
    //   this.route('cash-flows.edit', {path: '/cash-flows/:cash_flow_id'});
    //
    //   this.route('purchase-orders', function () {
    //     this.route('delete', {path: '/:purchase_order_id/delete'});
    //   });
    //   this.route('purchase-orders.add', {path: '/purchase-orders/add'});
    //   this.route('purchase-orders.edit', {path: '/purchase-orders/:purchase_order_id/edit'});
    //
    //   this.route('product-receipts', {path: '/products/:product_id/receipts'}, function () {
    //     this.route('add', {path: '/add/:variant'});
    //     this.route('delete', {path: '/:product_receipt_id/delete'});
    //     this.route('edit', {path: '/:product_receipt_id/edit'});
    //   });
    //   // this.route('product-receipts.add', {path: '/product-receipts/:product_id/receipts/add'});
    //   this.route('ingredients', function () {
    //     this.route('delete', {path: '/:id/delete'});
    //   });
    //   this.route('ingredients.add', {path: '/ingredients/add'});
    //   this.route('ingredients.edit', {path: '/ingredients/:id/edit'});
    //   this.route('shifts', function () {
    //     this.route('add', {path: '/add'});
    //     this.route('edit', {path: '/edit/:id'});
    //     this.route('delete', {path: '/delete/:id'});
    //   });
    //   this.route('employee-shifts');
    //   this.route('product-combos', {path: '/product-combos/:product_id'}, function () {
    //     this.route('delete', {path: '/delete/:id'});
    //     this.route('add');
    //     this.route('edit');
    //   });
    //   this.route('salaries', function () {
    //     this.route('add');
    //     this.route('delete', {path: '/delete/:id'});
    //   });
    //   this.route('activities', function () {
    //     this.route('add-time-sheet', {path: 'add-time-sheet/activity/:activity_id'});
    //     this.route('add-over-time', {path: 'add-over-time/shift/:shift_id/:employee_id/:day'});
    //   });
    // });
  });
  var _default = Router;
  _exports.default = _default;
});