define("coffee-client/pod/components/e-input/select/component", ["exports", "coffee-client/mixins/validated-input"], function (_exports, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    options: null,
    onchange: 'onchange',

    init() {
      this._super(...arguments);
    },

    actions: {
      onchange(selected) {
        this.set('value', selected);
      }

    }
  });

  _exports.default = _default;
});