define("coffee-client/helpers/convert-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //@todo user access
  var _default = Ember.Helper.extend({
    compute(params) {
      let variant = params[0];

      if (variant === 1) {
        return "S";
      }

      if (variant === 2) {
        return "M";
      }

      if (variant === 3) {
        return "L";
      }

      if (variant === 4) {
        return "XL";
      }
    }

  });

  _exports.default = _default;
});