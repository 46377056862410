define("coffee-client/pod/components/ecom-front/order-info/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    shippingName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 255
      })]
    },
    shippingPhone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    shippingAddress: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 250
      })]
    },
    shippingCity: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    billingName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 255
      })]
    },
    billingPhone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    billingAddress: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 250
      })]
    },
    billingCity: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    cart: Ember.inject.service(),
    store: Ember.inject.service(),
    identity: Ember.inject.service(),
    messenger: Ember.inject.service(),
    makeOrder: Ember.inject.service('make-order'),
    i18n: Ember.inject.service(),
    orderData: [],
    notificationData: [],
    user: null,
    finishOrder: false,
    footer: true,
    title: true,
    finalPrice: Ember.computed.alias('cart.finalPrice'),
    totalItems: Ember.computed.alias('cart.totalItems'),
    cartItems: Ember.computed.alias('cart.cartItems'),
    finishOrderAction: 'finishOrder',
    linkedOrder: null,
    cartData: Ember.computed.alias('cart.cartData'),
    total: Ember.computed('finalPrice', 'shippingPrice', function () {
      if (this.shippingPrice) {
        return this.finalPrice + this.shippingPrice;
      } else {
        return this.finalPrice;
      }
    }),

    init() {
      this._super(...arguments);

      this.cart.initial();
      this.cart.getCartData();
      this.set('orderData', []);
      this.set('notificationData', []);
    },

    saveOrderInSequence(counter) {
      let self = this;

      if (counter < this.orderData.length) {
        let order = this.orderData[counter];
        order.save().then(function () {
          //linked order
          if (counter === 0) {
            self.set('linkedOrder', order);
          } //last one


          if (counter === self.get('orderData').length - 1) {
            self.sendAction('finishOrderAction', self.get('linkedOrder'));
            self.set('linkedOrder', null);
            self.set('processing', false);
          } else {
            counter++;
            let nextOrder = self.get('orderData')[counter];
            nextOrder.set('linkedOrder', self.get('linkedOrder'));
            self.saveOrderInSequence(counter);
          }
        }).catch(function (reason) {
          console.error(reason);
          self.get('messenger').danger(self.get('i18n').t('Create orders is fail.Please try again'));
        });
      }
    },

    actions: {
      checkValidLink() {
        let self = this;
        let model = this.model;
        self.set('didValidate', true);
        model.validate().then(_ref => {
          let {
            validations
          } = _ref;

          if (validations.get('isValid')) {
            this.router.transitionTo('review-order');
          } else {
            self.get('messenger').danger('Invalid inputs. Please check again');
            window.scrollTo(0, 0);
          }
        });
      },

      saveOrder() {
        this.set('processing', true);
        let model = this.model; // let cartItems = this.get('cartItems');

        let self = this;
        self.get('cartData').forEach(function (item) {
          let order = self.get('store').createRecord('order');
          order.set('shippingName', model.get('shippingName'));
          order.set('billingName', model.get('billingName'));
          order.set('shippingAddress', model.get('shippingAddress'));
          order.set('billingAddress', model.get('billingAddress'));
          order.set('shippingPhone', model.get('shippingPhone'));
          order.set('billingPhone', model.get('billingPhone'));
          order.set('shippingCity', model.get('shippingCity'));
          order.set('billingCity', model.get('billingCity'));
          order.set('seller', item.get('seller'));
          order.set('paymentMethod', model.get('paymentMethod'));
          order.set('shippingMethod', model.get('shippingMethod'));
          item.get('cartItems').forEach(function (cartItem) {
            let orderDetail = self.get('store').createRecord('order-detail');
            orderDetail.set('quantity', cartItem.get('quantity'));
            orderDetail.set('price', cartItem.get('price'));
            orderDetail.set('productVariant', cartItem.get('productVariant'));
            orderDetail.set('order', model);
            orderDetail.set('product', cartItem.get('product'));
            order.get('orderDetails').pushObject(orderDetail);
          });
          self.get('orderData').pushObject(order);
        });
        self.saveOrderInSequence(0);
      }

    }
  });

  _exports.default = _default;
});