define("coffee-client/pod/components/e-input/input-quantity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0XqWu0qG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"input-group \",[21,\"className\"]]]],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"input-group-addon\"],[9],[0,\"\\n    \"],[7,\"button\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-minus\"],[9],[10],[3,\"action\",[[22,0,[]],\"minusQuantity\",[23,[\"value\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"isPrice\"]]],null,{\"statements\":[[0,\"    \"],[1,[27,\"number-input\",null,[[\"unmaskedValue\",\"group\",\"groupSize\",\"decimal\",\"class\",\"focus-in\"],[[23,[\"value\"]],true,3,false,\"form-control no-spinners scanInput\",\"focusInput\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"input\",null,[[\"value\",\"type\",\"class\",\"focus-in\"],[[23,[\"value\"]],\"number\",\"form-control no-spinners scanInput\",\"focusInput\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[7,\"span\"],[11,\"class\",\"input-group-addon\"],[9],[0,\"\\n    \"],[7,\"button\"],[9],[7,\"i\"],[11,\"class\",\"fa fa-plus\"],[9],[10],[3,\"action\",[[22,0,[]],\"plusQuantity\",[23,[\"value\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-input/input-quantity/template.hbs"
    }
  });

  _exports.default = _default;
});