define("coffee-client/pod/home/recruitment/application-recruitment/route", ["exports", "coffee-client/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    getOption: Ember.inject.service('get-option'),
    router: Ember.inject.service(),

    setHeadTags(model) {
      let domain = _environment.default.APP.domain;
      let currentUrl = domain + this.router.urlFor('home.recruitment');
      let index = currentUrl.indexOf("?");

      if (index > 0) {
        currentUrl = currentUrl.substring(0, index);
      } // this.set('headTags', []);
      // let headTags = [
      //   {
      //     type: 'meta',
      //     attrs: {
      //       name: 'twitter:card',
      //       content: '@TheMondayCoffee'
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       name: 'twitter:title',
      //       content: '@TheMondayCoffee'
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       name: 'twitter:creator',
      //       content: '@TheMondayCoffee'
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       property: 'og:type',
      //       content: "website"
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       property: 'og:site_name',
      //       content: 'themondaycoffee.com'
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       property: 'og:title',
      //       content: model.news.title
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       property: 'og:image',
      //       content: 'http:' + model.news.image
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       property: 'og:description',
      //       content: model.description
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       property: 'og:url',
      //       content: currentUrl
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       name: 'description',
      //       content: model.description
      //     }
      //   },
      //   {
      //     type: 'meta',
      //     attrs: {
      //       name: 'keywords',
      //       content: model.keywords
      //     }
      //   },
      //   {
      //     type: 'link',
      //     attrs: {
      //       rel: 'canonical',
      //       href: currentUrl
      //     }
      //   }
      // ];
      // this.set('headTags', headTags);

    },

    model(params) {
      let jobs = this.store.query('store-job', {
        theStore: params.id
      });
      let store = this.store.findRecord('store', params.id);
      let description = this.get('getOption').getOption('job-meta-description');
      let keywords = this.get('getOption').getOption('job-meta-keywords');
      let logo = this.get('getOption').getOption('logo');
      let slogan = 'The Monday Coffee | ' + this.get('getOption').getOption('slogan');
      return Ember.RSVP.hash({
        jobs: jobs,
        store: store,
        description: description,
        keywords: keywords,
        logo: logo,
        slogan: slogan
      });
    },

    afterModel(model) {
      model.data = [];
      this.setHeadTags(model);
      return model.jobs.forEach(job => {
        this.store.query('store-job', {
          job: job.get('id')
        }).then(storeJobs => {
          model.data.pushObject({
            job: job,
            storeJobs: storeJobs
          });
        });
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('jobs', model.jobs);
      controller.set('theStore', model.store);
    }

  });

  _exports.default = _default;
});