define("coffee-client/helpers/check-even", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      let check = parseInt(params[0]) % 2;

      if (check === 0) {
        return true;
      } else {
        return false;
      }
    }

  });

  _exports.default = _default;
});