define("coffee-client/pod/components/ecom-front/display-product-variant/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G8ELhBzF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"option\"]],\"Màu sắc\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ecom-front/display-product-variant/color\",null,[[\"color\"],[[23,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[21,\"value\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/display-product-variant/template.hbs"
    }
  });

  _exports.default = _default;
});