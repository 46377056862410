define("coffee-client/pod/components/e-input/currency/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['e-input/input'],
    classNameBindings: ['showErrorClass:has-error', 'isValid:has-success'],
    model: null,
    value: null,
    type: 'text',
    valuePath: '',
    placeholder: '',
    validation: null,
    isTyping: false,

    init() {
      this._super(...arguments);

      let valuePath = this.valuePath;
      Ember.defineProperty(this, 'validation', Ember.computed.oneWay(`model.validations.attrs.${valuePath}`));
      Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
    },

    notValidating: Ember.computed.not('validation.isValidating'),
    didValidate: Ember.computed.oneWay('targetObject.didValidate'),
    hasContent: Ember.computed.notEmpty('value'),
    isValid: Ember.computed.and('hasContent', 'validation.isValid', 'notValidating'),
    isInvalid: Ember.computed.oneWay('validation.isInvalid'),
    showErrorClass: Ember.computed.and('notValidating', 'showMessage', 'hasContent', 'validation'),
    showMessage: Ember.computed('validation.isDirty', 'isInvalid', 'didValidate', function () {
      return (this.get('validation.isDirty') || this.didValidate) && this.isInvalid;
    })
  });

  _exports.default = _default;
});