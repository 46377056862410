define("coffee-client/services/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    cartItems: [],
    //contains simple data for session
    cartData: [],
    //contains fetched objects
    loadSession: false,
    reloading: false,
    finalPrice: 0,
    totalItems: 0,
    serial: null,
    isAllowPayment: true,

    initial(force) {
      if (this.loadSession === false || force === true) {
        let data = this.get('session.data.cart');

        if (data === undefined) {
          this.set('cartItems', []);
          this.set('serial', null);
        } else {
          this.set('reloading', true);
          this.set('cartItems', []);
          let self = this;
          data.forEach(function (item) {
            self.get('cartItems').addObject(Ember.Object.create({
              id: item.id,
              quantity: item.quantity,
              variantId: item.variantId,
              price: item.price,
              name: item.name,
              image: item.image,
              totalPrice: item.quantity * item.price
            }));
          }); //this.generateSerial();

          this.set('reloading', false);
        }

        this.set('loadSession', true); //console.debug('cartItems initialized', this.get('cartItems'));
      }
    },

    products: Ember.computed('totalItems', function () {
      this.initial();
      let self = this;
      let ids = this.cartItems.map(function (item) {
        return item.id;
      }); //check in store, if have all required products, do not send query

      let results = [];

      if (ids.length) {
        let notEnough = false;
        ids.forEach(function (id) {
          let temp = self.get('store').peekRecord('product', id);

          if (!temp) {
            notEnough = true;
          } else {
            results.pushObject(temp);
          }
        });

        if (notEnough) {
          return this.store.query('product', {
            ids: ids.join(',')
          });
        }
      }

      return new Ember.RSVP.Promise(function (resolve) {
        resolve(results);
      });
    }),
    cartItemsQuantityChanged: Ember.observer('cartItems.@each.quantity', function () {
      let self = this;
      this.set('isAllowPayment', true);
      let finalPrice = 0;
      let totalItems = 0;
      this.cartItems.forEach(function (item) {
        if (!Ember.isPresent(item.get('quantity')) || parseInt(item.get('quantity')) < 1 || isNaN(item.get('quantity'))) {
          item.set('quantity', 1);
        } //Quoc sua


        if (!Ember.isPresent(item.get('quantity')) || parseInt(item.get('quantity')) > 999) {
          item.set('quantity', 999);
        }

        item.set('totalPrice', item.get('price') * item.get('quantity'));
        finalPrice = parseInt(finalPrice) + parseInt(item.get('totalPrice'));
        totalItems = parseInt(totalItems) + parseInt(item.get('quantity'));

        if (item.variantId) {
          //Kiểm tra tồn kho của variant
          self.get('cartData').forEach(function (cartDataItem) {
            let find = cartDataItem.cartItems.findBy('productVariant.id', item.variantId);

            if (find) {
              console.log('find', find.get('productVariant'));

              if (item.quantity > find.get('productVariant.inventory')) {
                //Tính lại nếu số lượng vượt quá tồn kho
                finalPrice = parseInt(finalPrice) - parseInt(item.get('totalPrice'));
                totalItems = parseInt(totalItems) - parseInt(item.get('quantity'));
                self.set('isAllowPayment', false);
              }
            }
          });
        } else {
          //Kiểm tra tồn kho (inStock) của sản phẩm
          self.get('cartData').forEach(function (cartDataItem) {
            let productFound = cartDataItem.cartItems.findBy('product.id', item.id);

            if (productFound) {
              if (item.quantity > productFound.get('product.inStock')) {
                finalPrice = parseInt(finalPrice) - parseInt(item.get('totalPrice'));
                totalItems = parseInt(totalItems) - parseInt(item.get('quantity'));
                self.set('isAllowPayment', false);
              }
            }
          });
        }
      });
      this.set('finalPrice', parseInt(finalPrice));
      this.set('totalItems', parseInt(totalItems));

      if (!this.reloading) {
        this.save(); //save
      }
    }),

    //  generateSerial(){
    //    let serial = null;
    //    let data = this.get('session.data.cart');
    //    if (data !== undefined) {
    //      data.forEach(function (item) {
    //        serial += '_' + item.id + item.quantity;
    //      });
    //      this.set('serial', serial);
    //    }
    //  },
    add(item) {
      this.initial();
      let find = this.findBy(item.id, item.variantId);

      if (!find) {
        this.cartItems.addObject(Ember.Object.create(Ember.Object.create({
          id: item.id,
          variantId: item.variantId,
          quantity: parseInt(item.quantity),
          image: item.image,
          price: parseInt(item.price),
          name: item.name,
          totalPrice: parseInt(item.quantity * item.price)
        })));
      } else {
        find.set('quantity', parseInt(find.get('quantity')) + parseInt(item.quantity));
      }
    },

    remove(productId, variantId) {
      this.initial();
      let self = this;
      self.get('cartItems').forEach(function (item) {
        if (item.get('id') === productId && item.get('variantId') === variantId) {
          self.get('cartItems').removeObject(item);
        }
      });
    },

    empty() {
      this.initial();
      this.cartItems.setObjects([]);
    },

    findBy(productId, variantId) {
      this.initial();
      let find = null;
      this.cartItems.forEach(function (item) {
        if (item.get('id') === productId && item.get('variantId') === variantId) {
          find = item;
        }
      });
      return find;
    },

    save() {
      this.session.set('data.cart', this.cartItems);
    },

    getCartData() {
      this.initial(true);
      let self = this;
      let cartData = [];
      self.set('cartData', cartData);

      if (parseInt(self.get('totalItems')) > 0) {
        return self.get('products').then(function () {
          self.get('store').unloadAll('cart-item');
          self.get('cartItems').forEach(function (item) {
            let product = self.get('store').peekRecord('product', item.id);
            let productVariant;

            if (item.variantId) {
              productVariant = self.get('store').peekRecord('product-variant', item.variantId);
            } // let productVariant = self.get('store').peekRecord('product-variant', item.variantId);


            let seller = product.get('seller');
            let cartItem = self.get('store').createRecord('cart-item', {
              product: product,
              productVariant: productVariant,
              quantity: item.quantity
            });
            let find = cartData.findBy('sellerId', seller.get('id'));

            if (find === undefined) {
              let temp = Ember.Object.create({
                sellerId: seller.get('id'),
                seller: seller,
                cartItems: []
              });
              temp.cartItems.pushObject(cartItem);
              cartData.pushObject(temp);
            } else {
              find.cartItems.pushObject(cartItem);
            }
          }); //console.debug('cartData', cartData);

          self.set('cartData', cartData);
          console.log('cartData', cartData);
        });
      }
    },

    removeCartItemObject(cartItem) {
      let productVariantId = cartItem.get('productVariant.id') ? cartItem.get('productVariant.id') : null;
      this.remove(cartItem.get('product.id'), productVariantId);
      cartItem.unloadRecord();
    }

  });

  _exports.default = _default;
});