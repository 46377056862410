define("coffee-client/pod/components/ecom-front/view-product-property/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    product: null,
    propertiesTerm: [],
    productProperties: [],

    init() {
      this._super(...arguments);

      let self = this;
      this.set('productProperties', []);
      this.set('propertiesTerm', []);
      this.get('product.productPropertyTermDetails').forEach(function (termDetail) {
        self.get('propertiesTerm').pushObject(termDetail.get('productPropertyTerm'));
      });
      let productProperties = this.store.findAll('product-property').then(function (productProperties) {
        let propertyList = [];
        propertyList.clear();
        productProperties.forEach(function (productProperty) {
          let listTerm = [];
          productProperty.get('productPropertyTerms').forEach(function (termStore) {
            self.get('propertiesTerm').forEach(function (termComponent) {
              if (termStore.get('id') === termComponent.get('id')) {
                listTerm.pushObject(termComponent);
              }
            });
          });

          if (listTerm.get('length') > 0) {
            propertyList.pushObject({
              property: productProperty,
              terms: listTerm
            });
          }
        });
        console.debug('productProperty in', propertyList);
        return propertyList;
      }); //add property into productProperties

      productProperties.then(function (properties) {
        properties.forEach(function (property) {
          self.get('productProperties').pushObject(property);
        });
      });
    }

  });

  _exports.default = _default;
});