define("coffee-client/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "coffee-client/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: _environment.default.api.host + '/auth',

    makeRequest(url, data) {
      let jsonData = JSON.stringify({
        username: data.username,
        password: data.password
      });
      const options = {
        url,
        data: jsonData,
        type: 'POST',
        contentType: 'application/json;charset=utf-8',
        dataType: 'json'
      };
      return Ember.$.ajax(options);
    }

  });

  _exports.default = _default;
});