define("coffee-client/pod/components/ecom-front/in-stock-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vrGlOMf+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\"],[11,\"class\",\"form-horizontal\"],[9],[0,\"\\n  \"],[7,\"p\"],[11,\"class\",\"text-danger\"],[9],[1,[27,\"t\",[\"_in stock note\"],null],false],[10],[0,\"\\n\"],[4,\"e-form/row\",null,[[\"label\",\"labelCol\",\"inputCol\"],[\"Inventory\",2,10]],{\"statements\":[[0,\"    \"],[1,[27,\"number-input\",null,[[\"unmaskedValue\",\"class\"],[[23,[\"inStock\"]],\"form-control\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"text-right\"],[9],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Ok\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"save\"]],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"class\",\"btn btn-primary\"],[9],[0,\"\\n    \"],[1,[27,\"t\",[\"Cancel\"],null],false],[0,\"\\n  \"],[3,\"action\",[[22,0,[]],\"cancel\"]],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/in-stock-modal/template.hbs"
    }
  });

  _exports.default = _default;
});