define("coffee-client/pod/components/ecom-front/store-job/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    job: null,
    store: Ember.inject.service(),
    storeJobs: null,
    actionName: "addCart",
    tagName: "",

    init() {
      this._super(...arguments);

      this.store.query('store-job', {
        job: this.get('job.id')
      }).then(storeJobs => {
        this.set('storeJobs', storeJobs);
      });
    },

    actions: {
      addCart(product, variant) {
        this.sendAction('actionName', product, variant);
      }

    }
  });

  _exports.default = _default;
});