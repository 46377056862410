define("coffee-client/models/customer-register", ["exports", "ember-data", "ember-data/model", "ember-cp-validations", "moment"], function (_exports, _emberData, _model, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    fullName: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    dob: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/,
        messageKey: 'messCustomerRegisterFalse'
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    fullName: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    confirmed: _emberData.default.attr('number'),
    dob: _emberData.default.attr('string') // birthday: computed('dob', function () {
    //   let date = moment(this.get('dob'));
    //   return '1111-11-11' + date.format('DD-MM-YYYY');
    // })

  });

  _exports.default = _default;
});