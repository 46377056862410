define("coffee-client/pod/home/order-done/route", ["exports", "coffee-client/mixins/route/scroll-content"], function (_exports, _scrollContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollContent.default, {});

  _exports.default = _default;
});