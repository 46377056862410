define("coffee-client/pod/components/ecom-light-table-cell/inventory-product-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DBDphgQ/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"admin.inventory\",[27,\"query-params\",null,[[\"product\"],[[23,[\"value\",\"id\"]]]]]],null,{\"statements\":[[0,\" \"],[1,[23,[\"value\",\"name\"]],false],[0,\" \"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/inventory-product-link/template.hbs"
    }
  });

  _exports.default = _default;
});