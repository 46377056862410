define("coffee-client/pod/order-customer/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    messenger: Ember.inject.service(),
    session: Ember.inject.service(),
    identity: Ember.inject.service(),
    store: Ember.inject.service(),
    makeOrder: Ember.inject.service('make-order'),
    orderDetailList: null,
    orderDetailListChange: Ember.observer('session.data.currentOrder@each', function () {
      Ember.run.later(() => {
        this.set('orderDetailList', this.session.get('data.currentOrder'));
      }, 100);
    }),
    orderInfo: Ember.computed.alias('session.data.currentOrderInfo')
  });

  _exports.default = _default;
});