define("coffee-client/pod/components/e-yes-no-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    open: false,
    actions: {
      onHide: function () {
        this.set('open', false);
      }
    }
  });

  _exports.default = _default;
});