define("coffee-client/services/make-order", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    order: null,
    cartItems: [],
    orderInfo: null,

    newOrder() {
      let store = this.store;
      let order = store.createRecord('order', {});
      this.set('order', order);
      return order;
    },

    clearOrder() {
      this.set('order', null);
    },

    initial(force) {
      if (this.loadSession === false || force === true) {
        let data = this.get('session.data.cartcurrentOrder');

        if (data === undefined) {
          this.set('cartItems', []);
          this.set('serial', null);
        } else {
          this.set('reloading', true);
          this.set('cartItems', []);
          let self = this;
          data.forEach(function (item) {
            self.get('cartItems').addObject(Ember.Object.create({
              id: item.id,
              quantity: item.quantity,
              variant: item.variant,
              price: item.price,
              name: item.name,
              image: item.image,
              totalPrice: item.quantity * item.price
            }));
          }); //this.generateSerial();

          this.set('reloading', false);
        }

        this.set('loadSession', true); //console.debug('cartItems initialized', this.get('cartItems'));
      }
    },

    saveInfo() {
      this.session.set('data.currentOrderInfo', this.orderInfo);
    },

    save() {
      this.session.set('data.currentOrder', this.cartItems);
      console.log('cart session', this.get('session.data.currentOrder'));
    },

    removeCartItemObject(cartItem) {
      let productvariant = cartItem.get('productVariant.id') ? cartItem.get('productVariant.id') : null;
      this.remove(cartItem.get('product.id'), productvariant);
      cartItem.unloadRecord();
    },

    emptyData() {
      this.empty();
      this.session.set('data.currentOrderInfo', null);
      this.session.set('data.currentOrder', null);
    },

    empty() {
      // this.initial();
      this.cartItems.setObjects([]);
    },

    add(item) {
      // this.initial();
      // let find = this.findBy(item.id, item.variant);
      // if (!find) {
      //   this.get('cartItems').pushObject(item);
      // }
      // else {
      //   find.quantity = parseInt(item.quantity);
      //   find.totalPrice = parseInt(find.quantity) * parseInt(find.price);
      // }
      this.cartItems.pushObject(item);
    },

    addInfo(item) {
      this.set('orderInfo', item);
      this.saveInfo();
    },

    findBy(productId, variant) {
      this.initial();
      let find = null;
      this.cartItems.forEach(function (item) {
        if (item.id === productId && item.variant === variant) {
          find = item;
        }
      });
      return find;
    }

  });

  _exports.default = _default;
});