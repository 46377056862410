define("coffee-client/pod/components/ecom-front/normal-product/component", ["exports", "coffee-client/helpers/format-image"], function (_exports, _formatImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    image: null,
    product: null,
    imageSwap: false,
    image1: false,
    image2: false,
    openCartAction: null,

    init() {
      this._super(...arguments);

      let component = this;
      component.set('image1', (0, _formatImage.formatImage)(null, {
        url: component.get('product.image1'),
        size: "m"
      }));
      component.set('image2', (0, _formatImage.formatImage)(null, {
        url: component.get('product.image2'),
        size: "m"
      }));
      setInterval(function () {
        if (!component.isDestroyed && !component.isDestroying) {
          if (component.get('imageSwap') === true) {
            if (component.get('image') === component.get('image1')) {
              component.set('image', component.get('image2'));
            } else {
              component.set('image', component.get('image1'));
            }
          }
        }
      }, 2000);
    },

    mouseEnter() {
      this.set('imageSwap', true);
    },

    mouseLeave() {
      this.set('imageSwap', false);
    }

  });

  _exports.default = _default;
});