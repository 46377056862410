define("coffee-client/pod/components/ecom-front/zoom-image-desktop/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // Hình ảnh phải là hình vuông
    imageUrl: 'http://ultraimg.com/images/bNeWGWB.jpg',
    classImage: null,
    lenActive: false,
    classReviewCustom: null,
    crosshair: false,
    //Hiển thị 2 mũi tên điều hướng ở trung tâm hình
    imageProportion: 2.5,
    //Tỉ lệ hình thực tế (natural) với hình ảnh hiển thị (không zoom);
    zoomImageSize: 400,

    //kích thước khung hiển thị hình sau khi zoom
    init() {
      this._super(...arguments);
    },

    mouseX: null,
    mouseY: null,
    zoomX: 0,
    zoomY: 0,

    mouseMove(e) {
      // Sự kiện bắt tọa độ của chuột
      //    let naturalWidth = Ember.$('.image-for-zoom').naturalWidth;
      //    console.debug('naturalWidth', naturalWidth);
      let offset = Ember.$(e.currentTarget).offset();
      let top = this.$(document).scrollTop(); //    console.debug('x', parseInt(e.clientX) - parseInt(offset.left));
      //    console.debug('y', parseInt(e.clientY) - parseInt(offset.top) + parseInt(top));
      //    console.debug('offset left', parseInt(offset.left));
      //    console.debug('offset top', parseInt(offset.top));

      let positionX = parseInt(e.clientX) - parseInt(offset.left);
      let positionY = parseInt(e.clientY) - parseInt(offset.top) + parseInt(top);
      this.set('mouseX', positionX);
      this.set('mouseY', positionY);
    },

    zoomImage: Ember.observer('mouseX', 'mouseY', function () {
      // xét tọa độ cho hình zoom
      if (parseInt(this.mouseX) >= 80 && parseInt(this.mouseX) <= 320) {
        this.set('zoomX', parseInt(this.mouseX));
      } else {
        if (parseInt(this.mouseX) < 80) {
          this.set('zoomX', 200 / parseFloat(this.imageProportion)); //(hình zoom/2 * 2.5) /tỉ lệ hình
        } else {
          this.set('zoomX', 800 / parseFloat(this.imageProportion));
        }
      }

      if (parseInt(this.mouseY) >= 80 && parseInt(this.mouseY) <= 320) {
        this.set('zoomY', parseInt(this.mouseY));
      } else {
        if (parseInt(this.mouseY) < 80) {
          this.set('zoomY', 200 / parseFloat(this.imageProportion));
        } else {
          this.set('zoom', 800 / parseFloat(this.imageProportion)); //(kich thức thật của hình - len zoom)/2
        }
      } //    console.debug('x A', this.get('zoomX'));
      //    console.debug('y A', this.get('zoomY'));


      var posX = -(parseInt(this.zoomX) * parseFloat(this.imageProportion) - 200);
      var posY = -(parseInt(this.zoomY) * parseFloat(this.imageProportion) - 200); //postX = (mouseX *(kích thước thực tế/kích thước hình hiển thị))-kích thước hình (review,zoom)/2

      this.$('.review-image').css('background-position-x', posX);
      this.$('.review-image').css('background-position-y', posY); //Xét tọa độ cho lens

      let lenX = parseInt(this.mouseX) - 80;
      let lenY = parseInt(this.mouseY) - 80; // Len X

      if (parseInt(this.mouseX) >= 80 && parseInt(this.mouseX) <= 320) {
        this.$('.zoomLens').css('left', lenX);
      } else {
        if (parseInt(this.mouseX) < 80) {
          this.$('.zoomLens').css('left', 0);
        } else {
          this.$('.zoomLens').css('left', 240);
        }
      } // Len Y


      if (parseInt(this.mouseY) >= 80 && parseInt(this.mouseY) <= 320) {
        this.$('.zoomLens').css('top', lenY);
      } else {
        if (parseInt(this.mouseY) < 80) {
          this.$('.zoomLens').css('top', 0);
        } else {
          this.$('.zoomLens').css('top', 240);
        }
      }
    }),
    actions: {
      mouseOut() {
        this.set('lenActive', false);
      },

      mouseIn() {
        this.set('lenActive', true);
      },

      zoomIn() {
        this.set('classReview', 'active');
      },

      zoomOut() {
        this.set('classReview', null);
      }

    }
  });

  _exports.default = _default;
});