define("coffee-client/pod/components/e-user/forgot-password-form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    password: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 20
      })]
    },
    confirmNewPassword: {
      validators: [(0, _emberCpValidations.validator)('confirmation', {
        on: 'password',
        messageKey: 'Password do not match'
      })]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    ajax: Ember.inject.service(),
    messenger: Ember.inject.service(),
    didValidate: false,
    completed: false,
    processing: false,
    token: null,
    // required fields
    url: '/validate-forgot-password',
    actions: {
      forgotPassword() {
        let self = this;
        this.validate().then(_ref => {
          let {
            validations
          } = _ref;
          self.set('didValidate', true);

          if (validations.get('isValid')) {
            self.set('processing', true);
            self.get('ajax').request(self.get('url'), {
              method: 'POST',
              data: JSON.stringify({
                "data": {
                  "attributes": {
                    "newPassword": self.get('password'),
                    "token": self.get('token')
                  }
                }
              })
            }).then(function (data) {
              self.set('completed', true);
              self.set('processing', false);
            }).catch(function (error) {
              self.get('messenger').danger(error);
              self.set('processing', false);
            });
          } else {
            self.get('messenger').danger('Invalid inputs. Please check again');
          }
        });
      }

    }
  });

  _exports.default = _default;
});