define("coffee-client/helpers/format-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatImage = formatImage;

  function formatImage(params, namedArgs) {
    let path = null;

    if (Ember.isPresent(namedArgs.url) && Ember.isPresent(namedArgs.size)) {
      let fileName = namedArgs.url.split('/').reverse()[0];
      path = namedArgs.url.replace(fileName, namedArgs.size + '_' + fileName);
    } else {
      path = namedArgs.url;
    }

    return path;
  }

  var _default = Ember.Helper.helper(formatImage);

  _exports.default = _default;
});