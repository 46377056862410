define("coffee-client/pod/components/ecom-front/menu-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actionName: "addCart",
    actions: {
      addItem(product, variant) {
        this.sendAction('actionName', product, variant);
      }

    }
  });

  _exports.default = _default;
});