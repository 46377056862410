define("coffee-client/pod/components/e-collapse-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    open: true,
    titleList: null
  });

  _exports.default = _default;
});