define("coffee-client/pod/components/ecom-front/form-add-variant-option/component", ["exports", "coffee-client/mixins/form/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_create.default, {
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),
    createVariantOption: 'createVariantOption',
    variantOptionName: 'Màu sắc',

    init() {
      this._super(...arguments);
    },

    actions: {
      addVariantOption() {
        let variantOptionName = this.variantOptionName;

        if (variantOptionName === "") {
          if (this.variantOptionInput) {
            variantOptionName = this.variantOptionInput;
          } else {
            this.messenger.danger('Tên biến thể không được để trống');
            return false;
          }
        }

        let variantOption = this.store.createRecord('product-variant-option', {
          name: variantOptionName
        });
        this.set('variantOptionInput', '');
        this.sendAction('createVariantOption', variantOption);
      }

    }
  });

  _exports.default = _default;
});