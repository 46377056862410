define("coffee-client/models/product", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 2,
        max: 255
      })]
    },
    description: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 400
      })]
    },
    detail: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 100000
      })]
    },
    price1: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    image1: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'url'
      })]
    },
    warningQuantity: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    },
    ingredientQuantity: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    code: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    detail: _emberData.default.attr('string'),
    productGroup: _emberData.default.belongsTo('product-group'),
    // unit: DS.attr('string'),
    // type: DS.attr('number'),
    displayPrice1: _emberData.default.attr('string'),
    displayPrice2: _emberData.default.attr('string'),
    displayPrice3: _emberData.default.attr('string'),
    price1: _emberData.default.attr('number'),
    price2: _emberData.default.attr('number'),
    price3: _emberData.default.attr('number'),
    price4: _emberData.default.attr('number'),
    salePrice1: _emberData.default.attr('number'),
    salePrice2: _emberData.default.attr('number'),
    salePrice3: _emberData.default.attr('number'),
    salePrice4: _emberData.default.attr('number'),
    published: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    image1: _emberData.default.attr('string'),
    image2: _emberData.default.attr('string'),
    image3: _emberData.default.attr('string'),
    image4: _emberData.default.attr('string'),
    image5: _emberData.default.attr('string'),
    image6: _emberData.default.attr('string'),
    image7: _emberData.default.attr('string'),
    specific: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    type: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    warningQuantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    ingredientQuantity: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    unit: _emberData.default.attr('string'),
    isSale: Ember.computed('salePrice1', 'salePrice2', 'salePrice3', 'salePrice4', function () {
      if (this.salePrice1 > 0 || this.salePrice2 > 0 || this.salePrice3 > 0 || this.salePrice4 > 0) {
        return true;
      } else {
        return false;
      }
    }),
    pricePerUnit: Ember.computed('ingredientQuantity', function () {
      if (this.ingredientQuantity > 0) {
        return parseInt(parseInt(this.price1) / parseInt(this.ingredientQuantity));
      } else {
        return 0;
      }
    })
  });

  _exports.default = _default;
});