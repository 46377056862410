define("coffee-client/models/order-detail", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    order: _emberData.default.belongsTo('order', {
      async: true
    }),
    product: _emberData.default.belongsTo('product', {
      async: true
    }),
    quantity: _emberData.default.attr('number'),
    tempPrice: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    salePrice: _emberData.default.attr('number'),
    variant: _emberData.default.attr('number'),
    finalPrice: _emberData.default.attr('number'),
    gifted: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    salePercents: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    finalPriceComputed: Ember.computed('tempPrice', 'quantity', function () {
      return parseInt(this.quantity) * parseInt(this.tempPrice);
    }),
    productName: Ember.computed('product', function () {
      return this.get('product.name');
    })
  });

  _exports.default = _default;
});