define("coffee-client/models/user", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    email: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowNonTld: true
      })]
    },
    displayName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 50
      })]
    },
    password: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 250,
        allowBlank: true
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    // pinCode: {
    //   validators: [
    //     validator('number', {
    //       allowString: true,
    //       integer: true
    //     }),
    //   ]
    // },
    specific: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    },
    address: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 255,
        allowBlank: true
      })]
    },
    city: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 50,
        allowBlank: true
      })]
    },
    bankOffice: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 250
      })]
    },
    bank: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 250
      })]
    },
    bankAccountName: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 250
      })]
    },
    taxNumber: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 250
      })]
    },
    companyFullName: {
      validators: [(0, _emberCpValidations.validator)('length', {
        max: 250
      })]
    },
    theStore: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    displayName: _emberData.default.attr('string'),
    taxNumber: _emberData.default.attr('string'),
    bankAccountNo: _emberData.default.attr('string'),
    bankAccountName: _emberData.default.attr('string'),
    bank: _emberData.default.attr('string'),
    bankOffice: _emberData.default.attr('string'),
    file: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    state: _emberData.default.attr('user-state', {
      defaultValue: 0
    }),
    intro: _emberData.default.attr('string'),
    logo: _emberData.default.attr('string'),
    banner: _emberData.default.attr('string'),
    onTop: _emberData.default.attr('number'),
    pinCode: _emberData.default.attr('number'),
    thirdPartyLogin: _emberData.default.attr(''),
    companyFullName: _emberData.default.attr('string'),
    roles: _emberData.default.hasMany('role', {
      async: true
    }),
    upgrade: _emberData.default.attr('user-upgrade', {
      defaultValue: 0
    }),
    specific: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    guaranteedSeller: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    facebookId: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    googleId: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    numOfRates: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    rating: _emberData.default.attr(),
    specificExpired: _emberData.default.attr('string'),
    name: Ember.computed('displayName', function () {
      return this.displayName;
    }),
    specificSeller: Ember.computed('specific', function () {
      if (parseInt(this.specific) > 0) {
        return true;
      } else {
        return false;
      }
    }),
    isSeller: Ember.computed('roles', function () {
      let flag = false;
      this.roles.forEach(function (role) {
        if (role.get('name') === 'seller') {
          flag = true;
        }
      });
      return flag;
    }),
    theStore: _emberData.default.belongsTo('store')
  });

  _exports.default = _default;
});