define("coffee-client/transforms/published", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      if (serialized === 1) {
        return 'Published';
      }

      if (serialized === 2) {
        return 'Pending';
      }

      return 'None';
    },
    serialize: function (deserialized) {
      if (deserialized === 'Published') {
        return 1;
      }

      if (deserialized === 'Pending') {
        return 2;
      }

      return 0;
    }
  });

  _exports.default = _default;
});