define("coffee-client/pod/components/ecom-front/menu-order/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V++j2r3V",
    "block": "{\"symbols\":[\"product\"],\"statements\":[[4,\"each\",[[23,[\"products\"]]],null,{\"statements\":[[0,\"  \"],[1,[27,\"ecom-front/menu-item\",null,[[\"product\"],[[22,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/menu-order/template.hbs"
    }
  });

  _exports.default = _default;
});