define("coffee-client/pod/components/ecom-front/view-product-property/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1hCmePdZ",
    "block": "{\"symbols\":[\"productProperty\",\"term\"],\"statements\":[[4,\"each\",[[23,[\"productProperties\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"property-view\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"property-view-name\"],[9],[1,[22,1,[\"property\",\"name\"]],false],[0,\" :\"],[10],[4,\"each\",[[22,1,[\"terms\"]]],null,{\"statements\":[[7,\"span\"],[9],[1,[22,2,[\"name\"]],false],[10]],\"parameters\":[2]},null],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/view-product-property/template.hbs"
    }
  });

  _exports.default = _default;
});