define("coffee-client/services/get-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),

    getValue(options, optionKey) {
      let result = false;
      let value = null;
      options.forEach(function (option) {
        if (optionKey === option.get('key')) {
          result = true;
          value = option.get('value');
        }
      });

      if (!result) {
        console.error('No option: ' + optionKey);
      }

      return value;
    },

    getOption(optionKey) {
      let self = this;
      let options = this.store.peekAll('option');

      if (options.get('length') > 0) {
        return this.getValue(options, optionKey);
      } else {
        this.store.findAll('option').then(function (optionPromise) {
          return self.getValue(optionPromise, optionKey);
        });
      }
    }

  });

  _exports.default = _default;
});