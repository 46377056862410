define("coffee-client/models/store", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    address: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    storeGroup: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    hasSelling: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    isWarehouse: _emberData.default.attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});