define("coffee-client/pod/home/news/detail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PoGVoxDE",
    "block": "{\"symbols\":[\"news\"],\"statements\":[[1,[27,\"page-title\",[[23,[\"title\"]]],[[\"_deprecate\"],[null]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"container mt-4\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"news\",\"content\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"row news-detail\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-sm-8\"],[9],[0,\"\\n        \"],[7,\"h2\"],[9],[1,[23,[\"model\",\"news\",\"title\"]],false],[10],[0,\"\\n        \"],[1,[23,[\"model\",\"news\",\"content\"]],true],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"col-sm-4\"],[9],[0,\"\\n        \"],[7,\"h4\"],[11,\"class\",\"mb-2\"],[9],[1,[27,\"t\",[\"Related news\"],null],false],[10],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"listNews\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\"],[9],[0,\"\\n            \"],[7,\"img\"],[11,\"class\",\"w-100 mb-2\"],[12,\"src\",[28,[[22,1,[\"image\"]]]]],[12,\"alt\",[28,[[22,1,[\"title\"]]]]],[9],[10],[0,\"\\n            \"],[4,\"link-to\",[\"home.news.detail\",[27,\"url-name\",[[22,1,[\"title\"]]],null],[22,1,[\"id\"]]],[[\"class\",\"titleBinding\"],[\"text-dark link text-uppercase shadow-none\",[22,1,[\"title\"]]]],{\"statements\":[[7,\"h5\"],[9],[1,[22,1,[\"title\"]],true],[10]],\"parameters\":[]},null],[0,\"\\n          \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/home/news/detail/template.hbs"
    }
  });

  _exports.default = _default;
});