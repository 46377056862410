define("coffee-client/helpers/get-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //@todo user access
  var _default = Ember.Helper.extend({
    getOption: Ember.inject.service('get-option'),

    compute(params) {
      return this.getOption.getOption(params[0]);
    }

  });

  _exports.default = _default;
});