define("coffee-client/pod/components/ecom-front/select-city/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zvo5ihXp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"e-select-city\"],[9],[0,\"\\n  \"],[1,[27,\"e-input/select\",null,[[\"model\",\"didValidate\",\"valuePath\",\"options\"],[[23,[\"model\"]],[23,[\"didValidate\"]],[23,[\"valuePath\"]],[23,[\"cityOptions\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"input-error\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showErrorMessage\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"showValidations\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n        \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"message\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showWarningMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"warning\"],[9],[0,\"\\n      \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"warningMessage\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/select-city/template.hbs"
    }
  });

  _exports.default = _default;
});