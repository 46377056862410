define("coffee-client/pod/components/light-table-cell/thumbnail/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ALu9Qg0f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[12,\"src\",[28,[[27,\"format-image\",null,[[\"url\",\"size\"],[[23,[\"value\"]],null]]]]]],[11,\"height\",\"50\"],[9],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/light-table-cell/thumbnail/template.hbs"
    }
  });

  _exports.default = _default;
});