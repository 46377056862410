define("coffee-client/services/messenger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    //flashmessenger: Ember.inject.service(),
    notify: Ember.inject.service(),
    i18n: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    success(message) {
      this.notify.success(this.i18n.t(message));
    },

    info(message) {
      this.notify.info(this.i18n.t(message));
    },

    warning(message) {
      this.notify.warning(this.i18n.t(message));
    },

    danger(reason) {
      if (reason.errors instanceof Array && reason.errors[0] && reason.errors[0].detail) {
        this.notify.alert(this.i18n.t(reason.errors[0].detail));
      } else {
        this.notify.alert(this.i18n.t(reason));
      }
    },

    //
    //  alertErrors(reason, keepMessages){
    //    if (!keepMessages) {
    //      this.clearMessages();
    //    }
    //    if (typeof reason === 'string' || reason instanceof String) {
    //      this.set('errors', [reason]);
    //    }
    //    else if (reason.errors[0] && reason.errors[0].detail) {
    //      this.set('errors', [reason.errors[0].detail]);
    //    }
    //    return this.get('errors');
    //  },
    //
    //  alertSuccess(message, keepMessages){
    //    if (!keepMessages) {
    //      this.clearMessages();
    //    }
    //    if (typeof message === 'string' || message instanceof String) {
    //      this.set('success', [message]);
    //    }
    //    else {
    //      this.set('success', message);
    //    }
    //    return this.get('success');
    //  },
    clearMessages() {//this.get('flashMessages').clearMessages();
    }

  });

  _exports.default = _default;
});