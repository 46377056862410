define("coffee-client/pod/components/ecom-front/normal-product/quick-view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: null,
    opened: false,
    openModalCart: false,
    openCartAction: null,
    actions: {
      addToCart() {
        this.set('openModalCart', true);
        this.set('opened', false);
      },

      clickOn() {
        this.set('openModalCart', false);
      },

      open() {
        this.set('opened', true);
      },

      close() {
        this.set('opened', false);
      }

    }
  });

  _exports.default = _default;
});