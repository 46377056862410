define("coffee-client/pod/components/ecom-front/aside/left/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    // session: service(),
    // identity: service(),
    // i18n: service(),
    // toggleBar: true,
    // collapsedProduct: true,
    // collapsedOrder: true,
    // collapsedNews: true,
    // collapsedSalary: true,
    // collapsedStatistic: true,
    // isManager: false,
    //hasRole
    init() {
      this._super(...arguments); // this.set('isManager', this.get('identity').hasRole('manager'));


      this.store.query('category', {
        page: 1,
        published: 1,
        limit: 3
      }).then(cats => {
        this.set('cat', cats.get('firstObject')); // console.log('cat',this.get('cat'))
      });
    },

    actions: {// onClick(){
      //   // alert(this.set('click', true));
      //   document.body.classList.add('sidebar-compact');
      //   return true
      // }
      // toggleSidebar: function () {
      //   // this.toggleProperty('toggleBar');
      //   let toggleAside = this.toggleProperty('toggleBar');
      //   if (toggleAside) {
      //     document.body.classList.add('sidebar-compact');
      //   } else {
      //     document.body.classList.remove('sidebar-compact');
      //   }
      // },
      //
      // toggleProduct() {
      //   this.toggleProperty('collapsedProduct')
      // },
      // toggleSalary() {
      //   this.toggleProperty('collapsedSalary')
      // },
      // toggleOrder() {
      //   this.toggleProperty('collapsedOrder')
      // },
      // toggleNews() {
      //   this.toggleProperty('collapsedNews')
      // },
      // toggleStatistic() {
      //   this.toggleProperty('collapsedStatistic')
      // },
    }
  });

  _exports.default = _default;
});