define("coffee-client/pod/components/e-modal/from-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PAV6XO/0",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[7,\"a\"],[12,\"class\",[28,[[21,\"class\"]]]],[9],[0,\"\\n  \"],[1,[21,\"text\"],false],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"open\"]],[10],[0,\"\\n\\n\"],[4,\"bs-modal\",null,[[\"title\",\"open\",\"body\",\"footer\",\"header\",\"size\",\"autoClose\",\"backdropClose\"],[[23,[\"title\"]],[23,[\"opened\"]],false,false,false,[23,[\"size\"]],false,false]],{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('coffee-client/pod/components/e-modal/from-link/template.hbs' @ L7:C5) \"],null]],[[\"class\"],[[27,\"if\",[[23,[\"headerClass\"]],[23,[\"headerClass\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"modal-title\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('coffee-client/pod/components/e-modal/from-link/template.hbs' @ L12:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-modal/from-link/template.hbs"
    }
  });

  _exports.default = _default;
});