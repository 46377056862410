define("coffee-client/pod/components/ecom-front/review-product-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m2eOve12",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"versionId\"]]],null,{\"statements\":[[4,\"link-to\",[\"home.preview-product\",[23,[\"versionId\"]]],[[\"class\"],[\"btn btn-sm btn-save btn-bg\"]],{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"Preview product\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/review-product-link/template.hbs"
    }
  });

  _exports.default = _default;
});