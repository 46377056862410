define("coffee-client/pod/components/ecom-admin/category/select/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //-- required
    selected: null,
    options: [],
    //--
    store: Ember.inject.service(),

    init() {
      this._super(...arguments); //    let selectedId = null;
      //    if (this.get('selected.id')) {
      //      selectedId = this.get('selected.id');
      //    }


      let self = this;
      self.get('store').query('category', {}).then(function (categories) {
        let options = [];
        categories.forEach(function (category) {
          if (!category.get('parent.id')) {
            //if (category.get('id') !== selectedId) {
            let newName = category.get('name');
            options.pushObject({
              name: newName,
              id: category.id
            });
            category.get('children').forEach(function (child1) {
              //if (child1.get('id') !== selectedId) {
              newName = '----' + child1.get('name');
              options.pushObject({
                name: newName,
                id: child1.id
              });
              child1.get('children').forEach(function (child2) {
                //if (child2.get('id') !== selectedId) {
                newName = '--------' + child2.get('name');
                options.pushObject({
                  name: newName,
                  id: child2.id
                });
                child2.get('children').forEach(function (child3) {
                  //if (child2.get('id') !== selectedId) {
                  newName = '--------' + child3.get('name');
                  options.pushObject({
                    name: newName,
                    id: child3.id
                  }); //}
                }); //}
              }); //}
            }); //}
          }
        });
        self.set('options', options);
        self.selectedChanged();
      });
    },

    actions: {
      selectItem(value) {
        this.set('value', value);
      },

      clear() {
        this.set('selected', null);
        this.set('value', null);
      }

    },
    selectedChanged: Ember.observer('selected', function () {
      let find = null;
      let self = this;
      this.options.forEach(function (item) {
        if (item.id === self.get('selected.id')) {
          find = item;
        }
      });

      if (find) {
        this.set('value', find);
      }
    }),
    valueChanged: Ember.observer('value', function () {
      this.set('selected', this.store.peekRecord('category', this.get('value.id')));
    })
  });

  _exports.default = _default;
});