define("coffee-client/pod/components/ecom-front/news/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CKlOA2R1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"check-even\",[[23,[\"index\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"w-100 news-image\"],[9],[7,\"img\"],[11,\"class\",\"w-100\"],[12,\"src\",[28,[[23,[\"news\",\"image\"]]]]],[12,\"alt\",[28,[[23,[\"news\",\"title\"]]]]],[9],[10],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"box-title-news\"],[9],[0,\"\\n    \"],[7,\"h5\"],[9],[1,[23,[\"news\",\"title\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[23,[\"news\",\"description\"]],false],[10],[0,\"\\n\"],[4,\"link-to\",[\"home.news.detail\",[27,\"url-name\",[[23,[\"news\",\"title\"]]],null],[23,[\"news\",\"id\"]]],null,{\"statements\":[[0,\"      Tìm hiểu ngay\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"box-title-news\"],[9],[0,\"\\n    \"],[7,\"h5\"],[9],[1,[23,[\"news\",\"title\"]],false],[10],[0,\"\\n    \"],[7,\"p\"],[9],[1,[23,[\"news\",\"description\"]],false],[10],[0,\"\\n    \"],[7,\"br\"],[11,\"class\",\"mt-2\"],[9],[10],[0,\"\\n\"],[4,\"link-to\",[\"home.news.detail\",[27,\"url-name\",[[23,[\"news\",\"title\"]]],null],[23,[\"news\",\"id\"]]],null,{\"statements\":[[0,\"      Tìm hiểu ngay\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"w-100 news-image\"],[9],[7,\"img\"],[11,\"class\",\"w-100\"],[12,\"src\",[28,[[23,[\"news\",\"image\"]]]]],[12,\"alt\",[28,[[23,[\"news\",\"title\"]]]]],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/news/template.hbs"
    }
  });

  _exports.default = _default;
});