define("coffee-client/models/cash-flow", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    flow: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    value: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('format', {
        regex: /^[0-9]+$/,
        messageKey: 'messPositiveIntegers'
      })]
    },
    note: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    theStore: _emberData.default.belongsTo('store'),
    creator: _emberData.default.belongsTo('user'),
    disabler: _emberData.default.belongsTo('user'),
    createdTime: _emberData.default.attr('string'),
    disabledTime: _emberData.default.attr('string'),
    flow: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    type: _emberData.default.attr('number'),
    state: _emberData.default.attr('number'),
    value: _emberData.default.attr('number'),
    order: _emberData.default.belongsTo('order'),
    purchaseOrder: _emberData.default.belongsTo('purchaseOrder'),
    stockTake: _emberData.default.belongsTo('stockTake'),
    note: _emberData.default.attr('string'),
    dateTime: _emberData.default.attr('string'),
    canDisable: Ember.computed('state', 'type', function () {
      if (this.state !== 2 && this.type === 1) {
        return true;
      }

      return false;
    })
  });

  _exports.default = _default;
});