define("coffee-client/pod/components/e-input/power-select-model/component", ["exports", "coffee-client/mixins/validated-input"], function (_exports, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    //-- required
    selected: null,
    //selected value
    modelName: null,
    //model name
    canEmpty: true,
    //can select empty value
    optionComponent: "option-component",
    //Component display list option
    singleValueComponent: "single-value-component",
    //Component display selected value
    queryParams: [],
    //pre-defined query params
    //--
    placeholder: null,
    keyName: 'name',
    //param name for searching remote data
    limit: 7,
    //maximum number items of result
    multiple: false,
    //select multiple
    store: Ember.inject.service(),
    timeout: 400,
    //delay from typing time to query time
    lastTerm: null,
    //last search term,
    i18n: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (this.multiple === true) {
        this.set('selected', []);
      }

      this.set('placeholder', this.i18n.t('Click to select...'));
    },

    actions: {
      selectItem(value) {
        this.set('selected', value);
        let valuePath = this.valuePath;
        this.set(`model.${valuePath}`, value);
      },

      queryChanged(term) {
        let keyName = this.keyName;
        let params = this.queryParams;
        term = term.trim();
        params[keyName] = term;
        params['limit'] = this.limit;
        this.set('lastTerm', term);
        let self = this;
        Ember.run.later(function () {
          if (term === self.get('lastTerm')) {
            self.get('store').query(self.get('modelName'), params).then(result => {
              self.set('options', result.toArray());
            });
          }
        }, self.get('timeout'));
      },

      clear() {
        this.set('selected', null);
        let valuePath = this.valuePath;
        this.set(`model.${valuePath}`, null);
      }

    }
  });

  _exports.default = _default;
});