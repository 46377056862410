define("coffee-client/models/news", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    title: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    },
    image: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    newsGroup: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    urlName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    },
    content: {
      validators: [(0, _emberCpValidations.validator)('length', {
        min: 100
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    newsGroup: _emberData.default.belongsTo('news-group', {
      async: true
    }),
    title: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    content: _emberData.default.attr('string'),
    urlName: _emberData.default.attr('string'),
    published: _emberData.default.attr('number', {
      defaultValue: 1
    }),
    specific: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    createdTime: _emberData.default.attr('string'),
    modifiedTime: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    image2: _emberData.default.attr('string'),
    metaDescription: _emberData.default.attr('string'),
    metaKeywords: _emberData.default.attr('string')
  });

  _exports.default = _default;
});