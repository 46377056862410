define("coffee-client/pod/components/ecom-admin/product-property/add/component", ["exports", "coffee-client/mixins/form/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_create.default, {
    store: Ember.inject.service(),
    modelName: 'product-property',
    termList: [],
    chooseCategory: Ember.observer('selectedCategory', function () {
      let productPropertyCategory = this.store.createRecord('product-property-category');
      productPropertyCategory.set('category', this.selectedCategory);
      let duplicated = 0;
      let self = this;
      this.get('model.productPropertyCategories').forEach(function (productPropertyCategory) {
        if (productPropertyCategory.get('category.id') === self.get('selectedCategory.id')) {
          duplicated = 1;
        }
      });

      if (duplicated === 0) {
        this.get('model.productPropertyCategories').pushObject(productPropertyCategory);
      }
    }),

    init() {
      this._super(...arguments);

      this.set('visible', true);
      this.set('model.visible', true);
    },

    actions: {
      addTerm() {
        let store = this.store;
        let term = store.createRecord('product-property-term', {
          name: this.termName,
          productProperty: this.model
        });

        if (this.visible) {
          term.visible = this.visible;
        } else {
          term.visible = false;
        }

        this.set('termName', '');
        this.get('model.productPropertyTerms').pushObject(term);
        console.debug('term list', this.get('model.productPropertyTerms'));
      },

      deleteCategory(productPropertyCategory) {
        this.get('model.productPropertyCategories').removeObject(productPropertyCategory);
      },

      deleteTerm(productPropertyTerm) {
        this.get('model.productPropertyTerms').removeObject(productPropertyTerm);
      }

    }
  });

  _exports.default = _default;
});