define("coffee-client/pod/components/ecom-front/top-links/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isZoomBanner: false,
    imageZoom: null,

    init() {
      this._super(...arguments);
    },

    actions: {
      changeBanner(toplink) {
        this.set('isZoomBanner', true);
        this.set('imageZoom', image);
      },

      outChangeBanner() {
        this.set('isZoomBanner', false);
        this.set('imageZoom', null);
      }

    }
  });

  _exports.default = _default;
});