define("coffee-client/pod/components/ecom-front/select-warranty-type/component", ["exports", "coffee-client/config/environment", "coffee-client/mixins/validated-input"], function (_exports, _environment, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    warrantyTypes: [],
    selected: null,

    init() {
      this._super(...arguments);

      this.set('warrantyTypes', _environment.default.APP.product.warrantyTypeOptions);

      if (this.value) {
        this.set('selected', this.warrantyTypes[this.value]);
      } else {
        this.set('selected', this.warrantyTypes[0]);
      }
    },

    actions: {
      onchange(item) {
        this.set('selected', item);
        this.set('value', item.value);
      }

    }
  });

  _exports.default = _default;
});