define("coffee-client/pod/home/news/detail/route", ["exports", "coffee-client/config/environment", "coffee-client/helpers/url-name"], function (_exports, _environment, _urlName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    getOption: Ember.inject.service('get-option'),
    router: Ember.inject.service(),

    setHeadTags(model) {
      let domain = _environment.default.APP.domain;
      let currentUrl = domain + this.router.urlFor('home.news');
      let index = currentUrl.indexOf("?");

      if (index > 0) {
        currentUrl = currentUrl.substring(0, index);
      }

      currentUrl = currentUrl + '/' + (0, _urlName.urlName)([model.news.get('title')]) + '/' + model.news.get('id');
      this.set('headTags', []);
      let headTags = [{
        type: 'meta',
        attrs: {
          name: 'twitter:card',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:title',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'twitter:creator',
          content: '@TheMondayCoffee'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: "article"
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:site_name',
          content: 'themondaycoffee.com'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.news.title
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: 'http:' + model.news.image
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          content: model.news.metaDescription
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:url',
          content: currentUrl
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'description',
          content: model.news.metaDescription
        }
      }, {
        type: 'meta',
        attrs: {
          name: 'keywords',
          content: model.news.metaKeywords
        }
      }, {
        type: 'link',
        attrs: {
          rel: 'canonical',
          href: currentUrl
        }
      }];
      this.set('headTags', headTags);
    },

    afterModel(model) {
      this.setHeadTags(model);
    },

    model(params) {
      let news = this.store.findRecord('news', params.id);
      let listNews = this.store.query('news', {
        page: 1,
        limit: 4
      });
      let description = this.get('getOption').getOption('news-meta-description');
      let keywords = this.get('getOption').getOption('news-meta-keywords');
      let logo = this.get('getOption').getOption('logo');
      let slogan = 'The Monday Coffee | ' + this.get('getOption').getOption('slogan');
      return Ember.RSVP.hash({
        news: news,
        listNews: listNews,
        description: description,
        keywords: keywords,
        logo: logo,
        slogan: slogan
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('title', model.news.title);
    }

  });

  _exports.default = _default;
});