define("coffee-client/pod/components/ecom-front/wishlist-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7EPPwnNp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[23,[\"identity\",\"user\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"selected\"]]],null,{\"statements\":[[4,\"if\",[[27,\"user-access\",[\"remove-product-my-wished-list\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"a\"],[11,\"href\",\"\"],[11,\"class\",\"wished-product\"],[9],[1,[27,\"t\",[\"Add my wishlist\"],null],false],[7,\"i\"],[11,\"class\",\"fa fa-heart wished-product\"],[9],[10],[3,\"action\",[[22,0,[]],\"removeFromWishList\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"a\"],[11,\"href\",\"\"],[11,\"style\",\"color:#333333\"],[9],[1,[27,\"t\",[\"Add my wishlist\"],null],false],[7,\"i\"],[11,\"class\",\"fa fa-heart-o wished-product\"],[9],[10],[3,\"action\",[[22,0,[]],\"addToWishList\"]],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/wishlist-button/template.hbs"
    }
  });

  _exports.default = _default;
});