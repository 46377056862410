define("coffee-client/pod/components/ecom-front/zoom-image-desktop/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/Agezcd/",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"image-zoom-control\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"lenActive\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[28,[\"zoomLens \",[21,\"classReview\"]]]],[9],[3,\"action\",[[22,0,[]],\"zoomIn\"],[[\"on\"],[\"mouseEnter\"]]],[3,\"action\",[[22,0,[]],\"zoomOut\"],[[\"on\"],[\"mouseLeave\"]]],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"img\"],[12,\"class\",[28,[\"image-for-zoom \",[21,\"classImage\"]]]],[12,\"src\",[28,[[21,\"imageUrl\"]]]],[9],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"mouseIn\"],[[\"on\"],[\"mouseEnter\"]]],[3,\"action\",[[22,0,[]],\"mouseOut\"],[[\"on\"],[\"mouseLeave\"]]],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[28,[\"review-image \",[21,\"classReview\"],\" \",[21,\"classReviewCustom\"]]]],[12,\"style\",[28,[\"background-image: url(\",[21,\"imageUrl\"],\")\"]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"crosshair\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"horizontal-crosshair\"],[9],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"vertical-crosshair\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/zoom-image-desktop/template.hbs"
    }
  });

  _exports.default = _default;
});