define("coffee-client/pod/components/ecom-front/zoom-image/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //   init(){
    //     this._super(...arguments);
    //   },
    //   mouseX: null,
    //   mouseY: null,
    //   mouseMove(img) {
    //     this.set('mouseX', img.clientX);
    //     this.set('mouseY', img.clientY);
    //   },
    //   zoomImage: Ember.observer('mouseX', 'mouseX', function () {
    //     var posX = -((parseInt(this.get('mouseX')) * 2) - 124);
    //     var posY = -((parseInt(this.get('mouseY')) * 2) - 158);
    //     this.$('.review-image').css('background-position-x', posX);
    //     this.$('.review-image').css('background-position-y', posY);
    //   }),
    //   actions: {
    //     zoomIn()
    //     {
    //       this.set('classReview', 'active');
    //     },
    //     zoomOut()
    //     {
    //       this.set('classReview', null);
    //     }
    //   }
    psOpts: {
      index: 1
    },
    items1: [{
      src: 'http://ultraimg.com/images/bNeWGWB.jpg',
      w: 10,
      h: 1200
    }, {
      src: 'http://placekitten.com/g/1200/900',
      w: 1200,
      h: 900
    }],
    items2: [{
      src: 'http://ultraimg.com/images/bNeWGWB.jpg',
      w: 600,
      h: 400,
      title: 'kitties'
    }],

    init() {
      this._super(...arguments);

      this.set('items', this.items2);
    },

    // actions
    actions: {
      initGallery() {
        this.myGallery.init();
      },

      changeItems() {
        if (this.items === this.items1) {
          this.set('items', this.items2);
        } else {
          this.set('items', this.items1);
        }
      }

    },
    psTwoOpts: {
      hideShare: true
    }
  });

  _exports.default = _default;
});