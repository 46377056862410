define("coffee-client/mixins/route/load", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    modelName: null,
    modelPath: null,

    //---
    model(params) {
      if (this.modelName) {
        if (params.id) {
          return this.store.findRecord(this.modelName, params.id);
        }

        return this.store.createRecord(this.modelName);
      }
    },

    deactivate() {
      let path = 'currentModel';

      if (this.modelPath) {
        path = 'currentModel.' + this.modelPath;
      }

      if (this.modelName) {
        this.get(path).rollbackAttributes();
      }
    }

  });

  _exports.default = _default;
});