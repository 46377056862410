define("coffee-client/templates/components/multi-value-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LRIX1VMW",
    "block": "{\"symbols\":[],\"statements\":[[1,[23,[\"value\",\"name\"]],false],[0,\" \"],[7,\"span\"],[11,\"class\",\"multi-value__remove\"],[9],[0,\"×\"],[3,\"action\",[[22,0,[]],\"onRemoveClick\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/templates/components/multi-value-component.hbs"
    }
  });

  _exports.default = _default;
});