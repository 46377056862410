define("coffee-client/pod/components/e-input/file-uploader/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qx0RH57q",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"e-image-uploader\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"uploading\"]]],null,{\"statements\":[[0,\"    \"],[1,[21,\"e-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[27,\"e-input/input\",null,[[\"model\",\"valuePath\",\"type\"],[[23,[\"model\"]],[23,[\"valuePath\"]],\"hidden\"]]],false],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"inline-block\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"value\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[9],[0,\"\\n          \"],[2,\"{/{value}}\"],[0,\"\\n          \"],[1,[27,\"t\",[\"A file has been attached\"],null],false],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"edit\"]]],null,{\"statements\":[[0,\"          \"],[1,[27,\"e-file-uploader\",null,[[\"url\",\"class\",\"uploading\",\"uploadImageDone\",\"uploadImageError\"],[[23,[\"url\"]],\"e-file-input\",[23,[\"uploading\"]],[27,\"action\",[[22,0,[]],\"uploadImageDone\"],null],[27,\"action\",[[22,0,[]],\"uploadImageError\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"button\"],[11,\"class\",\"btn btn-xs btn-default\"],[9],[1,[27,\"t\",[\"Upload other file\"],null],false],[3,\"action\",[[22,0,[]],\"openEdit\"]],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-xs btn-default\"],[12,\"title\",[28,[[27,\"t\",[\"Delete\"],null]]]],[9],[1,[27,\"t\",[\"Clear\"],null],false],[3,\"action\",[[22,0,[]],\"removeImage\"]],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[27,\"e-file-uploader\",null,[[\"url\",\"class\",\"uploading\",\"uploadImageDone\",\"uploadImageError\"],[[23,[\"url\"]],\"e-file-input\",[23,[\"uploading\"]],[27,\"action\",[[22,0,[]],\"uploadImageDone\"],null],[27,\"action\",[[22,0,[]],\"uploadImageError\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-input/file-uploader/template.hbs"
    }
  });

  _exports.default = _default;
});