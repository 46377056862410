define("coffee-client/models/time-sheet", ["exports", "ember-data", "ember-data/model", "ember-cp-validations", "ember-inflector", "moment"], function (_exports, _emberData, _model, _emberCpValidations, _emberInflector, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const inflector = _emberInflector.default.inflector;
  inflector.irregular('activity', 'activities');
  let Validations = (0, _emberCpValidations.buildValidations)({
    inTime: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    outTime: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    activity: _emberData.default.belongsTo('activity', {
      async: true
    }),
    salary: _emberData.default.belongsTo('salary', {
      async: true
    }),
    employee: _emberData.default.belongsTo('user', {
      async: true
    }),
    inTime: _emberData.default.attr('string'),
    outTime: _emberData.default.attr('string'),
    lateTime: _emberData.default.attr('string'),
    deductedTime: _emberData.default.attr('string'),
    workingTime: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    state: _emberData.default.attr('number'),
    pay: _emberData.default.attr('number'),
    overtimeShift: _emberData.default.belongsTo('shift', {
      async: true
    }),
    inTimeHour: Ember.computed('inTime', function () {
      let time = (0, _moment.default)(this.inTime);
      return time.format('HH:mm');
    }),
    outTimeHour: Ember.computed('outTime', function () {
      let time = (0, _moment.default)(this.outTime);
      return time.format('HH:mm');
    }),
    startDate: Ember.computed('inTime', function () {
      let time = (0, _moment.default)(this.inTime);
      return '1111-11-11' + ' ' + time.format('HH:mm:ss');
    }),
    endDate: Ember.computed('outTime', function () {
      let time = (0, _moment.default)(this.outTime);
      return '1111-11-11' + ' ' + time.format('HH:mm:ss');
    }),
    lateTimeHour: Ember.computed('lateTime', function () {
      let temp1 = parseInt(parseInt(this.lateTime) / 60);
      let temp2 = parseInt(this.lateTime) % 60;

      if (temp1 < 1) {
        return temp2 + 'Phút';
      }

      if (temp2 === 0) {
        return temp1 + 'h';
      } else {
        return temp1 + 'h' + temp2;
      }
    }),
    deductedTimeHour: Ember.computed('deductedTime', function () {
      let temp1 = parseInt(parseInt(this.deductedTime) / 60);
      let temp2 = parseInt(this.deductedTime) % 60;

      if (temp1 < 1) {
        return temp2 + 'Phút';
      }

      if (temp2 === 0) {
        return temp1 + 'h';
      } else {
        return temp1 + 'h' + temp2;
      }
    })
  });

  _exports.default = _default;
});