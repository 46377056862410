define("coffee-client/pod/components/ecom-front/button-back-to-top/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "imz4WL/D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[28,[\"control-back-top \",[21,\"classBackToTop\"]]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"control-arrow\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"arrow\"],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"arrow-mask \"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"resetScroll\"]],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/button-back-to-top/template.hbs"
    }
  });

  _exports.default = _default;
});