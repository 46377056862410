define("coffee-client/serializers/employee-shift", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serialize(snapshot, options) {
      let json = this._super(...arguments);

      json.data.attributes.employee = json.data.relationships.employee.id;
      json.data.attributes.shift = json.data.relationships.shift.id; // delete item.data.relationships.product;

      return json;
    }

  });

  _exports.default = _default;
});