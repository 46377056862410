define("coffee-client/pod/components/ecom-front/display-product-variant/color/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d5W4uIjM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"p-variant-color\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"color\"],[12,\"style\",[28,[\"background-color:\",[21,\"hexCode\"],\";\\n    \",[27,\"unless\",[[23,[\"hexCode\"]],\"width:0;\"],null],\" \",[27,\"unless\",[[23,[\"hexCode\"]],\"border:none\"],null]]]],[9],[10],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"color-label\"],[9],[1,[21,\"color\"],false],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/display-product-variant/color/template.hbs"
    }
  });

  _exports.default = _default;
});