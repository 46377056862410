define("coffee-client/pod/components/light-table-cell/product-publish-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZHHPKw1g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"home.preview-product\",[23,[\"value\"]]],[[\"class\"],[\"btn btn-sm btn-save btn-bg\"]],{\"statements\":[[0,\"  \"],[7,\"i\"],[11,\"class\",\"fa fa-edit\"],[9],[10],[1,[27,\"t\",[\"Publish product\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/light-table-cell/product-publish-button/template.hbs"
    }
  });

  _exports.default = _default;
});