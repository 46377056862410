define("coffee-client/pod/home/menu/content/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    media: Ember.inject.service(),
    isTablet: Ember.computed('media.isTablet', function () {
      return this.get('media.isTablet');
    }),

    loadProducts() {// this.set('products', this.get('store').query('product', {category: this.get('category.id')}));
    },

    init() {
      this._super(...arguments);

      console.log('tablet', this.get('media.isTablet'));
    },

    actions: {
      setCat(cat) {
        this.set('category', cat);
        this.set('title', 'Menu - ' + cat.name);
        console.log('a menu', this.title);
        this.loadProducts();
      }

    }
  });

  _exports.default = _default;
});