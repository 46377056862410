define("coffee-client/pod/components/ecom-front/cart/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    opened: false,
    footer: true,
    title: true,
    clickOn: 'clickOn',
    cartData: Ember.computed.alias('cart.cartData'),
    isAllowPayment: Ember.computed.alias('cart.isAllowPayment'),
    cart: Ember.inject.service(),
    store: Ember.inject.service(),
    finalPrice: Ember.computed.alias('cart.finalPrice'),
    totalItems: Ember.computed.alias('cart.totalItems'),
    cartItems: Ember.computed.alias('cart.cartItems'),
    cartProducts: Ember.computed.alias('cart.products'),
    openedCart: Ember.observer('opened', function () {
      if (this.opened) {
        this.cart.getCartData();
      }
    }),

    //  allCartItems: [],
    //
    //  cartItemQuantityChanged: Ember.observer('allCartItems.@each.quantity', function () {
    //    let self = this;
    //    self.get('allCartItems').forEach(function (cartItem) {
    //      let find = self.get('cart').findBy(cartItem.get('product.id'),
    //        cartItem.get('productVariant.id') ? cartItem.get('productVariant.id') : null);
    //
    //      if (find) {
    //        if (find.quantity !== cartItem.get('quantity')) {
    //          find.set('quantity', cartItem.get('quantity'));
    //          find.set('price', cartItem.get('price'));
    //          find.set('totalPrice', cartItem.get('totalPrice'));
    //        }
    //      }
    //    });
    //  }),
    init() {
      this._super(...arguments); /////////////////


      this.cart.getCartData(); //this.loadCartData();
      //this.autoLoadCartData();
      //////////////////
    },

    //  autoLoadCartData(){
    //    if (!this.get('opened')) {
    //      this.loadCartData();
    //    }
    //    Ember.run.later(this, function () {
    //      this.autoLoadCartData();
    //    }, 6000);
    //  },
    actions: {
      remove(cartItem) {
        this.cart.removeCartItemObject(cartItem);
      },

      clickOn() {
        this.sendAction('clickOn');
      }

    } //  loadCartData(){
    //    let self = this;
    //    self.get('cart').initial(true);
    //    if (parseInt(self.get('cart.totalItems')) > 0) {
    //      self.get('cart').getCartData().then(function (data) {
    //        self.set('allCartItems', []);
    //        self.set('cartData', data);
    //        data.forEach(function (i) {
    //          self.get('allCartItems').pushObjects(i.get('cartItems'));
    //        });
    //      });
    //    }
    //  },

  });

  _exports.default = _default;
});