define("coffee-client/pod/components/e-route-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1Y0AbUDR",
    "block": "{\"symbols\":[\"modal\",\"&default\"],\"statements\":[[4,\"bs-modal\",null,[[\"title\",\"open\",\"body\",\"footer\",\"header\",\"size\",\"onHide\",\"autoClose\",\"backdropClose\"],[[23,[\"title\"]],[23,[\"opened\"]],false,false,false,[23,[\"size\"]],[27,\"action\",[[22,0,[]],\"close\"],null],false,false]],{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('coffee-client/pod/components/e-route-modal/template.hbs' @ L3:C5) \"],null]],[[\"class\"],[[27,\"if\",[[23,[\"headerClass\"]],[23,[\"headerClass\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"modal-title\"],[9],[0,\"\\n      \"],[1,[21,\"title\"],false],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('coffee-client/pod/components/e-route-modal/template.hbs' @ L8:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[14,2],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-route-modal/template.hbs"
    }
  });

  _exports.default = _default;
});