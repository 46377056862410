define("coffee-client/pod/components/ecom-admin/role/view-permission/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    role: null,
    permissions: [],

    init() {
      this._super(...arguments);

      this.set('permissions', this.store.query('permission', {
        roleNames: this.get('role.name')
      }));
    }

  });

  _exports.default = _default;
});