define("coffee-client/pod/components/ecom-front/product/edit/component", ["exports", "coffee-client/mixins/form/edit", "coffee-client/config/environment"], function (_exports, _edit, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_edit.default, {
    selectedTerms: [],
    productVersion: null,
    imageNos: [0, 1, 2, 3, 4, 5, 6, 7],
    // productChangeRequests: [],
    soldOld: null,
    openVariantOptionForm: false,
    regularPriceOld: null,
    inStock: null,
    published: null,
    variantOptionList: null,
    productVariantList: null,
    sizeNumberOptions: [],
    sizeSymbolOptions: [],
    giftList: [],
    store: Ember.inject.service(),
    identity: Ember.inject.service(),
    messenger: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let self = this;
      this.set('sizeNumberOptions', _environment.default.APP.product.sizeNumberOptions);
      this.set('sizeSymbolOptions', _environment.default.APP.product.sizeSymbolOptions);
      this.set('productVariantList', this.get('model.productVariants'));
      this.set('variantOptionList', this.get('model.productVariantOptions'));
      this.set('giftList', this.get('model.gifts')); // this.changeValueProduct();

      if (this.get('model.published') === 'None') {
        this.set('published', false);
      } else {
        this.set('published', true);
      }

      this.set('seller', this.store.findRecord('user', this.get('identity.user.id')));
      this.set('soldOld', this.get('model.sold'));
      this.set('regularPriceOld', this.get('model.regularPrice'));
      this.set('inStockOld', this.get('model.inStock'));
      console.debug('model.productPropertyTermDetails', this.get('model.productPropertyTermDetails.content'));

      if (this.get('model.productPropertyTermDetails')) {
        this.get('model.productPropertyTermDetails').forEach(function (productPropertyTermDetail) {
          self.get('selectedTerms').pushObject(productPropertyTermDetail.get('productPropertyTerm.content'));
        });
      }
    },

    // changeValueProduct(){
    //   let self = this;
    //   this.set('salePriceRequest', []);
    //   this.set('regularPriceRequest', []);
    //   let userId = this.get('identity.user.id');
    //   let productId = this.get('model.id');
    //   this.get('store').query('productChangeRequest',
    //     {
    //       user: userId,
    //       product: productId
    //     }).then(function (productChangeRequests) {
    //     self.set('productChangeRequests', productChangeRequests)
    //     productChangeRequests.forEach(function (productChangeRequest) {
    //       if (productChangeRequest.get('productField') === 'salePrice') {
    //         self.get('salePriceRequest').pushObject(productChangeRequest);
    //       }
    //       if (productChangeRequest.get('productField') === 'regularPrice') {
    //         self.get('regularPriceRequest').pushObject(productChangeRequest);
    //       }
    //       self.set('regularPriceRequestObject', self.get('regularPriceRequest.lastObject'));
    //       self.set('salePriceRequestObject', self.get('salePriceRequest.lastObject'));
    //     });
    //   });
    // },
    preValidation() {
      this.giftList.forEach(function (gift) {
        gift.set('endTime', gift.get('endTimeString'));
        gift.set('startTime', gift.get('startTimeString'));
      });
      return true;
    },

    submitSuccess(model) {
      let isNewList = [];
      let self = this;
      let isNewGiftList = [];
      model.reload().then(function () {
        model.get('gifts').forEach(function (gift) {
          if (gift.get('isNew')) {
            isNewGiftList.pushObject(gift);
          }
        });
        isNewGiftList.forEach(function (isNewObj) {
          let find = model.get('gifts').findBy('isNew', true);

          if (find) {
            model.get('gifts').removeObject(find);
            find.unloadRecord();
          }
        });
        isNewGiftList = [];
        model.get('productVariants').forEach(function (variant) {
          if (variant.get('isNew')) {
            isNewList.pushObject(variant);
          }
        });
        isNewList.forEach(function (isNewObj) {
          let find = model.get('productVariants').findBy('isNew', true);

          if (find) {
            model.get('productVariants').removeObject(find);
          }
        });
        isNewList = [];
        self.set('productVariantList', model.get('productVariants'));
        self.set('variantOptionList', model.get('productVariantOptions'));
        self.set('giftList', model.get('gifts'));

        self._super(...arguments);

        self.get('store').query('product-version', {
          product: model.get('id'),
          state: 2
        }).then(function (productVersions) {
          let firstObject = productVersions.get('firstObject');
          self.get('router').transitionTo('home.preview-product', firstObject.get('id'));
        });
      });
    },

    postValidation(model) {
      let checkVariantValid = true;
      let checkGiftValid = true;
      let checkDateGift = true;
      model.get('gifts').forEach(function (gift) {
        checkGiftValid = gift.get('validations.isValid'); //Check thời gian end > start

        let startTime = moment(gift.get('startTime'));
        let endTime = moment(gift.get('endTime'));

        if (startTime.diff(endTime) > 0) {
          checkDateGift = false;
        }
      });
      model.get('productVariants').forEach(function (variant) {
        variant.get('productVariantValues').forEach(function (value) {
          checkVariantValid = value.get('validations.isValid');
        });
      });

      if (checkVariantValid && checkGiftValid && checkDateGift) {
        this.set('productVariantList', []);
        this.set('variantOptionList', []);
        this.set('giftList', []);
        return true;
      } else {
        if (!checkVariantValid) {
          this.messenger.danger('Vui lòng điền đầy đủ thông tin của thuộc tính');
          return false;
        } else {
          if (!checkGiftValid) {
            this.messenger.danger('Vui lòng điền đầy đủ thông tin của quà tặng');
            return false;
          } else {
            if (!checkDateGift) {
              this.messenger.danger('Ngày bắt đầu quà tặng không được lớn hơn ngày kết thúc');
              return false;
            } else {
              this.messenger.danger('Vui lòng điền đầy đủ thông tin');
              return false;
            }
          }
        }
      }
    },

    upgradeVariant(option) {
      let self = this;
      let variantValueList = [];
      this.productVariantList.forEach(function (productVariant) {
        let variantValue = self.get('store').createRecord('product-variant-value', {
          value: '',
          productVariantOption: option
        });
        productVariant.get('productVariantValues').pushObject(variantValue);
      });
    },

    actions: {
      addGift() {
        let toDate = moment().format('YYYY-MM-DD');
        let gift = this.store.createRecord('product-gift', {
          endTimeTerm: toDate,
          startTimeTerm: toDate
        });
        this.giftList.pushObject(gift);
      },

      deleteGift(gift) {
        let self = this;
        this.giftList.removeObject(gift);
      },

      addVariant() {
        let self = this;
        let variantValueList = [];

        if (this.get('variantOptionList.length') !== 0) {
          this.variantOptionList.forEach(function (option) {
            let variantValue = self.get('store').createRecord('product-variant-value', {
              value: '',
              productVariantOption: option
            });
            variantValueList.pushObject(variantValue);
          });
          let productVariant = this.store.createRecord('product-variant', {
            productVariantValues: variantValueList,
            product: this.model
          });
          this.productVariantList.pushObject(productVariant);
        } else {
          this.messenger.danger('Vui lòng thêm option trước khi thêm biến thể!');
        }
      },

      deleteVariant(variant) {
        let self = this;
        this.productVariantList.removeObject(variant);
      },

      addVariantOption() {
        this.set("openVariantOptionForm", true);
      },

      createVariantOption(variantOption) {
        let self = this;
        let checkOption = true;
        this.variantOptionList.forEach(function (option) {
          if (option.get('name').underscore().camelize() === variantOption.get('name').underscore().camelize()) {
            checkOption = false;
          }
        });

        if (!checkOption) {
          self.get('messenger').danger('Option đã tồn tại');
        } else {
          this.variantOptionList.pushObject(variantOption);
          this.set('openVariantOptionForm', false);
          this.upgradeVariant(variantOption);
        }
      },

      deleteVariantOption(variantOption) {
        let self = this;
        this.variantOptionList.removeObject(variantOption);
        this.productVariantList.forEach(function (variant) {
          // variant.get('productVariantValues').forEach(function (variantValue) {
          //   if (variantValue.get('productVariantOption.name') === variantOption.get('name')) {
          //     variant.get('productVariantValues').removeObject(variantValue);
          //   }
          // });
          let find = variant.get('productVariantValues').findBy('productVariantOption.name', variantOption.get('name'));

          if (find) {
            variant.get('productVariantValues').removeObject(find);
          }
        });

        if (this.get('variantOptionList.length') === 0) {
          this.set('productVariantList', []);
        } // self.upgradeVariant();

      } // editAction(item){
      //   this.set(`${item}IsEdited`, true);
      // },
      // cancelEdit(item){
      //   this.set(`model.${item}`, this.get(`${item}Old`));
      //   this.set(`${item}IsEdited`, false);
      // },
      // acceptEdit(item){
      //   let self = this;
      //   if (this.get(`${item}Old`) !== this.get(`model.${item}`) && this.get(`model.${item}`) != null) {
      //     this.get('model').save().then(function () {
      //       self.set(`${item}Old`, self.get(`model.${item}`));
      //       self.set(`${item}IsEdited`, false);
      //     });
      //   }
      //   else {
      //     this.set(`${item}IsEdited`, false);
      //   }
      // },
      // requestEdit(item){
      //   if (this.get(`model.${item}`) != null &&
      //     parseInt(this.get(`${item}Old`)) !== parseInt(this.get(`model.${item}`))) {
      //     let productChangeRequest = this.get('store').createRecord('product-change-request');
      //     productChangeRequest.set('productField', item);
      //     productChangeRequest.set('changeValue', this.get(`model.${item}`));
      //     productChangeRequest.set('product', this.get('model'));
      //     productChangeRequest.set('seller', this.get('seller'));
      //     productChangeRequest.set('status', 0);
      //     this.set(`model.${item}`, this.get(`${item}Old`));
      //     productChangeRequest.save();
      //     Ember.run.later(this, function () {
      //       this.changeValueProduct();
      //     }, 400);
      //     Ember.run.later(this, function () {
      //       this.set(`${item}IsEdited`, false);
      //     }, 400);
      //   }
      //   else {
      //     this.set(`${item}IsEdited`, false);
      //   }
      // }


    }
  });

  _exports.default = _default;
});