define("coffee-client/pod/components/e-form/row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nAcB8Izz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"form-group row\"],[9],[0,\"\\n  \"],[7,\"label\"],[12,\"class\",[28,[\"col-md-\",[27,\"if\",[[23,[\"labelCol\"]],[23,[\"labelCol\"]],3],null],\" control-label text-\",[27,\"if\",[[23,[\"labelAlign\"]],[23,[\"labelAlign\"]],\"left\"],null]]]],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"translate\"]]],null,{\"statements\":[[0,\"      \"],[1,[27,\"t\",[[23,[\"label\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[21,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[4,\"if\",[[23,[\"required\"]]],null,{\"statements\":[[7,\"i\"],[11,\"class\",\"required\"],[9],[0,\"*\"],[10]],\"parameters\":[]},null],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[12,\"class\",[28,[\"col-md-\",[27,\"if\",[[23,[\"inputCol\"]],[23,[\"inputCol\"]],9],null]]]],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-form/row/template.hbs"
    }
  });

  _exports.default = _default;
});