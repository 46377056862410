define("coffee-client/pod/components/ecom-light-table-cell/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H44mAITV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"src\",[28,[[23,[\"value\",\"image1\"]]]]],[12,\"alt\",[28,[[23,[\"value\",\"name\"]]]]],[11,\"class\",\"img-fluid\"],[9],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/image/template.hbs"
    }
  });

  _exports.default = _default;
});