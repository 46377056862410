define("coffee-client/pod/components/ecom-front/header/component", ["exports", "coffee-client/helpers/user-access"], function (_exports, _userAccess) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    identity: Ember.inject.service(),
    cart: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    seenProducts: Ember.inject.service('seen-products'),
    makeOrder: Ember.inject.service('make-order'),
    isShowingModal: false,
    lookupOrderMess: null,
    lookupOrderModal: false,
    scrollWidth: 310,
    categoryMenuClass: 'category-list-wrapper',
    btnCategoryActive: false,
    isOpenSelectCat: false,
    user: null,
    isSafari: true,
    notificationNotRead: null,
    notificationCounter: 0,
    notificationList: null,
    notificationOpened: false,
    openCart: false,
    width: 67,
    placeHolder: 'Search products, categories, sellers here...',
    keyword: '',
    selectedCategory: null,
    selectedCategoryId: null,
    isLogin: false,
    mainMenuClass: 'e-header-main',
    categoryMenuOpened: false,
    autocompletedSearchDelay: 600,
    searchProducts: [],
    searchCategories: [],
    searchSellers: [],
    searchBrands: [],
    searchResultShowed: false,
    categoriesNoParent: [],
    openCartAction: null,
    isHome: false,
    headerBanner: null,
    orderCode: null,
    changeRoute: Ember.observer('routing.currentRouteName', function () {
      if (this.get('routing.currentRouteName') === 'home.index') {
        this.set('isHome', true);
        this.set('scrollWidth', 600);
      } else {
        this.set('scrollWidth', 310);
        this.set('isHome', false);
      }
    }),

    init() {
      this._super(...arguments);

      let self = this;
      let routingName = this.get('routing.currentRouteName');
      let isChrome = navigator.userAgent.indexOf('Chrome') > -1;
      let isSafari = navigator.userAgent.indexOf("Safari") > -1;

      if (isChrome && isSafari) {
        isSafari = false;
      }

      this.set('isSafari', isSafari);
      setInterval(this.run(), 1000);

      if (!this.get('media.isDesktop')) {
        this.set('placeHolder', 'Search products');
      }

      this.store.findAll('category').then(function (categories) {
        self.get('categoriesNoParent', []);
        categories.forEach(function (category) {
          if (!category.get('parent')) {
            self.get('categoriesNoParent').pushObject(category);
          }
        });
      });

      if (this.get('identity.user')) {
        this.set('user', this.store.findRecord('user', this.get('identity.user.id')));
      }

      if (this.get('identity.user')) {
        let identity = this.identity;

        if (identity.userAccess('read-notifications')) {
          this.loadNotification();
        }

        this.set('isLogin', true);
      }
    },

    loadNotification() {
      let self = this;
      this.store.query('notification', {
        read: 0,
        user: this.get('identity.user.id')
      }).then(function (notificationNotRead) {
        self.set('notificationNotRead', notificationNotRead);
        self.set('notificationCounter', notificationNotRead.get('meta.total_items'));
      });
      Ember.run.later(this, function () {
        this.loadNotification();
      }, 60000);
    },

    searchResultChanged: Ember.observer('searchCategories.[]', 'searchProducts.[]', 'searchSellers.[]', 'searchBrands.[]', function () {
      if (this.get('keyword.length') > 1) {
        if (this.get('searchProducts.length') || this.get('searchCategories.length') || this.get('searchSellers.length') || this.get('searchBrands.length')) {
          this.set('searchResultShowed', true);
        } else {
          this.set('searchResultShowed', false);
        }
      } else {
        this.set('searchResultShowed', false);
      }
    }),
    keywordChanged: Ember.observer('keyword', function () {
      this.set('searchResultShowed', false);

      if (Ember.isPresent(this.keyword)) {
        if (this.get('keyword.length') > 1) {
          Ember.run.debounce(this, 'doAutoCompletedSearch', this.autocompletedSearchDelay);
        }
      }
    }),
    categoryChanged: Ember.observer('selectedCategory.name', function () {
      Ember.run.later(this, function () {
        this.set('width', parseInt(this.$('.category-search-box').css('width')));
        this.$(".e-ipsearch").css({
          'padding-left': parseInt(this.width + 5)
        });
      }, 50);
    }),

    run() {},

    actions: {
      openLookupOrderModal() {
        this.toggleProperty('lookupOrderModal');
        this.set('lookupOrderMess', null);
        this.set('orderCode', null);
      },

      lookupOrder() {
        let self = this;

        if (this.orderCode) {
          this.store.query('order', {
            code: this.orderCode
          }).then(function (result) {
            if (result.get('length') === 1) {
              let order = result.get('firstObject');

              if (self.get('identity.user')) {
                self.get('router').transitionTo('home.user.my-orders.order-detail', order.get('id'));
              } else {
                self.get('router').transitionTo('home.lookup-order', order.get('id'));
              }

              self.set('lookupOrderModal', false);
              self.set('orderCode', null);
              self.set('lookupOrderMess', null);
            } else {
              self.set('lookupOrderMess', 'Please enter the correct code');
            }
          });
        } else {
          this.set('lookupOrderMess', 'Please enter order code');
        }
      },

      chooseNotification(notification) {
        notification.set('read', 1);
        notification.save().then(function () {
          window.location.href = notification.get('link');
        });
      },

      openNotification() {
        let self = this;

        if (this.get('identity.user')) {
          if (this.notificationOpened) {
            this.set('notificationOpened', false);
          } else {
            this.set('notificationOpened', true);
            this.store.query('notification', {
              read: 0,
              user: this.get('identity.user.id')
            }).then(function (notificationNotRead) {
              self.set('notificationNotRead', notificationNotRead);
              self.set('notificationCounter', notificationNotRead.get('meta.total_items'));
            });
            this.set('notificationList', this.store.query('notification', {
              user: this.get('identity.user.id'),
              limit: 10,
              page: 1
            }));
          }
        }
      },

      openSelectCat() {
        this.toggleProperty('isOpenSelectCat');
      },

      openSelectCatOut() {
        Ember.run.later(this, function () {
          this.set('isOpenSelectCat', false);
        }, 200);
      },

      closeCategory() {
        this.set('categoryMenuOpened', false);
      },

      selectedCategory(category) {
        this.set('selectedCategory', category);

        if (category) {
          this.set('selectedCategoryId', category.get('id'));
        } else {
          this.set('selectedCategoryId', null);
        }

        this.set('isOpenSelectCat', false);
      },

      toggleCartOpen() {
        this.toggleProperty('openCart');
      },

      fsearchFocusIn() {
        this.set('changeColor', 'change-color');
        this.set('fsearch-focus', 'fsearch-focus');

        if (this.keyword) {
          this.set('searchResultShowed', true);
        }
      },

      fsearchFocusOut() {
        this.set('changeColor', '');
        Ember.run.later(this, function () {
          this.set('searchResultShowed', false);
        }, 200);
        this.set('fsearch-focus', '');
      },

      doSearch() {
        if (this.keyword) {
          let queries = {
            keyword: this.keyword,
            brands: null,
            category: this.selectedCategoryId,
            priceEnd: null,
            priceStart: null,
            seller: null,
            page: null,
            sort: 'createdTime',
            dir: 'desc'
          };
          this.router.transitionTo('home.list-products', {
            queryParams: queries
          });
          this.set('keyword', null);
          this.set('searchResultShowed', false);
        }
      },

      clickOn() {
        this.set('openCart', false);
      },

      categoryMenuOn() {
        if (!this.set('categoryMenuOpened')) {
          this.set('categoryMenuOpened', true);
        }
      },

      categoryMenuOut() {
        if (this.set('categoryMenuOpened')) {
          this.set('categoryMenuOpened', false);
        }
      },

      hideSearchResult() {
        this.set('searchResultShowed', false);
      },

      clickOnSearchBrand(brand) {
        let queries = {
          keyword: null,
          brands: [brand.get('id')],
          category: this.selectedCategoryId,
          priceEnd: null,
          priceStart: null,
          seller: null,
          page: null,
          sort: 'createdTime',
          dir: 'desc'
        };
        this.router.transitionTo('home.list-products', {
          queryParams: queries
        });
        this.set('searchResultShowed', false);
      },

      logout() {
        this.session.invalidate();
      }

    },

    doAutoCompletedSearch() {
      let self = this;
      this.store.query('product', {
        keyword: this.keyword,
        limit: 7,
        sort: 'name',
        dir: 'asc'
      }).then(function (data) {
        self.set('searchProducts', data);
      });
      this.store.query('category', {
        name: this.keyword,
        limit: 3,
        sort: 'name',
        dir: 'asc'
      }).then(function (data) {
        self.set('searchCategories', data);
      });
      this.store.query('user', {
        query: this.keyword,
        roles: [2],
        limit: 3,
        sort: 'displayName',
        dir: 'asc'
      }).then(function (data) {
        self.set('searchSellers', data);
      });
      this.store.query('brand', {
        name: this.keyword,
        limit: 3,
        sort: 'name',
        dir: 'asc'
      }).then(function (data) {
        self.set('searchBrands', data);
      });
    },

    didInsertElement() {
      this._super(...arguments);

      let self = this;
      Ember.$(document).on('scroll', function () {
        Ember.run.debounce(self, 'scroll', 50);
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.$(document).off('scroll');
    },

    scroll() {
      this.set('windowLocation', window.location.href);

      if (this.$(document).scrollTop() >= this.scrollWidth) {
        this.set('mainMenuClass', 'e-header-main-top');
        this.set('categoryMenuClass', 'category-list-wrapper category-list-wrapper-scroll');
      } else {
        this.set('categoryMenuClass', 'category-list-wrapper');
        this.set('mainMenuClass', 'e-header-main');

        if (this.get('media.isDesktop')) {
          this.set('categoryMenuOpened', false);
        }
      }
    }

  });

  _exports.default = _default;
});