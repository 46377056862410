define("coffee-client/models/stock-take-item", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    product: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    stockTake: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    realQuantity: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true
      })]
    },
    itemQuantity: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true
      })]
    },
    reason: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    product: _emberData.default.belongsTo('product'),
    stockTake: _emberData.default.belongsTo('stock-take'),
    quantity: _emberData.default.attr('number', {
      defaulValue: 0
    }),
    inventoryQuantity: _emberData.default.attr('number', {
      defaulValue: 0
    }),
    reason: _emberData.default.attr('string'),
    realQuantity: _emberData.default.attr('number'),
    itemQuantity: _emberData.default.attr('number'),
    differenceQuantity: Ember.computed('quantity', 'inventoryQuantity', function () {
      return this.quantity - this.inventoryQuantity;
    })
  });

  _exports.default = _default;
});