define("coffee-client/pod/components/light-table-cell/product-link/image/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YdVag77J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"home.product-detail\",[27,\"url-name\",[[23,[\"value\",\"name\"]]],null],[23,[\"value\",\"id\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\"],[12,\"src\",[28,[[27,\"format-image\",null,[[\"url\",\"size\"],[[23,[\"value\",\"image1\"]],null]]]]]],[11,\"height\",\"50\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/light-table-cell/product-link/image/template.hbs"
    }
  });

  _exports.default = _default;
});