define("coffee-client/pod/components/e-user/identity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/aDYwcZY",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"content\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-user/identity/template.hbs"
    }
  });

  _exports.default = _default;
});