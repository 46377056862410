define("coffee-client/pod/home/make-order/controller", ["exports", "coffee-client/helpers/get-price", "coffee-client/helpers/get-regular-price", "coffee-client/helpers/get-sale-price", "ember-cp-validations", "ember-concurrency"], function (_exports, _getPrice, _getRegularPrice, _getSalePrice, _emberCpValidations, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    shippingName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 80
      })]
    },
    shippingAddress: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 200
      })]
    },
    shippingPhone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    shippingCity: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    shippingDistrict: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Controller.extend(Validations, {
    store: Ember.inject.service(),
    saveOrderSuccess: 'saveOrderSuccess',
    isPaymentPage: false,
    openButton: true,
    orderCompleted: false,
    order: null,
    products: null,
    allProduct: null,
    selectQueryParams: {
      hasSelling: '1'
    },
    note: "",
    shippingName: "",
    keyword: "",
    orderDetailList: null,
    identity: Ember.inject.service(),
    successMessage: 'Add successfully',
    messenger: Ember.inject.service(),
    changeVoucherContent: Ember.observer('voucherContent', function () {
      this.set('haveClickPayment', false);

      if (this.get('voucherContent') !== null && this.get('voucherContent') !== '') {
        Ember.run.debounce(this, this.checkVoucher, 1500);
      } else {
        this.set('usedVoucher', null);
        this.set('voucherObject', null);
        this.set('voucherCode', null);
      }
    }),
    finalPrice: Ember.computed('orderDetailList.@each.finalPriceComputed', function () {
      let finalPrice = 0;
      this.get('orderDetailList').forEach(function (orderDetail) {
        finalPrice = finalPrice + orderDetail.get('finalPriceComputed');
      });
      return finalPrice;
    }),

    init() {
      this._super(...arguments);

      this.orderDetailList = [];
    },

    checkVoucher() {
      if (this.voucherContent !== null && this.voucherContent !== '') {
        let voucherContent = this.voucherContent.toUpperCase();
        this.store.query('voucher', {
          content: voucherContent
        }).then(vouchers => {
          let object = vouchers.get('firstObject');

          if (object) {
            this.set('usedVoucher', object.get('discountPercent'));
            this.set('voucherObject', object);
            this.set('voucherCode', voucherContent);
          } else {
            this.set('voucherCode', null);
            this.set('voucherObject', null);
            this.set('usedVoucher', null);
          }

          this.set('haveClickPayment', true);
        });
      } else {
        this.set('usedVoucher', null);
        this.set('voucherObject', null);
        this.set('voucherCode', null);
      }
    },

    actions: {
      chooseStore(store) {
        this.set('theStore', store);
      },

      addCart(product, variant) {
        let isDuplicate = false;

        if (this.get('orderDetailList.length') > 0) {
          this.orderDetailList.forEach(function (orderDetail) {
            if (product.get('id') === orderDetail.get('product.id') && orderDetail.get('variant') === variant) {
              orderDetail.set('quantity', parseInt(orderDetail.get('quantity')) + 1);
              isDuplicate = true;
            }
          });

          if (!isDuplicate) {
            let orderDetail = this.store.createRecord('order-detail', {
              product: product,
              quantity: 1,
              variant: variant,
              price: (0, _getRegularPrice.getRegularPrice)(null, {
                product: product,
                variant: variant
              }),
              salePrice: (0, _getSalePrice.getSalePrice)(null, {
                product: product,
                variant: variant
              }),
              tempPrice: (0, _getPrice.getPrice)(null, {
                product: product,
                variant: variant
              })
            });
            this.orderDetailList.pushObject(orderDetail);
          }
        } else {
          let orderDetail = this.store.createRecord('order-detail', {
            product: product,
            quantity: 1,
            variant: variant,
            price: (0, _getRegularPrice.getRegularPrice)(null, {
              product: product,
              variant: variant
            }),
            salePrice: (0, _getSalePrice.getSalePrice)(null, {
              product: product,
              variant: variant
            }),
            tempPrice: (0, _getPrice.getPrice)(null, {
              product: product,
              variant: variant
            })
          });
          this.orderDetailList.pushObject(orderDetail);
        }
      },

      delItem(item) {
        this.orderDetailList.removeObject(item);
      },

      createOrder() {
        let order = this.get('order');
        let self = this;
        this.set('openButton', false);
        this.validate().then(_ref => {
          let {
            validations
          } = _ref;

          if (validations.get('isValid')) {
            order.set('orderDetails', this.orderDetailList);
            order.set('note', this.note);
            order.set('theStore', this.theStore);
            order.set('shippingName', this.shippingName);
            order.set('shippingPhone', this.shippingPhone);
            order.set('shippingAddress', this.shippingAddress);
            order.set('shippingCity', this.shippingCity);
            order.set('shippingDistrict', this.shippingDistrict);
            order.set('paymentMethod', 'COD');
            order.save().then(function () {
              self.set('openButton', true);
              self.set('order', self.get('store').createRecord('order'));
              self.set('orderDetailList', []);
              self.set('note', '');
              self.set('shippingPhone', '');
              self.set('shippingAddress', '');
              self.set('shippingDistrict', '');
              self.set('shippingCity', '');
              self.set('shippingName', '');
              self.set('didValidate', false);
              self.get('messenger').success(self.get('successMessage'));
              self.send('saveOrderSuccess');
              self.set('isPaymentPage', false);
            }).catch(function () {
              self.get('messenger').danger('Error');
              self.set('openButton', true);
            });
          } else {
            self.set('openButton', true);
            this.messenger.danger('Invalid inputs. Please check again');
            this.set('didValidate', true);
            return false;
          }
        });
      },

      doSearch() {
        if (this.keyword === null || this.keyword === "") {
          this.set('products', this.allProduct);
        } else {
          this.set('products', []);
          let self = this;
          console.log('all', this.allProduct);
          let strLowerCase = this.keyword.toLowerCase();
          let keyword = strLowerCase.trim();
          this.allProduct.forEach(function (product) {
            let nameLowerCase = product.get('name').toLowerCase();

            if (nameLowerCase.includes(keyword)) {
              self.get('products').pushObject(product);
            }
          });
        }
      },

      goPayment() {
        this.validate().then(_ref2 => {
          let {
            validations
          } = _ref2;

          if (validations.get('isValid')) {
            this.set('isPaymentPage', true);
          } else {
            this.messenger.danger('Invalid inputs. Please check again');
            this.set('didValidate', true);
            this.set('isPaymentPage', false);
            return false;
          }
        });
      }

    }
  });

  _exports.default = _default;
});