define("coffee-client/mixins/validated-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: ['e-input/input'],
    classNameBindings: ['showErrorClass:has-error', 'isValid:has-success'],
    model: null,
    value: null,
    type: 'text',
    valuePath: '',
    placeholder: '',
    validation: null,
    showValidations: false,
    didValidate: false,
    notValidating: Ember.computed.not('validation.isValidating').readOnly(),
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    hasWarnings: Ember.computed.notEmpty('validation.warnings').readOnly(),
    isValid: Ember.computed.and('hasContent', 'validation.isTruelyValid').readOnly(),
    shouldDisplayValidations: Ember.computed.or('showValidations', 'didValidate', 'hasContent').readOnly(),
    showErrorClass: Ember.computed.and('notValidating', 'showErrorMessage', 'hasContent', 'validation').readOnly(),
    showErrorMessage: Ember.computed.and('shouldDisplayValidations', 'validation.isInvalid').readOnly(),
    showWarningMessage: Ember.computed.and('shouldDisplayValidations', 'hasWarnings', 'isValid').readOnly(),

    init() {
      this._super(...arguments);

      let valuePath = this.valuePath;
      Ember.defineProperty(this, 'validation', Ember.computed.readOnly(`model.validations.attrs.${valuePath}`));
      Ember.defineProperty(this, 'value', Ember.computed.alias(`model.${valuePath}`));
    },

    //  mouseLeave() {
    //    this._super(...arguments);
    //    this.set('showValidations', true);
    //  },
    focusOut() {
      this._super(...arguments);

      this.set('showValidations', true);
    }

  });

  _exports.default = _default;
});