define("coffee-client/pod/components/ecom-front/footer/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),
    email: null,
    actions: {
      subscribeEmail() {
        let email = this.email;
        let self = this;
        let messenger = this.messenger;
        let model = this.store.createRecord('email-subscription', {
          email: email
        });
        model.validate().then(_ref => {
          let {
            validations
          } = _ref;
          model.set('didValidate', true);

          if (validations.get('isValid')) {
            self.set('emailDisabled', true);
            model.save().then(function () {
              messenger.success('Your email has been recorded');
              self.set('email', '');
              self.set('emailDisabled', false);
            }).catch(function () {
              messenger.success('Your email has been recorded');
              self.set('email', '');
              self.set('emailDisabled', false);
            });
          } else {
            messenger.danger('Please enter invalid email');
          }
        });
      }

    }
  });

  _exports.default = _default;
});