define("coffee-client/services/identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    user: Ember.computed('session.data.authenticated.currentUser', function () {
      return this.get('session.data.authenticated.currentUser');
    }),
    roles: Ember.computed('session.data.authenticated.currentUser', function () {
      console.debug('session.data.authenticated.currentUser', this.session.get('data.authenticated.currentUser.roles'));

      if (this.session.get('data.authenticated.currentUser.roles')) {
        return JSON.parse(this.session.get('data.authenticated.currentUser.roles'));
      }

      return ['guest'];
    }),
    locale: Ember.computed('session.data.locale', function () {
      return this.get('session.data.locale');
    }),
    permissions: Ember.computed.alias('session.data.permissions'),

    hasRole(roleName) {
      let result = false;
      this.roles.forEach(function (role) {
        if (roleName === role) {
          result = true;
        }
      });
      return result;
    },

    userAccess(permissionName) {
      let result = false;
      this.permissions.forEach(function (permission) {
        if (permission === permissionName) {
          result = true;
        }
      });

      if (!result) {
        console.error('No permission: ' + permissionName);
      }

      return result;
    },

    resetPermissions() {
      let self = this;
      let roles = this.roles;
      let session = this.session;
      console.debug('roles', roles);
      return new Ember.RSVP.Promise(function (resolve) {
        let results = [];

        if (roles) {
          resolve(self.get('store').query('permission', {
            roleNames: self.get('roles').toString()
          }).then(function (permissions) {
            let array = [];
            permissions.forEach(function (permission) {
              array.push(permission.get('name'));
            });
            session.set('data.permissions', array);
            return session.get('data.permissions');
          }));
        }

        resolve(results);
      });
    }

  });

  _exports.default = _default;
});