define("coffee-client/serializers/customer-register", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serialize() {
      let json = this._super(...arguments);

      json.data.attributes.dob = (0, _moment.default)(json.data.attributes.dob, 'DD-MM-YYYY').format('YYYY-MM-DD 00:00:00');
      return json;
    }

  });

  _exports.default = _default;
});