define("coffee-client/pod/components/ecom-admin/product-property/view/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editRoute: 'admin.product-properties.edit',
    deleteRoute: 'admin.product-properties.delete'
  });

  _exports.default = _default;
});