define("coffee-client/pod/components/ecom-front/order-print/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'canceling.canceledUser': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      integer: true
    })],
    'canceling.pinCode': (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = Ember.Component.extend(Validations, {
    messenger: Ember.inject.service(),
    ajax: Ember.inject.service(),
    model: null,
    autoPrint: true,
    successMessage: 'Change status successfully',
    confirm: false,
    wifiPass: null,
    isCancelingValid: Ember.computed.alias('validations.isValid'),
    total: Ember.computed('model.totalPrice', 'model.discount', function () {
      if (this.get('model.discount') > 0) {
        return this.get('model.totalPrice') + this.get('model.discount');
      } else {
        return this.get('model.totalPrice');
      }
    }),

    init() {
      this._super(...arguments);

      this.set('canceling', {
        canceledUser: null,
        pinCode: null
      });
    },

    actions: {
      save() {
        let self = this;

        if (!this.isCancelingValid) {
          self.get('messenger').danger('Invalid inputs. Please check again');
          self.set('didValidateCanceling', true);
          self.set('canceling.canceledUser', null);
          self.set('canceling.pinCode', null);
        } else {
          this.ajax.request('/orders/' + this.get('model.id'), {
            method: 'PATCH',
            data: JSON.stringify({
              data: {
                attributes: {
                  "canceled-user": self.get('canceling').canceledUser,
                  "pin-code": self.get('canceling').pinCode
                }
              }
            })
          }).then(() => {
            self.get('messenger').success(self.get('successMessage'));
            self.get('model').reload();
            self.set('confirm', false);
          }).catch(() => {
            self.get('messenger').danger('Invalid pin code');
          });
        }
      },

      cancelOrder() {
        this.set('confirm', true);
      },

      cancel() {
        this.set('confirm', false);
      }

    }
  });

  _exports.default = _default;
});