define("coffee-client/helpers/get-regular-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.getRegularPrice = getRegularPrice;

  function getRegularPrice(params, namedArgs) {
    let product = namedArgs.product;
    let variant = namedArgs.variant;

    if (variant === 1) {
      return product.get('price1');
    }

    if (variant === 2) {
      return product.get('price2');
    }

    if (variant === 3) {
      return product.get('price3');
    }

    if (variant === 4) {
      return product.get('price4');
    }
  }

  var _default = Ember.Helper.helper(getRegularPrice);

  _exports.default = _default;
});