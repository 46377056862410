define("coffee-client/pod/components/ecom-light-table-cell/promotion-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7F9lB4Le",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",[\"home.promotions.view\",[27,\"url-name\",[[23,[\"promotion\",\"title\"]]],null],[23,[\"promotion\",\"id\"]]],null,{\"statements\":[[0,\"  Copy link\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/promotion-link/template.hbs"
    }
  });

  _exports.default = _default;
});