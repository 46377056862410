define("coffee-client/pod/components/ecom-front/select-color/component", ["exports", "coffee-client/config/environment", "coffee-client/mixins/validated-input"], function (_exports, _environment, _validatedInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_validatedInput.default, {
    colors: [],
    value: null,
    selected: null,

    init() {
      this._super(...arguments);

      this.set('colors', _environment.default.APP.product.colorOptions);

      if (this.value) {
        let temp = this.colors.findBy('value', this.value);

        if (temp) {
          this.set('selected', temp);
        }
      } else {
        this.set('selected', this.colors[0]);
        this.set('value', this.get('selected.value'));
      }
    },

    actions: {
      onchange(item) {
        this.set('selected', item);
        this.set('value', item.value);
      }

    }
  });

  _exports.default = _default;
});