define("coffee-client/pod/components/ecom-admin/role/option-role/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "osCOK0LV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"tr\"],[9],[0,\"\\n  \"],[7,\"td\"],[9],[1,[27,\"plus-number\",null,[[\"number\",\"step\"],[[23,[\"index\"]],1]]],false],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[23,[\"option\",\"name\"]],false],[10],[0,\"\\n  \"],[7,\"td\"],[9],[1,[23,[\"option\",\"description\"]],false],[10],[0,\"\\n  \"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[4,\"unless\",[[23,[\"addMode\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"option\",\"unmodified\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"isSelected\"]]],null,{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-check color-orange\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"label\"],[9],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"isSelected\"]]]]],false],[7,\"i\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[27,\"or\",[[23,[\"option\",\"initial\"]],[23,[\"option\",\"unmodified\"]]],null]],null,{\"statements\":[[4,\"if\",[[27,\"and\",[[27,\"not\",[[23,[\"option\",\"initial\"]]],null],[23,[\"option\",\"unmodified\"]]],null]],null,{\"statements\":[],\"parameters\":[]},{\"statements\":[[0,\"          \"],[7,\"i\"],[11,\"class\",\"fa fa-check color-orange\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"label\"],[9],[1,[27,\"input\",null,[[\"type\",\"checked\"],[\"checkbox\",[23,[\"isSelected\"]]]]],false],[7,\"i\"],[9],[10],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-admin/role/option-role/template.hbs"
    }
  });

  _exports.default = _default;
});