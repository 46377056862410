define("coffee-client/pod/components/ecom-front/store-by-group/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    storeGroup: null,
    firstImage: null,
    list: null,

    init() {
      this._super(...arguments);

      this.set('storeList', this.store.query('store', {
        storeGroup: this.storeGroup
      }));
    }

  });

  _exports.default = _default;
});