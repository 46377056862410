define("coffee-client/pod/components/ecom-front/header/st-1/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    showLeftSlideMenu: false,
    actions: {
      toggleMenu(key) {
        this.toggleProperty('showLeftSlideMenu');
      }

    },

    init() {
      this._super(...arguments);

      this.store.query('category', {
        page: 1,
        published: 1,
        limit: 3
      }).then(cats => {
        this.set('cat', cats.get('firstObject')); // console.log('cat',this.get('cat'))
      });
    }

  });

  _exports.default = _default;
});