define("coffee-client/serializers/order-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      product: {
        serialize: 'records'
      },
      order: {
        serialize: false
      }
    },

    serialize() {
      let json = this._super(...arguments);

      json.data.attributes.product = json.data.product.data.id; // if (json.data.relationships) {
      //   if (json.data.relationships["product-variant"]) {
      //     if (json.data.relationships["product-variant"].data) {
      //       json.data.attributes['product-variant'] = json.data.relationships["product-variant"].data.id;
      //     }
      //   }
      // }

      delete json.data.product;
      delete json.data.relationships;
      return json;
    }

  });

  _exports.default = _default;
});