define("coffee-client/pod/components/ecom-front/select-bank/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    bankOptions: ['Ngân hàng Nông nghiệp và Phát triển Nông thôn VN', 'Ngân hàng Đại Dương', 'Ngân hàng Á Châu', 'Ngân hàng Tiên Phong', 'Ngân hàng Đông Á', 'Ngân hàng Đông Nam Á', 'Ngân hàng Bắc Á', '	Ngân hàng An Bình', 'Ngân hàng Bản Việt', 'Ngân hàng hàng Hải Việt Nam', 'Ngân hàng kỹ Thương Việt Nam', 'Ngân hàng Kiên Long', 'Ngân hàng Nam Á', '	Việt Nam Thịnh Vượng', 'Ngân hàng Công Thương Việt Nam', 'Ngân hàng	Phát triển Thành phố Hồ Chí Minh', 'Ngân hàng Quốc Dân', 'Ngân hàng Phương Đông', 'Ngân hàng Quân đội', 'Ngân hàng Đại chúng', 'Ngân hàng Quốc tế', 'Ngân hàng Sài Gòn', 'Ngân hàng Sài Gòn Công Thương', 'Ngân hàng Sài Gòn-Hà Nội', 'Ngân hàng Sài Gòn Thương Tín', 'Ngân hàng Việt Á', 'Ngân hàng Bảo Việt', 'Ngân hàng Việt Nam Thương Tín', 'Ngân hàng Xăng dầu Petrolimex', 'Ngân hàng Xuất Nhập khẩu Việt Nam', 'Ngân hàng Bưu điện Liên Việt', 'Ngân hàng Ngoại thương Việt Nam', 'Ngân hàng Đầu tư và Phát triển Việt Nam'],
    value: ""
  });

  _exports.default = _default;
});