define("coffee-client/helpers/check-position-news", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute(params) {
      if (parseInt(params[0]) === 0 || parseInt(params[0]) === 1) {
        return true;
      }

      if (parseInt(params[0]) === 2 || parseInt(params[0]) === 3) {
        return false;
      } else {
        return false;
      }
    }

  });

  _exports.default = _default;
});