define("coffee-client/pod/components/e-user/upgrade-account-form/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    displayName: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 20
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      }), (0, _emberCpValidations.validator)('length', {
        min: 9,
        max: 11
      })]
    },
    address: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 6,
        max: 250
      })]
    },
    city: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    ajax: Ember.inject.service(),
    messenger: Ember.inject.service(),
    identity: Ember.inject.service(),
    didValidate: false,
    processing: false,
    refreshRoute: 'refreshRoute',
    url: '/upgrade-seller',

    init() {
      this._super(...arguments);
    },

    actions: {
      submit() {
        let self = this;
        this.validate().then(_ref => {
          let {
            validations
          } = _ref;
          self.set('didValidate', true);
          self.set('processing', true);

          if (validations.get('isValid')) {
            self.get('ajax').request(self.get('url') + '/' + self.get('identity.user.id'), {
              method: 'PATCH',
              data: JSON.stringify({
                "data": {
                  "attributes": {
                    displayName: self.get('displayName'),
                    phone: self.get('phone'),
                    address: self.get('address'),
                    city: self.get('city'),
                    logo: self.get('logo'),
                    banner: self.get('banner'),
                    taxNumber: self.get('taxNumber'),
                    bankAccountNo: self.get('bankAccountNo'),
                    bankAccountName: self.get('bankAccountName'),
                    bank: self.get('bank'),
                    bankOffice: self.get('bankOffice'),
                    companyFullName: self.get('companyFullName')
                  }
                }
              })
            }).then(function () {
              self.set('processing', false);
              self.sendAction('refreshRoute');
            }).catch(function (error) {
              self.get('messenger').danger(error);
              self.set('processing', false);
            });
          } else {
            self.get('messenger').danger('Invalid inputs. Please check again');
            self.set('processing', false);
          }
        });
      }

    }
  });

  _exports.default = _default;
});