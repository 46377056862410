define("coffee-client/pod/components/ecom-front/my-wish-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TA/LdauD",
    "block": "{\"symbols\":[\"t\",\"body\"],\"statements\":[[7,\"div\"],[11,\"class\",\"e-my-order\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"e-table-base\"],[9],[0,\"\\n\"],[4,\"light-table\",[[23,[\"table\"]]],[[\"height\"],[\"75vh\"]],{\"statements\":[[0,\"      \"],[1,[27,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"head\"]],\"expected `t.head` to be a contextual component but found a string. Did you mean `(component t.head)`? ('coffee-client/pod/components/ecom-front/my-wish-list/template.hbs' @ L4:C8) \"],null]],[[\"fixed\",\"onColumnClick\"],[true,[27,\"action\",[[22,0,[]],\"onColumnClick\"],null]]]],false],[0,\"\\n\\n\"],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"body\"]],\"expected `t.body` to be a contextual component but found a string. Did you mean `(component t.body)`? ('coffee-client/pod/components/ecom-front/my-wish-list/template.hbs' @ L6:C9) \"],null]],[[\"canExpand\",\"multiRowExpansion\",\"canSelect\",\"onScrolledToBottom\"],[false,false,false,[27,\"action\",[[22,0,[]],\"onScrolledToBottom\"],null]]],{\"statements\":[[4,\"if\",[[23,[\"isLoading\"]]],null,{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,2,[\"loader\"]],\"expected `body.loader` to be a contextual component but found a string. Did you mean `(component body.loader)`? ('coffee-client/pod/components/ecom-front/my-wish-list/template.hbs' @ L9:C13) \"],null]],null,{\"statements\":[[0,\"            \"],[1,[21,\"e-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/my-wish-list/template.hbs"
    }
  });

  _exports.default = _default;
});