define("coffee-client/pod/components/ecom-admin/product-property/list/component", ["exports", "coffee-client/mixins/infinity-table"], function (_exports, _infinityTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_infinityTable.default, {
    modelName: 'product-property',
    columns: [{
      label: 'Name',
      valuePath: 'name'
    }],
    //-- param
    params: ['name'],
    name: null //-- ----

  });

  _exports.default = _default;
});