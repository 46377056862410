define("coffee-client/pod/components/e-modal/from-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vN0dxP0A",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"bs-button\",null,[[\"type\",\"icon\",\"action\"],[[23,[\"type\"]],[23,[\"icon\"]],\"open\"]],{\"statements\":[[0,\"  \"],[1,[21,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"bs-modal\",null,[[\"title\",\"body\",\"open\",\"footer\"],[[27,\"t\",[[23,[\"title\"]]],null],false,[23,[\"opened\"]],false]],{\"statements\":[[4,\"bs-modal-body\",null,null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-modal/from-button/template.hbs"
    }
  });

  _exports.default = _default;
});