define("coffee-client/models/product-receipt", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validatons = (0, _emberCpValidations.buildValidations)({
    product: (0, _emberCpValidations.validator)('presence', true),
    receiptItem: (0, _emberCpValidations.validator)('presence', true),
    quantity: (0, _emberCpValidations.validator)('presence', true),
    basePrice: (0, _emberCpValidations.validator)('presence', true),
    variant: (0, _emberCpValidations.validator)('presence', true)
  });

  var _default = _model.default.extend(validatons, {
    product: _emberData.default.belongsTo('product'),
    receiptItem: _emberData.default.belongsTo('product'),
    quantity: _emberData.default.attr('number'),
    basePrice: _emberData.default.attr('number'),
    variant: _emberData.default.attr('number'),
    subPrice: Ember.computed('receiptItem.pricePerUnit', function () {
      if (this.get('receiptItem.pricePerUnit') > 0) {
        return parseInt(this.quantity) * parseInt(this.get('receiptItem.pricePerUnit'));
      } else {
        return 0;
      }
    })
  });

  _exports.default = _default;
});