define("coffee-client/pod/home/news/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kw0++Iid",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"news-title text-center pt-5\"],[9],[0,\"\\n  \"],[7,\"h1\"],[11,\"style\",\"font-size: 1em;\"],[9],[1,[27,\"t\",[\"News\"],null],false],[10],[0,\"\\n\"],[4,\"link-to\",[\"home\"],null,{\"statements\":[[0,\"    \"],[1,[27,\"t\",[\"Home\"],null],false],[0,\"\\n  \"]],\"parameters\":[]},null],[0,\" | \"],[1,[27,\"t\",[\"News\"],null],false],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/home/news/template.hbs"
    }
  });

  _exports.default = _default;
});