define("coffee-client/pod/home/recruitment/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),
    actions: {
      scroll() {
        let self = this;
        this.set('i', 0);
        setInterval(function () {
          if (self.get('i') < 1940) {
            window.scrollTo(0, self.get('i'));
            self.set('i', self.get('i') + 1900);
          }
        }, 10);
      }

    }
  });

  _exports.default = _default;
});