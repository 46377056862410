define("coffee-client/pod/components/ecom-front/review-product-link/component", ["exports", "coffee-client/mixins/form/create"], function (_exports, _create) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_create.default, {
    store: Ember.inject.service(),
    productId: null,
    versionId: null,
    tagName: '',

    init() {
      this._super(...arguments);

      let self = this;
      this.store.query('product-version', {
        product: this.productId
      }).then(function (productVersions) {
        if (productVersions.get('firstObject.id')) {
          if (!self.get('isDestroyed')) {
            self.set('versionId', productVersions.get('firstObject.id'));
          }
        }
      });
    }

  });

  _exports.default = _default;
});