define("coffee-client/services/seen-products", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    seenProducts: [],
    double: false,
    currentProductId: null,
    seenProductList: Ember.computed('seenProducts', function () {
      if (!this.currentProductId) {
        return this.seenProducts;
      }

      let result = [];
      let self = this;
      this.seenProducts.forEach(function (productId) {
        if (productId !== self.get('currentProductId')) {
          result.pushObject(productId);
        }
      });
      return result.uniq();
    }),

    init() {
      let data = this.get('session.data.seenProducts');

      if (data !== undefined) {
        let self = this;
        this.get('session.data.seenProducts').forEach(function (id) {
          self.get('seenProducts').pushObject(id);
        });
      }
    },

    add(productId) {
      let data = this.get('session.data.seenProducts');

      if (data === undefined) {
        this.seenProducts.pushObject(productId);
      } else {
        let self = this;
        this.seenProducts.pushObject(productId);
        this.get('session.data.seenProducts').forEach(function (id) {
          self.get('seenProducts').pushObject(id);
        });
      }

      this.save();
    },

    save() {
      this.session.set('data.seenProducts', this.seenProducts.uniq());
      this.set('seenProducts', this.seenProducts.uniq());
    },

    clear() {
      this.session.set('data.seenProducts', []);
      this.set('seenProducts', []);
    },

    clearCurrentProductId() {
      this.set('currentProductId', null);
    }

  });

  _exports.default = _default;
});