define("coffee-client/models/role", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: _emberData.default.attr('string'),
    isDefault: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    permissionList: _emberData.default.hasMany('permission')
  });

  _exports.default = _default;
});