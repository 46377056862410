define("coffee-client/pod/components/ecom-front/wishlist-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    //  params
    product: null,
    //  end params
    wishedProduct: null,
    tagName: 'span',
    messenger: Ember.inject.service(),
    identity: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      let self = this;

      if (this.identity.userAccess('view-products')) {
        if (this.get('identity.user')) {
          this.store.query('wished-product', {
            product: self.get('product.id'),
            limit: 1
          }).then(function (data) {
            if (data.content.length > 0) {
              self.set('wishedProduct', data.get('firstObject'));
            }
          });
        }
      }
    },

    selected: Ember.computed('wishedProduct', function () {
      if (this.wishedProduct) {
        if (!this.get('wishedProduct.isDeleted')) {
          return true;
        }
      }

      return false;
    }),
    actions: {
      removeFromWishList() {
        let self = this;
        console.debug(self.get('wishedProduct'));
        self.get('wishedProduct').destroyRecord().then(function () {
          self.set('wishedProduct', null);
          self.get('messenger').info('This product was removed from your wish list');
        }).catch(function () {
          self.get('messenger').danger('Fail to remove this product from your wish list');
        });
      },

      addToWishList() {
        let self = this;
        self.get('store').createRecord('wished-product', {
          product: this.product
        }).save().then(function (object) {
          self.set('wishedProduct', object);
          self.get('messenger').info('This product was added to your wish list');
        }).catch(function () {
          self.get('messenger').danger('Fail to add this product to your wish list');
        });
      }

    }
  });

  _exports.default = _default;
});