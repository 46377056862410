define("coffee-client/mixins/_authenticated-route", ["exports", "coffee/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    session: Ember.inject.service('session'),
    permissions: _environment.default.api.permissions,

    beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('login');
      }

      if (!this.isAllowed()) {
        this.transitionTo('access-denied');
      }
    },

    isAllowed() {
      let permissionData = this.get('session.data.authenticated.currentUser.permissions');
      permissionData = JSON.parse(permissionData);
      let permissions = this.permissions;
      let currentRoute = this.routeName;

      for (let k in permissionData) {
        for (let i in permissionData[k]) {
          if (typeof permissionData[k][i] === 'string') {
            if (permissionData[k][i] === permissions[currentRoute]) {
              return true;
            }
          }
        }
      }

      return false;
    }

  });

  _exports.default = _default;
});