define("coffee-client/models/permission", ["exports", "ember-data", "ember-data/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    route: _emberData.default.attr('string'),
    initial: _emberData.default.attr('number'),
    unmodified: _emberData.default.attr('number'),
    ordering: _emberData.default.attr('number')
  });

  _exports.default = _default;
});