define("coffee-client/pod/components/coffee-light-table-row/purchase-order/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    purchaseOrder: null,

    // required params
    init() {
      this._super(...arguments);

      this.set('list', this.store.query('purchase-order-item', {
        purchaseOrder: this.get('purchaseOrder.id')
      }));
    }

  });

  _exports.default = _default;
});