define("coffee-client/serializers/stock-take", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    attrs: {
      theStore: 'store'
    }
  });

  _exports.default = _default;
});