define("coffee-client/authenticators/torii", ["exports", "ember-simple-auth/authenticators/torii"], function (_exports, _torii) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _torii.default.extend({
    torii: Ember.inject.service(),
    ajax: Ember.inject.service(),

    authenticate() {
      let ajax = this.ajax;
      return this._super(...arguments).then(data => {
        //console.debug(data);
        return ajax.request('/auth', {
          type: 'POST',
          contentType: 'application/json;charset=utf-8',
          dataType: 'json',
          data: JSON.stringify({
            authCode: data.authorizationCode,
            provider: data.provider
          })
        }).then(response => {
          return {
            // jscs:disable requireCamelCaseOrUpperCaseIdentifiers
            access_token: response.access_token,
            // jscs:enable requireCamelCaseOrUpperCaseIdentifiers
            provider: data.provider,
            expires_in: response.expires_in,
            currentUser: response.currentUser
          };
        });
      });
    }

  });

  _exports.default = _default;
});