define("coffee-client/pod/components/ecom-front/select-sort/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    sortOptions: [{
      displayName: 'Giá thấp nhất',
      param: ['asc', 'price']
    }, {
      displayName: 'Giá cao nhất',
      param: ['dec', 'price']
    }]
  });

  _exports.default = _default;
});