define("coffee-client/pod/components/ecom-front/header/categories/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    browser: Ember.inject.service(),
    noScroll: false,
    openCart: false,
    current: null,
    opened: false,
    myClass: '',
    openChildCategories: false,
    categoriesList: [],
    childrenList: [],
    activeImage: null,
    //background image
    closeCategory: 'closeCategory',
    categoryMenuClass: 'category-list-wrapper',
    isFirefox: null,

    init() {
      this._super(...arguments);

      let self = this;
      this.set('isFirefox', navigator.userAgent.indexOf("Firefox") > -1);
      this.store.findAll('category').then(function (categories) {
        self.set('childrenList', []);
        self.set('openChildCategories', false);
        categories.forEach(function (item) {
          if (item.get('parent.id')) {} else {
            let find = self.get('categoriesList').findBy('id', item.get('id'));

            if (find === undefined) {
              self.get('categoriesList').pushObject(item);
            }
          }
        });
        self.set('categoriesList', self.get('categoriesList').sortBy('position'));
      });
    },

    actions: {
      closeCategory() {
        this.set('opened', false);
        this.set('current', null);
      },

      openChildCategories(item) {
        Ember.run.debounce(this, 'openChildCategoriesDebounce', item, 150);
      },

      clickOn() {
        this.set('myClass', 'no-active');
        this.set('opened', false);
        this.set('current', null);
      }

    },

    openChildCategoriesDebounce(item) {
      this.set('current', item);
      this.set('activeImage', item.get('image'));
      this.set('myClass', 'active');
      this.set('classBgMenu', 'active');
      this.set('openChildCategories', true);
      this.childrenList.clear();
      let counter = 0;
      let self = this;
      item.get('topChildren').forEach(function (item) {
        if (item.get('blockOnHome')) {
          self.get('childrenList').pushObject(item);
        }
      });

      if (this.childrenList.length > 0) {
        this.set('myClass', ' active activeLi');
        this.set('classBgMenu', 'open-child');
      } else {
        this.set('myClass', '');
      }
    }

  });

  _exports.default = _default;
});