define("coffee-client/pod/components/ecom-admin/role/option-role/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    option: null,
    isSelected: null,
    countNumber: null,
    tagName: '',
    addMode: false,

    init() {
      this._super(...arguments);

      this.set('countNumber', parseInt(this.index) + 1);
    }

  });

  _exports.default = _default;
});