define("coffee-client/pod/print-stock-take/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(...arguments);

      controller.set('stockTake', model);
      controller.set('stockTakeItems', []);
      this.store.query('stock-take-item', {
        stockTake: model.get('id')
      }).then(list => {
        controller.stockTakeItems.addObjects(list);
      });
    }

  });

  _exports.default = _default;
});