define("coffee-client/pod/home/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nsPflfF0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page\"],[9],[0,\"\\n  \"],[2,\"Start header page\"],[0,\"\\n  \"],[1,[21,\"ecom-front/header/st-1\"],false],[0,\"\\n  \"],[2,\"End header page\"],[0,\"\\n  \"],[2,\"Start aside left\"],[0,\"\\n  \"],[1,[21,\"ecom-front/aside/left\"],false],[0,\"\\n  \"],[2,\"End header page\"],[0,\"\\n  \"],[2,\"Start load content Sub page\"],[0,\"\\n  \"],[7,\"main\"],[11,\"class\",\"main\"],[9],[0,\"\\n    \"],[1,[27,\"liquid-outlet\",null,[[\"class\"],[\"demo-container\"]]],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[2,\"End load content Sub page\"],[0,\"\\n  \"],[2,\"Start footer\"],[0,\"\\n  \"],[1,[21,\"ecom-front/footer/ls-1\"],false],[0,\"\\n  \"],[2,\"End footer\"],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/home/template.hbs"
    }
  });

  _exports.default = _default;
});