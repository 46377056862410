define("coffee-client/pod/components/ecom-admin/header/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    login: null,
    isSuperUser: false,
    newOrderNumber: 0,
    newContactNumber: 0,
    totalNotification: 0,
    pendingProducts: null,
    pendingProductVersions: null,
    pendingPremiumAccounts: null,
    pendingUpgradeAccounts: null,
    pendingRatings: null,
    pendingComments: null,
    pendingContacts: null,
    newSellerRegisters: null,
    notificationsNumber: 0,
    notificationIsOpen: false,
    identity: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.loadNotification();
    },

    //@todo mở comment
    loadNotification() {
      let self = this;
      let promises = null;

      if (this.identity.userAccess('view-all-orders') && this.identity.userAccess('manage-contacts')) {
        promises = {
          orders: this.store.query('order', {
            status: 2
          }),
          contacts: this.store.query('contact', {
            done: 0
          })
        };
      } else {
        if (this.identity.userAccess('manage-contacts')) {
          promises = {
            contacts: this.store.query('contact', {
              done: 0
            })
          };
        }

        if (this.identity.userAccess('view-all-orders')) {
          promises = {
            orders: this.store.query('order', {
              status: 2
            })
          };
        }
      }

      if (promises) {
        Ember.RSVP.hash(promises).then(function (hash) {
          if (self.get('identity').userAccess('manage-contacts')) {
            self.set('newContactNumber', hash.contacts.get('meta').total_items);
          }

          if (self.get('identity').userAccess('view-all-orders')) {
            self.set('newOrderNumber', hash.orders.get('meta').total_items);
          }

          let totalNotification = parseInt(self.get('newOrderNumber')) + parseInt(self.get('newContactNumber'));
          self.set('totalNotification', totalNotification);
        });
      }

      Ember.run.later(this, function () {
        self.loadNotification();
      }, 120000);
    },

    actions: {
      logout() {
        this.session.invalidate();
      }

    }
  });

  _exports.default = _default;
});