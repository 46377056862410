define("coffee-client/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sFwq1idb",
    "block": "{\"symbols\":[],\"statements\":[[1,[21,\"head-layout\"],false],[0,\"\\n\"],[1,[27,\"ember-notify\",null,[[\"messageStyle\"],[\"bootstrap\"]]],false],[0,\"\\n\"],[1,[21,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/templates/application.hbs"
    }
  });

  _exports.default = _default;
});