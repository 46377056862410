define("coffee-client/pod/components/ecom-admin/product-property/edit/component", ["exports", "coffee-client/mixins/form/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_edit.default, {
    store: Ember.inject.service(),
    termList: [],
    chooseCategory: Ember.observer('selectedCategory', function () {
      let productPropertyCategory = this.store.createRecord('product-property-category');
      productPropertyCategory.set('category', this.selectedCategory);
      let self = this;
      let duplicated = 0;
      this.get('model.productPropertyCategories').forEach(function (productPropertyCategory) {
        if (productPropertyCategory.get('category.id') === self.get('selectedCategory.id')) {
          duplicated = 1;
        }
      });

      if (duplicated === 0) {
        this.get('model.productPropertyCategories').pushObject(productPropertyCategory);
      }
    }),

    init() {
      this._super(...arguments);

      this.set('visible', true);
    },

    submitSuccess(model) {
      console.debug('productPropertyCategories', model.get('productPropertyCategories'));
      model.get('productPropertyCategories').forEach(function (productPropertyCategory) {
        if (productPropertyCategory.get('isNew')) {
          console.debug('name', productPropertyCategory.get('category.name'));
          console.debug('new', productPropertyCategory.get('isNew'));
          model.get('productPropertyCategories').removeObject(productPropertyCategory);
        }
      });
      model.get('productPropertyTerms').forEach(function (productPropertyTerm) {
        if (productPropertyTerm.get('isNew')) {
          model.get('productPropertyTerms').removeObject(productPropertyTerm);
        }
      });

      this._super(...arguments);
    },

    actions: {
      addTerm() {
        let store = this.store;
        let term = store.createRecord('product-property-term', {
          name: this.termName,
          productProperty: this.model
        });

        if (this.visible) {
          term.set('visible', this.visible);
        } else {
          term.set('visible', false);
        }

        this.set('termName', '');
        this.get('model.productPropertyTerms').pushObject(term);
      },

      deleteCategory(productPropertyCategory) {
        this.get('model.productPropertyCategories').removeObject(productPropertyCategory);
      },

      deleteTerm(productPropertyTerm) {
        this.get('model.productPropertyTerms').removeObject(productPropertyTerm);
      }

    }
  });

  _exports.default = _default;
});