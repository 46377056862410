define("coffee-client/pod/home/news/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BPH6mooS",
    "block": "{\"symbols\":[\"news\",\"index\"],\"statements\":[[1,[27,\"page-title\",[\"Tin tức - The Monday Coffee\"],[[\"_deprecate\"],[null]]],false],[0,\"\\n\"],[7,\"section\"],[11,\"class\",\"mt-4 container section section-home section-news\"],[11,\"id\",\"section5\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\",\"newsList\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"card card-news-item card-news-item-home shadow-none rounded-0\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"align-items-center\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"home.news.detail\",[27,\"url-name\",[[22,1,[\"title\"]]],null],[22,1,[\"id\"]]],null,{\"statements\":[[0,\"          \"],[7,\"img\"],[11,\"class\",\"card-img-top rounded-0 img-fluid\"],[12,\"src\",[28,[[22,1,[\"image\"]]]]],[12,\"alt\",[28,[[22,1,[\"title\"]]]]],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n          \"],[7,\"h4\"],[11,\"class\",\"card-title text-uppercase m-0\"],[9],[0,\"\\n            \"],[4,\"link-to\",[\"home.news.detail\",[27,\"url-name\",[[22,1,[\"title\"]]],null],[22,1,[\"id\"]]],[[\"class\",\"titleBinding\"],[\"text-dark link text-uppercase shadow-none\",[22,1,[\"title\"]]]],{\"statements\":[[1,[22,1,[\"title\"]],true]],\"parameters\":[]},null],[0,\"\\n          \"],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"mt-2\"],[9],[1,[22,1,[\"description\"]],true],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/home/news/index/template.hbs"
    }
  });

  _exports.default = _default;
});