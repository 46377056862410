define("coffee-client/pod/components/ecom-light-table-cell/inventory-tracking-action-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9ezwLGVa",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],\"1\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Is changed\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],\"2\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Is sold\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[27,\"eq\",[[23,[\"value\"]],\"3\"],null]],null,{\"statements\":[[0,\"  \"],[1,[27,\"t\",[\"Returned\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-light-table-cell/inventory-tracking-action-type/template.hbs"
    }
  });

  _exports.default = _default;
});