define("coffee-client/pod/components/ecom-front/ads-home/position-2/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b5Wkcwuj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row ads-home\"],[9],[0,\"\\n  \"],[1,[27,\"ecom-front/display-home-ads\",null,[[\"className\",\"position\",\"homeAds\",\"noDisplayPendingItem\"],[\"col-sm-5 no-padding-left\",10,[23,[\"ads\"]],true]]],false],[0,\"\\n  \"],[1,[27,\"ecom-front/display-home-ads\",null,[[\"className\",\"position\",\"homeAds\",\"noDisplayPendingItem\"],[\"col-sm-5 no-padding-left\",11,[23,[\"ads\"]],true]]],false],[0,\"\\n  \"],[1,[27,\"ecom-front/display-home-ads\",null,[[\"className\",\"position\",\"homeAds\",\"noDisplayPendingItem\"],[\"col-sm-2 no-padding sm-banner\",12,[23,[\"ads\"]],true]]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/ads-home/position-2/template.hbs"
    }
  });

  _exports.default = _default;
});