define("coffee-client/pod/components/e-route-modal/delete-model/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    model: null,
    id: null,
    backTo: null,
    title: null,
    message: null,
    processing: false,
    messenger: Ember.inject.service(),
    successMessage: 'Delete successfully',
    i18n: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (this.namePath) {
        let nameValue = this.get('model.' + this.namePath);
        this.set('title', this.i18n.t(this.title) + ': ' + nameValue);
      }
    },

    actions: {
      ok(model) {
        let self = this;
        this.set('processing', true);
        model.destroyRecord().then(function () {
          //model.transitionTo('loaded.saved');
          self.get('messenger').success(self.get('successMessage'));
          self.get('router').transitionTo(self.get('backTo'));
          self.set('processing', false);
        }).catch(function (reason) {
          model.rollbackAttributes();
          self.get('messenger').danger(reason);
          self.set('processing', false);
        });
      },

      cancel() {
        this.router.transitionTo(this.backTo);
      }

    }
  });

  _exports.default = _default;
});