define("coffee-client/pod/components/e-form/component", ["exports", "coffee-client/mixins/route/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resetScroll.default, {
    loading: false,
    action: 'submit',
    actionLabel: 'Save',
    showSubmit: true,
    actions: {
      action() {
        window.scrollTo(0, 0);
        this.sendAction('action');
      }

    }
  });

  _exports.default = _default;
});