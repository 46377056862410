define("coffee-client/transforms/user-state", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function (serialized) {
      if (serialized === 0) {
        return 'Disabled';
      }

      if (serialized === 1) {
        return 'Active';
      }

      if (serialized === 2) {
        return 'Normal pending';
      }

      if (serialized === 3) {
        return 'Seller pending';
      }

      return 'None';
    },
    serialize: function (deserialized) {
      if (deserialized === 'Disabled') {
        return 0;
      }

      if (deserialized === 'Active') {
        return 1;
      }

      if (deserialized === 'Normal pending') {
        return 2;
      }

      if (deserialized === 'Seller pending') {
        return 3;
      }

      return 0;
    }
  });

  _exports.default = _default;
});