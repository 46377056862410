define("coffee-client/pod/components/e-yes-no-modal/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yA8R7f5B",
    "block": "{\"symbols\":[\"modal\",\"footer\",\"&default\"],\"statements\":[[4,\"bs-modal\",null,[[\"onSubmit\",\"onHide\",\"open\"],[[23,[\"onSubmit\"]],[27,\"action\",[[22,0,[]],\"onHide\"],null],[23,[\"open\"]]]],{\"statements\":[[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"header\"]],\"expected `modal.header` to be a contextual component but found a string. Did you mean `(component modal.header)`? ('coffee-client/pod/components/e-yes-no-modal/template.hbs' @ L2:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"h4\"],[11,\"class\",\"modal-title\"],[9],[1,[27,\"t\",[\"Notice\"],null],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"body\"]],\"expected `modal.body` to be a contextual component but found a string. Did you mean `(component modal.body)`? ('coffee-client/pod/components/e-yes-no-modal/template.hbs' @ L5:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[14,3],[0,\"\\n    \"],[1,[27,\"t\",[\"Are you sure you want to do that\"],null],false],[0,\" ???\\n\"]],\"parameters\":[]},null],[4,\"component\",[[27,\"-assert-implicit-component-helper-argument\",[[22,1,[\"footer\"]],\"expected `modal.footer` to be a contextual component but found a string. Did you mean `(component modal.footer)`? ('coffee-client/pod/components/e-yes-no-modal/template.hbs' @ L9:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[4,\"bs-button\",null,[[\"onClick\",\"type\"],[[27,\"action\",[[22,0,[]],[22,1,[\"close\"]]],null],\"danger\"]],{\"statements\":[[0,\" \"],[1,[27,\"t\",[\"No\"],null],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"onClick\",\"type\"],[[27,\"action\",[[22,0,[]],[22,1,[\"submit\"]]],null],\"success\"]],{\"statements\":[[0,\" \"],[1,[27,\"t\",[\"Yes\"],null],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-yes-no-modal/template.hbs"
    }
  });

  _exports.default = _default;
});