define("coffee-client/pod/components/ecom-admin/menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    login: null,
    isSuperUser: false,
    newOrderNumber: 0,
    pendingProducts: null,
    pendingProductVersions: null,
    pendingPremiumAccounts: null,
    pendingUpgradeAccounts: null,
    pendingRatings: null,
    pendingComments: null,
    pendingContacts: null,
    newSellerRegisters: null,
    notificationsNumber: 0,
    notificationIsOpen: false,
    identity: Ember.inject.service(),
    session: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments); // this.loadNotification();


      if (this.identity.userAccess('view-all-orders')) {
        this.loadNewOrderNumber();
      }
    },

    //@todo mở comment
    // loadNotification(){
    //   let self = this;
    //   let store = this.get('store');
    //   this.set('notificationsNumber', 0);
    //
    //   let identity = this.get('identity');
    //
    //   if (identity.userAccess('view-all-products')) {
    //     store.query('product', {limit: 1, published: 2})
    //       .then(function (pendingProducts) {
    //         self.set('pendingProducts', pendingProducts);
    //         let notificationsNumber = self.get('notificationsNumber') + pendingProducts.get('meta').total_items;
    //         self.set('notificationsNumber', notificationsNumber);
    //       });
    //
    //     store.query('product-version', {limit: 1, state: 2})
    //       .then(function (pendingProductVersions) {
    //         self.set('pendingProductVersions', pendingProductVersions);
    //         let notificationsNumber = self.get('notificationsNumber') + pendingProductVersions.get('meta').total_items;
    //         self.set('notificationsNumber', notificationsNumber);
    //       });
    //   }
    //
    //   if (identity.userAccess('view-all-products')) {
    //     store.query('product-version', {limit: 1, state: 2})
    //       .then(function (pendingProductVersions) {
    //         self.set('pendingProductVersions', pendingProductVersions);
    //         let notificationsNumber = self.get('notificationsNumber') + pendingProductVersions.get('meta').total_items;
    //         self.set('notificationsNumber', notificationsNumber);
    //       });
    //   }
    //
    //   if (identity.userAccess('manage-product-ratings')) {
    //     store.query('product-rating', {published: 0})
    //       .then(function (pendingRatings) {
    //         self.set('pendingRatings', pendingRatings);
    //         let notificationsNumber = self.get('notificationsNumber') + pendingRatings.get('meta').total_items;
    //         self.set('notificationsNumber', notificationsNumber);
    //       });
    //   }
    //
    //   if (identity.userAccess('manage-product-comments')) {
    //     store.query('comment', {published: 0})
    //       .then(function (pendingComments) {
    //         self.set('pendingComments', pendingComments);
    //         let notificationsNumber = self.get('notificationsNumber') + pendingComments.get('meta').total_items;
    //         self.set('notificationsNumber', notificationsNumber);
    //       });
    //   }
    //
    //   if (identity.userAccess('manage-contacts')) {
    //     store.query('contact', {done: 0})
    //       .then(function (pendingContacts) {
    //         self.set('pendingContacts', pendingContacts);
    //         let notificationsNumber = self.get('notificationsNumber') + pendingContacts.get('meta').total_items;
    //         self.set('notificationsNumber', notificationsNumber);
    //       });
    //   }
    //
    //   if (identity.userAccess('manage-seller-registrations')) {
    //     store.query('seller-register', {state: 1})
    //       .then(function (newSellerRegisters) {
    //         self.set('newSellerRegisters', newSellerRegisters);
    //         let notificationsNumber = self.get('notificationsNumber') + newSellerRegisters.get('meta').total_items;
    //         self.set('notificationsNumber', notificationsNumber);
    //       });
    //   }
    //
    //   Ember.run.later(this, function () {
    //     this.loadNotification();
    //   }, 90000);
    // },
    loadNewOrderNumber() {
      let self = this;
      this.store.query('order', {
        status: 1
      }).then(function (data) {
        self.set('newOrderNumber', data.get('meta').total_items);
      });
      Ember.run.later(this, function () {
        self.loadNewOrderNumber();
      }, 30000);
    },

    actions: {
      logout() {
        this.session.invalidate();
      }

    }
  });

  _exports.default = _default;
});