define("coffee-client/models/purchase-order-item", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    product: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    purchaseOrder: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    realQuantity: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: 0,
        allowString: true
      })]
    },
    itemQuantity: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: 0,
        allowString: true
      })]
    },
    price: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        gt: 0,
        allowString: true
      })]
    },
    discount: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowBlank: true,
        allowString: true
      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    product: _emberData.default.belongsTo('product'),
    purchaseOrder: _emberData.default.belongsTo('purchase-order'),
    quantity: _emberData.default.attr('number'),
    realQuantity: _emberData.default.attr('number'),
    itemQuantity: _emberData.default.attr('number'),
    price: _emberData.default.attr('number'),
    discount: _emberData.default.attr('number'),
    totalPrice: _emberData.default.attr('number'),
    note: _emberData.default.attr('string'),
    modifier: _emberData.default.belongsTo('user'),
    modifiedTime: _emberData.default.attr('string'),
    totalPriceTemp: Ember.computed('quantity', 'price', 'discount', function () {
      return this.price * this.realQuantity - this.discount;
    })
  });

  _exports.default = _default;
});