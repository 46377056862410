define("coffee-client/pod/components/ecom-front/list-products-2/content/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    orderOptions: ['newest', 'oldest', 'highest price', 'lowest price'],
    total: 0,
    params: null,
    sort: null,
    dir: null,
    limit: 0,
    page: 0,
    priceStart: 0,
    priceEnd: 60000000,
    maxPagesInList: 7,
    selectedBrands: [],
    priceRange: [0, 0],
    allCategories: null,
    category: null,
    selectedCategory: null,
    seller: null,
    keyword: '',
    brands: Ember.computed('selectedBrands.@each', function () {
      let idList = [];
      this.selectedBrands.forEach(function (brand) {
        idList.pushObject(brand.id);
      });
      return idList;
    }),
    brandChange: Ember.observer('selectedBrands.@each', function () {
      this.set('page', 1);
      this.transition();
    }),
    selectedCategoryChanged: Ember.observer('selectedCategory.id', function () {
      if (this.get('selectedCategory.id')) {
        this.set('category', this.get('selectedCategory.id'));
        this.doTransition();
      }
    }),
    keywordChanged: Ember.observer('keyword', function () {
      this.transition();
    }),
    orderChanged: Ember.observer('order', function () {
      if (this.order === 'newest') {
        this.set('sort', 'createdTime');
        this.set('dir', 'desc');
      }

      if (this.order === 'oldest') {
        this.set('sort', 'createdTime');
        this.set('dir', 'asc');
      }

      if (this.order === 'highest price') {
        this.set('sort', 'price');
        this.set('dir', 'desc');
      }

      if (this.order === 'lowest price') {
        this.set('sort', 'price');
        this.set('dir', 'asc');
      }

      this.doTransition();
    }),
    priceRangeChanged: Ember.observer('priceRange', function () {
      this.set('priceStart', this.priceRange.objectAt(0));
      this.set('priceEnd', this.priceRange.objectAt(1));

      if (parseInt(this.priceStart) > 0 || parseInt(this.priceEnd) > 0) {
        this.set('activeRangeSlider', 'active');
      } else {
        this.set('activeRangeSlider', '');
      }

      this.set('page', 1);
      this.transition();
    }),

    init() {
      this._super(...arguments);

      this.set('selected', this.order);
      let products = this.products;
      let meta = products.get('meta');
      this.set('total', parseInt(meta.total_items));
      this.set('dir', this.dir);
      this.set('sort', this.sort);
      this.set('limit', parseInt(this.limit));
      this.set('page', parseInt(this.page));

      if (parseInt(this.priceStart) > 0 || parseInt(this.priceEnd) > 0) {
        this.set('activeRangeSlider', 'active');
      }

      let priceRange = [];
      priceRange.pushObjects([parseInt(this.priceStart), parseInt(this.priceEnd)]);
      this.set('priceRange', priceRange);
    },

    actions: {
      cancelSeller() {
        this.set('seller', undefined);
        this.doTransition();
      },

      getPage(pageNumber) {
        this.set('page', pageNumber);
        this.transition();
      },

      filterByPrice(priceStart, priceEnd) {
        this.set('priceStart', priceStart);
        this.set('priceEnd', priceEnd);
        this.set('page', 1);
        this.doTransition();
      },

      setAllCategory() {
        this.set('category', undefined);
        this.doTransition();
      }

    },

    transition() {
      Ember.run.debounce(this, this.doTransition, 2500);
    },

    doTransition() {
      window.scrollTo(0, 0);
      let queries = this.getProperties(['page', 'priceStart', 'priceEnd', 'brands', 'category', 'keyword', 'seller', 'dir', 'sort']);
      this.router.transitionTo('home.list-products', {
        queryParams: queries
      });
    }

  });

  _exports.default = _default;
});