define("coffee-client/pod/components/ecom-front/product-range-price/component", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    quantityStart: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    },
    quantityEnd: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    },
    price: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('number', {
        allowString: true,
        integer: true
      })]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    labelCol: 3,
    inputCol: 9,
    model: null,
    store: Ember.inject.service(),
    messenger: Ember.inject.service(),
    changeStartQuantity: Ember.observer('model.productPriceTiers.@each.quantityStart', function () {
      this.get('model.productPriceTiers').forEach(function (productPriceTier) {
        if (parseInt(productPriceTier.get('quantityEnd')) < parseInt(productPriceTier.get('quantityStart'))) {
          productPriceTier.set('quantityEnd', productPriceTier.get('quantityStart'));
        }
      });

      if (parseInt(this.get('model.productPriceTiers.length')) > 0) {
        let firstObj = this.get('model.productPriceTiers').objectAt(0);

        if (parseInt(firstObj.get('quantityStart')) < 2) {
          firstObj.set('quantityStart', 2);
        }
      }
    }),

    init() {
      this._super(...arguments);
    },

    actions: {
      inputFocusOut() {
        this.changeValueEven();
      },

      createForm() {
        if (this.checkPrice()) {
          if (this.get('model.productPriceTiers.length') > 0) {
            let productPriceTier = this.store.createRecord('product-price-tier');
            let lastObj = this.get('model.productPriceTiers').get('lastObject');

            if (parseInt(lastObj.get('quantityEnd')) && parseInt(lastObj.get('price'))) {
              productPriceTier.set('quantityStart', parseInt(lastObj.get('quantityEnd')) + 1);
              productPriceTier.set('product', this.model);
              this.get('model.productPriceTiers').pushObject(productPriceTier);
            } else {
              this.messenger.danger('_product-range-price-note-1');
              this.set('didValidate', true);
              return false;
            }
          } else {
            let productPriceTier = this.store.createRecord('product-price-tier');
            let lastObj = this.get('model.productPriceTiers').get('lastObject');
            productPriceTier.set('quantityStart', 2);
            productPriceTier.set('product', this.model);
            this.get('model.productPriceTiers').pushObject(productPriceTier);
          }
        } else {
          this.messenger.danger('_product-range-price-check-price');
          this.set('didValidate', true);
        }
      },

      checkPriceFocusOut(value) {
        if (!this.checkPrice()) {
          this.messenger.danger('_product-range-price-check-price');
          this.set('didValidate', true);
        }
      },

      addRangePrice() {
        this.validate().then(_ref => {
          let {
            model,
            validations
          } = _ref;

          if (validations.get('isValid')) {
            if (parseInt(this.quantityStart) > parseInt(this.quantityEnd)) {
              this.messenger.danger('_product-range-price-note-1');
              this.set('didValidate', true);
              return false;
            } else {
              if (this.get('model.productPriceTiers.length') > 0) {
                let lastObj = this.get('model.productPriceTiers').get('lastObject');

                if (parseInt(this.quantityStart) < parseInt(lastObj.get('quantityEnd'))) {
                  this.messenger.danger('_product-range-price-note-2');
                  this.set('didValidate', true);
                  return false;
                } else {
                  this.addProductPriceTier();
                }
              } else {
                this.addProductPriceTier();
              }
            }
          } else {
            this.messenger.danger('_product-range-price-note');
            this.set('didValidate', true);
            return false;
          }
        });
      },

      deleteRangePrice(productPriceTier) {
        this.get('model.productPriceTiers').removeObject(productPriceTier);
      }

    },

    changeValueEven() {
      let self = this;
      self.get('model.productPriceTiers').forEach(function (productPriceTier) {
        if (!parseInt(productPriceTier.get('quantityStart'))) {
          productPriceTier.set('quantityStart', 0);
        }

        if (parseInt(productPriceTier.get('quantityEnd')) < parseInt(productPriceTier.get('quantityStart'))) {
          productPriceTier.set('quantityEnd', productPriceTier.get('quantityStart'));
        }

        if (!parseInt(productPriceTier.get('quantityEnd'))) {
          productPriceTier.set('quantityEnd', productPriceTier.get('quantityStart'));
        }
      });

      if (parseInt(this.get('model.productPriceTiers.length')) > 1) {
        let length = parseInt(this.get('model.productPriceTiers.length'));
        let quantityEndBefore = 0;

        for (let i = 0; i < length; i++) {
          let obj = this.get('model.productPriceTiers').objectAt(i);

          if (i > 0) {
            obj.set('quantityStart', parseInt(quantityEndBefore) + 1);
            quantityEndBefore = obj.get('quantityEnd');
          } else {
            quantityEndBefore = obj.get('quantityEnd');
          }
        }
      }
    },

    checkPrice() {
      if (parseInt(this.get('model.productPriceTiers.length')) > 1) {
        let length = parseInt(this.get('model.productPriceTiers.length'));
        let quantityEndBefore = 0;
        let check = true;

        for (let i = 1; i < length; i++) {
          let obj = this.get('model.productPriceTiers').objectAt(i);
          let objBefore = this.get('model.productPriceTiers').objectAt(i - 1);

          if (parseInt(obj.get('price')) > parseInt(objBefore.get('price'))) {
            check = false;
            obj.set('price', null);
            return false;
          } else {
            check = true;
          }
        }

        return check;
      } else {
        return true;
      }
    }

  });

  _exports.default = _default;
});