define("coffee-client/models/stock-take", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = _model.default.extend(Validations, {
    code: _emberData.default.attr('string'),
    creator: _emberData.default.belongsTo('user'),
    theStore: _emberData.default.belongsTo('store'),
    createdTime: _emberData.default.attr('string'),
    state: _emberData.default.attr('number'),
    completedTime: _emberData.default.attr('string'),
    completer: _emberData.default.belongsTo('user'),
    difference: _emberData.default.attr('number'),
    note: _emberData.default.attr('string'),
    name: Ember.computed('code', function () {
      return this.code;
    })
  });

  _exports.default = _default;
});