define("coffee-client/services/ajax", ["exports", "ember-ajax/services/ajax", "coffee-client/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    host: _environment.default.api.host,
    session: Ember.inject.service(),
    headers: Ember.computed('session.authToken', {
      get() {
        let headers = {}; //const data = this.get('session.data.authenticated');

        let accessToken = this.get('session.data.authenticated.access_token');

        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return headers;
      }

    }),

    isSuccess(status, headers, payload) {
      let isSuccess = this._super(...arguments); //      if (isSuccess && payload.status) {
      //        // when status === 200 and payload has status property,
      //        // check that payload.status is also considered a success request
      //        return this._super(payload.status);
      //      }


      return isSuccess;
    }

  });

  _exports.default = _default;
});