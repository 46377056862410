define("coffee-client/pod/components/ecom-admin/role/view-permission/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P4zIOvJ6",
    "block": "{\"symbols\":[\"permission\"],\"statements\":[[7,\"ul\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"permissions\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[9],[1,[22,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-admin/role/view-permission/template.hbs"
    }
  });

  _exports.default = _default;
});