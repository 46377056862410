define("coffee-client/pod/components/ecom-front/in-stock-modal/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    product: null,
    backTo: null,
    value: null,
    inStock: null,
    cancelAction: 'closeModal',
    successMessage: 'Changes saved successfully',
    messenger: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    actions: {
      save() {
        let self = this;
        let product = this.product;

        if (this.inStock) {
          product.set('inStock', this.inStock);
          product.set('productVariants', product.get('productVariants'));
          product.save().then(function () {
            self.get('messenger').success(self.get('successMessage'));
            self.set('inStock', null);
            self.sendAction('cancelAction');
          }).catch(function () {
            self.get('messenger').danger('Error');
          });
        } else {
          self.get('messenger').danger('Inventory can not empty');
        }
      },

      cancel() {
        this.sendAction('cancelAction');
      }

    }
  });

  _exports.default = _default;
});