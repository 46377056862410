define("coffee-client/pod/components/e-input/select-model/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lWQEd/2M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"btn-selected height\"],[9],[0,\"\\n  \"],[1,[27,\"ember-select-guru\",null,[[\"optionComponent\",\"singleValueComponent\",\"value\",\"options\",\"multiple\",\"placeholder\",\"onSearchInputChange\",\"onSelect\",\"searchPlaceholder\"],[[23,[\"optionComponent\"]],[23,[\"singleValueComponent\"]],[23,[\"selected\"]],[23,[\"options\"]],[23,[\"multiple\"]],[23,[\"placeholder\"]],[27,\"action\",[[22,0,[]],\"queryChanged\"],null],[27,\"action\",[[22,0,[]],\"selectItem\"],null],[27,\"t\",[\"Type to search...\"],null]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"canEmpty\"]]],null,{\"statements\":[[4,\"if\",[[23,[\"selected\"]]],null,{\"statements\":[[4,\"unless\",[[23,[\"multiple\"]]],null,{\"statements\":[[0,\"        \"],[7,\"button\"],[11,\"class\",\"btn btn-xs\"],[9],[0,\"x\"],[3,\"action\",[[22,0,[]],\"clear\"]],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"input-error\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"showErrorMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"error\"],[9],[0,\"\\n        \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"message\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,[\"showWarningMessage\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"warning\"],[9],[0,\"\\n        \"],[1,[27,\"get\",[[27,\"get\",[[23,[\"model\",\"validations\",\"attrs\"]],[23,[\"valuePath\"]]],null],\"warningMessage\"],null],false],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/e-input/select-model/template.hbs"
    }
  });

  _exports.default = _default;
});