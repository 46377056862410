define("coffee-client/pod/components/ecom-admin/product/publish-modal/component", ["exports", "ember-cp-validations", "coffee-client/helpers/url-name"], function (_exports, _emberCpValidations, _urlName) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({});

  var _default = Ember.Component.extend(Validations, {
    product: null,
    opened: false,
    backTo: null,
    publishedMessage: null,
    productName: null,
    messenger: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    urlName(name) {
      name = name.toLowerCase();
      name = name.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      name = name.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      name = name.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      name = name.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      name = name.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      name = name.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      name = name.replace(/đ/g, "d");
      name = name.replace(/!|@|\$|%|\^|\*|\(|\)|\+|=|<|>|\?|\/|,|\.|:|'| |"|&|#|\[|]|~/g, "-");
      name = name.replace(/-+-/g, "-");
      name = name.replace(/^-+|-+$/g, "");
      return name;
    },

    actions: {
      cancel() {
        this.set('publishedMessage', null);
        this.set('opened', false);
      },

      save() {
        let self = this;
        let product = this.product;

        if (this.isCancelForm) {
          if (this.publishedMessage) {
            product.set('message', this.publishedMessage);
            product.set('state', 0);
            product.save();
          } else {
            self.get('messenger').danger('Message can not be empty');
            return false;
          }
        } else {
          product.set('state', 1);
          product.save().then(function () {
            self.get('router').transitionTo('home.product-detail', (0, _urlName.urlName)([product.get('product.name')]), product.get('product.id'));
          });
        }

        this.set('publishedMessage', null);
        this.set('opened', false);
      }

    }
  });

  _exports.default = _default;
});