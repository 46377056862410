define("coffee-client/pod/components/ecom-front/notification/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    user: null,
    notificationNotRead: null,
    notificationCounter: 0,
    notificationList: null,
    notificationTempList: null,
    notificationOpened: false,
    popup: true,
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    identity: Ember.inject.service(),
    cart: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.notificationListChanged();
    },

    notificationListChanged: Ember.observer('notificationList.@each', function () {
      let self = this;

      if (self.get('notificationList')) {
        self.get('notificationList').forEach(function (item) {
          if (item.get('type') === 'order-success') {
            let params = JSON.parse(item.get('params'));
            let orderId = params.orderId;
            self.get('store').find('order', orderId).then(function (order) {
              item.set('content', 'Bạn đã nhận được đơn hàng mới (' + order.get('code') + ')');
              item.set('link', 'http://quada.vn/ban-hang/don-hang/' + order.get('id'));
              item.set('isProcessed', true);
            });
          } else if (item.get('type') === 'product-version-published') {
            let params = JSON.parse(item.get('params'));
            let productVersionId = params.productVersionId;
            self.get('store').find('product-version', productVersionId).then(function (productVersion) {
              item.set('content', 'Sản phẩm "' + productVersion.get('product.name') + '" đã được công bố thành công');
              item.set('link', 'http://quada.vn/xem-truoc-san-pham/' + productVersion.get('id'));
              item.set('isProcessed', true);
            });
          } else if (item.get('type') === 'product-version-rejected') {
            let params = JSON.parse(item.get('params'));
            let productVersionId = params.productVersionId;
            self.get('store').find('product-version', productVersionId).then(function (productVersion) {
              item.set('content', 'Sản phẩm "' + productVersion.get('product.name') + '" đã được bị từ chối');
              item.set('link', 'http://quada.vn/xem-truoc-san-pham/' + productVersion.get('id'));
              item.set('isProcessed', true);
            });
          } else {
            item.set('isProcessed', true);
          }
        });
      }
    }),
    actions: {
      chooseNotification(notification) {
        let link = notification.get('link');
        let content = notification.get('content');
        notification.set('read', 1);
        notification.save().then(function () {
          notification.set('link', link);
          notification.set('content', content);
          window.location.href = link;
        });
      },

      closePopup() {
        this.set('notificationOpened', false);
      }

    }
  });

  _exports.default = _default;
});