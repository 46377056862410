define("coffee-client/pod/components/e-form/row/component", ["exports", "coffee-client/mixins/route/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_resetScroll.default, {
    translate: true
  });

  _exports.default = _default;
});