define("coffee-client/pod/components/ecom-front/show-more-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GVMbDPcR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"show-more-box\"],[12,\"style\",[28,[\"height:\",[21,\"height\"],\"px;\"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"show-more-content\"],[9],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"unless\",[[23,[\"isViewedMore\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"show-more\"],[9],[0,\"\\n    \"],[7,\"a\"],[9],[1,[21,\"label\"],false],[0,\" \"],[7,\"i\"],[11,\"class\",\"fa fa-angle-down\"],[9],[10],[3,\"action\",[[22,0,[]],\"showMore\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "coffee-client/pod/components/ecom-front/show-more-content/template.hbs"
    }
  });

  _exports.default = _default;
});