define("coffee-client/pod/print-stock-take/controller", ["exports", "coffee-client/mixins/form/edit", "ember-concurrency"], function (_exports, _edit, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_edit.default, {
    didValidate: true,
    balanced: Ember.computed('stockTake.state', function () {
      return this.get('stockTake.state') === 2;
    })
  });

  _exports.default = _default;
});