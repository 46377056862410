define("coffee-client/models/option", ["exports", "ember-data", "ember-data/model", "ember-cp-validations"], function (_exports, _emberData, _model, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 255
      })]
    },
    key: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 3,
        max: 256
      })]
    },
    value: {
      validators: [(0, _emberCpValidations.validator)('presence', true) // ,
      // validator('length', {
      //   min: 3,
      //   max: 1000
      // })
      ]
    },
    type: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _model.default.extend(Validations, {
    name: _emberData.default.attr('string'),
    key: _emberData.default.attr('string'),
    value: _emberData.default.attr('string'),
    type: _emberData.default.attr('string')
  });

  _exports.default = _default;
});