define("coffee-client/mixins/route/reset-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    resetController(controller, isExiting) {
      if (isExiting) {
        Ember.get(this, '_qp.qps').forEach(qp => {
          let defaultValue;

          if (qp.hasOwnProperty('def')) {
            // < v2.0
            defaultValue = qp.def;
          } else {
            // >= v2.0
            defaultValue = qp.defaultValue;
          }

          Ember.set(controller, qp.prop, defaultValue);
        });
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});